<template>
  <div>
    <b-form @submit.prevent>
      <b-row>

        <!-- form -->
        <b-col cols="12">
          <!-- <b-form-group
            label="Zone Name"
            label-for="v-name"
          >
            <v-text-field
              v-model="zoneName"
              label="Zone Name"
            />
          </b-form-group> -->
          <b-form-group
            label="Zone Name"
            label-for="v-name"
          >
            <b-form-input
              id="v-name"
              v-model="zoneName"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Task Name"
            label-for="v-task"
          >
            <v-select
              v-model="task.selected"
              multiple
              label="task"
              :options="taskOptions"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Zone Type"
            label-for="v-zone"
          >
            <v-select
              v-model="zone.selected"
              label="zone"
              :options="zone.options"
            />
          </b-form-group>
        </b-col>

        <!-- coordinates -->
        <!-- <b-col v-if="editZoneId === '0'" cols="12">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Latitude</th>
                <th>Longitude</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(coordinate, index) in coordinates"
                :key="index"
              >
                <td>{{ coordinate[0] }}</td>
                <td>{{ coordinate[1] }}</td>
              </tr>
            </tbody>
          </table>
        </b-col> -->

        <!-- submit and reset -->
        <b-col cols="12 text-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="saveNewZone"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BForm, BButton, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
    editZoneId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      task: {
        selected: [],
      },
      zone: {
        selected: [],
        options: [],
      },
      zoneName: '',
      taskOptions: [],
      allTaskByProject: [],
      oldTaskList: [],
    }
  },
  computed: {
  },
  created() {
  },
  beforeMount() {
    // this.tasks.forEach(task => {
    //   this.taskOptions.push({
    //     value: task.id,
    //     task: task.name,
    //   })

    //   this.apiGetTaskById(task.id)
    // })
  },
  mounted(){
    this.apiGetZoneById()
    this.apiGetAllZoneType()
  },

  methods: {
    apiGetZoneById() {
      this.$axios.get(`${this.$baseUrl}/zones/${this.editZoneId}`).then(response => {
        if (response.status === 200 || response.status === 201) {
          const { data } = response
          console.log(data)
          console.log(this.tasks)
          this.zoneName = data.name
          //   this.zone.selected.value = data.type.id
          //   this.zone.selected.text = data.type.label
          this.zone.selected = {
            zone: data.type.label,
            value: data.type.id,
          }

          this.tasks.forEach(task => {
            this.taskOptions.push({
              value: task.id,
              task: task.name,
            })

            this.apiGetTaskById(task.id)
          })

        //   console.log(this.allTaskByProject)
        //   this.allTaskByProject.forEach(task => {
        //     console.log(task)
        //   })
        }
      })
    },
    apiGetTaskById(id) {
      this.$axios.get(`${this.$baseUrl}/tasks/${id}`).then(response => {
        if (response.status === 200 || response.status === 201) {
          const { data } = response
          console.log(data)
          this.allTaskByProject.push(data)

          const zoneTask = data.zones
          zoneTask.forEach(zone => {
            if (this.editZoneId === zone.id) {
              this.oldTaskList = this.task.selected
              this.task.selected.push({
                task: data.name,
                value: data.id,
              })
            }
          })
        }
      })
    },
    apiGetAllZoneType(){
      this.$axios.get(`${this.$baseUrl}/zoneTypes`).then(response => {
        if (response.status === 200) {
          this.zone.options = []
          response.data.forEach(zone => {
            this.zone.options.push({
              zone: zone.label,
              value: zone.id,
            })
          })
        }
      })
    },
    saveNewZone(){
      this.apiPostCreateZone()
    },
    apiPostCreateZone(){
      const taskValue = []

      const formData = {
        name: this.zoneName,
        type: this.zone.selected.value,
      }

      console.log(formData)

      this.$axios.put(`${this.$baseUrl}/zones/${this.editZoneId}`, formData).then(response => {
        if (response.status === 200) {
          const timeout = 4000
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Zone Succesfully Updated!`,
            },
          }, { timeout })

          const { data } = response
          const createdZoneId = data.id

          this.task.selected.forEach(item => {
            // select multiple task
            taskValue.push(item.value)

            this.tasks.forEach(task => {
              if (task.id === item.value) {
                console.log(task)
                const currentZoneId = []

                this.$axios.get(`${this.$baseUrl}/tasks/${task.id}`).then(response2 => {
                  if (response2.status === 200) {
                    const data2 = response2.data
                    console.log(response2.data)
                    currentZoneId.push(createdZoneId)
                    data2.zones.forEach(currentZone => {
                      if (currentZone.id !== createdZoneId) {
                        currentZoneId.push(currentZone.id)
                      }
                    })

                    setTimeout(() => {
                      const formDataUpdate = {
                        zones: currentZoneId,
                      }

                      console.log(formDataUpdate)

                      this.$axios.patch(`${this.$baseUrl}/tasks/${task.id}`, formDataUpdate).then(response3 => {
                        console.log(response3)
                        if (response3.status === 200) {
                          this.$emit('successAddedZone')
                        }
                      })
                    }, 1000)
                  }
                })
              }
            })
          })

          this.oldTaskList.forEach(oldTask => {
            console.log(taskValue)
            if (!taskValue.includes(oldTask.value)) {
              console.log('old task')
              console.log(oldTask.value)
              this.$axios.get(`${this.$baseUrl}/tasks/${oldTask.value}`).then(response4 => {
                if (response4.status === 200 || response4.status === 201) {
                  const data4 = response4.data
                  console.log(data4)
                  let tempDeletedZones = []
                  const tempDeletedZonesId = []
                  const { zones } = data4
                  for (let i = 0; i < zones.length; i += 1) {
                    console.log(zones[i])
                    if (zones[i].id === this.editZoneId) {
                      zones.splice(i, 1)
                      tempDeletedZones = zones
                    }
                  }

                  console.log(tempDeletedZones)
                  tempDeletedZones.forEach(temp => {
                    tempDeletedZonesId.push(temp.id)
                  })

                  const formDataUpdate = {
                    zones: tempDeletedZonesId,
                  }

                  this.$axios.patch(`${this.$baseUrl}/tasks/${oldTask.value}`, formDataUpdate).then(response3 => {
                    console.log(response3)
                    if (response3.status === 200) {
                      if (this.task.selected.length === 0) {
                        this.$emit('successAddedZone')
                      }
                      // this.$emit('successAddedZone')
                    }
                  })
                }
              })
            }
          })
        }
      })
    },
  },
}
</script>
