<template>
  <div class="bg-white p-0 m-0">
    <div class="p-2 d-flex justify-content-between align-items-center">
      <span
        style="
          color: #5e5873;
          font-weight: 500;
          font-size: 16px;
          text-transform: capitalize;
        "
      >{{ type === "defects" ? "Quality Issues" : type }}
      </span>
      <div
        class="btn btn-primary"
        @click="addNewRecordClick()"
      >
        + Add Record
      </div>
    </div>
    <hr class="">
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center">
          <span class="mr-1"> Show </span>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </div>
        <div class="d-flex align-items-center">
          <span class="mr-1"> Task </span>
          <b-form-select
            id="listTask"
            v-model="selectedListTask"
            size="sm"
            inline
            @change="taskChanged()"
          >
            <option :value="null">
              All Task
            </option>
            <option
              v-for="(task, index) in listTask"
              :key="index"
              :value="task.id"
            >
              {{ task.name }}
            </option>
          </b-form-select>
        </div>
        <div class="d-flex mt-1 align-items-center">
          <span class="mr-1"> Type </span>
          <b-form-select
            v-model="selectedType"
            size="sm"
            inline
          >
            <option :value="null">
              All Type
            </option>
            <option
              v-for="(type, index) in listType"
              :key="index"
              :value="type.label"
            >
              {{ type.label }}
            </option>
          </b-form-select>
          <span class="mr-1" />
          <b-button
            variant="outline-secondary"
            size="sm"
            @click="addNewType()"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </div>
        <!-- <div class="d-flex mt-1 align-items-center">
          <span class="mr-1">Search</span>
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div> -->
      </div></b-card-body>
    <!-- table -->
    <b-table
      ref="selectableTable"
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      show-empty
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="selectedType"
      :filter-included-fields="filterOn"
      @row-selected="onRowSelected"
      @filtered="onFiltered"
    >
      <template #cell(timestamp)="data">
        {{ convertDate(data) }}
      </template>
      <template #cell(modify)="data">
        <div class="d-flex align-items-center">
          <!-- <div>
            <feather-icon
              icon="Edit2Icon"
            />
          </div> -->

          <div
            style="cursor: pointer"
            class="ml-1 deleteTrash"
            @click="deleteIssue(data.item.id, type)"
          >
            <feather-icon
              icon="TrashIcon"
              size="20"
            />
          </div>
        </div>
      </template>

      <template>
        <div class="d-flex justify-content-center align-items-center">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25 purpleColor"
              />
            </template>
            <b-dropdown-item>
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template></b-table>
    <div
      class="d-flex justify-content-between align-items-center ml-3 mr-3 mb-2"
    >
      <span
        style="color: #b9b9c3"
      >Showing 1 to {{ perPage }} of {{ items.length }} entries</span>
      <b-pagination
        v-model="currentPage"
        :value="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="(value) => props.pageChanged({ currentPage: value })"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    <b-modal
      id="modal-add-new-record"
      hide-footer
      centered
    >
      <add-new-defect-incident
        :type="type"
        :list-type="listType"
        :selected-project="selectedProject"
        @refreshDefectIncident="getDefectIncident"
      />
    </b-modal>
    <b-modal
      id="modal-add-new-type"
      hide-footer
      centered
    >
      <add-new-type
        :type="type"
        :selected-project="selectedProject"
        @refreshDefectIncident="getDefectIncidentType"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCardBody,
  BDropdown,
  BDropdownItem,
  BButton,
  BTable,
  BFormSelect,
  BPagination,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import dayjs from "dayjs"
import AddNewDefectIncident from "./AddNewDefectIncident.vue"
import AddNewType from "./AddNewType.vue"
// import axios from 'axios'

export default {
  components: {
    BCardBody,
    // BInputGroup,
    BDropdown,
    BDropdownItem,
    BTable,
    BFormSelect,
    BPagination,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    AddNewType,
    AddNewDefectIncident,
  },
  directives: {
    Ripple,
  },
  props: {
    type: {
      type: String,
      default: () => false,
    },
    selectedProject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      table: {
        checkedAll: false,
        checkedItems: [],
      },
      modes: ["multi", "single", "range"],
      fields: [
        { key: "name", label: "Name" },
        { key: "task.name", label: "Task ID" },
        { key: "type.label", label: "Type" },
        { key: "timestamp", label: "Time" },
        { key: "modify", label: "" },
        // { key: 'start_time', label: 'Start Time', sortable: true },
        // { key: 'end_time', label: 'End Time', sortable: true },
        // { key: 'durationA', label: 'Duration [NPT ZONE A]', sortable: true },
        // { key: 'durationB', label: 'Duration [NPT ZONE B]', sortable: true },
        // 'actions',
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      allItems: [],
      selectMode: "multi",
      selected: [],
      listTask: [],
      listType: [],
      selectedListTask: null,
      selectedType: null,
      // options: [
      //   { value: 'null', text: 'All Type' },
      //   { value: 'a', text: 'A' },
      //   { value: 'b', text: 'B' },
      //   { value: 'c', text: 'C' },
      // ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Inactive: "light-danger",
        Active: "light-success",
        Pending: "light-warning",
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    // this.$http.get('/analytics/data')
    //   .then(response => {
    //     const dashboardData = response.data
    //     this.items = dashboardData.listManHours.manHours
    //     this.totalRows = this.items.length
    //   })
  },
  mounted() {
    // this.getDefectIncidentType()
    this.getDefectIncident()
    this.getListTask()
  },
  methods: {
    convertDate(data) {
      return dayjs(data.item.timestamp).format("DD-MM-YYYY")
    },
    deleteIssue(id, type) {
      console.log(type)
      console.log(id)
      // this.$axios.delete(`${this.$baseUrl}/incidents/${this.editTaskId}`).then(response => {
      this.$axios
        .delete(
          type.toLowerCase() === "defects"
            ? `${this.$baseUrl}/defects/${id}`
            : `${this.$baseUrl}/incidents/${id}`,
        )
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            this.getDefectIncident()
            const { data } = response
            console.log(data)
            this.getDefectIncident()
            const timeout = 4000
            this.$toast(
              {
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Info`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Issue Deleted!`,
                },
              },
              { timeout },
            )
          }
        })
    },
    getDefectIncident() {
      console.log(this.type)
      this.getDefectIncidentType(this.type)
      this.$axios.get(`${this.$baseUrl}/${this.type}`).then(response => {
        const { data } = response
        this.allItems = data
        this.items = this.allItems
        this.totalRows = this.items.length
      })
    },
    getDefectIncidentType(type) {
      const param = type === 'defects' ? 'defectTypes' : 'incidenttype'
      this.$axios.get(`${this.$baseUrl}/${param}`).then(response => {
        if (response.status === 200 || response.status === 201) {
          const { data } = response
          this.listType = data
        }
      })
      // if (type === "incidents") {
      // this.listType = [
      //   {
      //     value: "Fatality (Immediate)",
      //     label: "Fatality (Immediate)",
      //   },
      //   {
      //     value: "Missing Person (Immediate)",
      //     label: "Missing Person (Immediate)",
      //   },
      //   {
      //     value: "Pandermic/Endermic (Within 24 Hours)",
      //     label: "Pandermic/Endermic (Within 24 Hours)",
      //   },
      //   {
      //     value: "Major Fire (Immediate)",
      //     label: "Major Fire (Immediate)",
      //   },
      //   {
      //     value: "Major Property Damage (Immediate)",
      //     label: "Major Property Damage (Immediate)",
      //   },
      //   {
      //     value: "Security Intrusion (Within 24 Hours)",
      //     label: "Security Intrusion (Within 24 Hours)",
      //   },
      // ]
      // } else {
      //   this.listType = [
      //     {
      // eslint-disable-next-line no-irregular-whitespace
      //       value: "​Damaged supply/items",
      // eslint-disable-next-line no-irregular-whitespace
      //       label: "​Damaged supply/items",
      //     },
      //     {
      //       value: "Improper weld",
      //       label: "Improper weld",
      //     },
      //     {
      //       value: "Low-quality concrete mix",
      //       label: "Low-quality concrete mix",
      //     },
      //     {
      //       value: "Mismeasurement",
      //       label: "Mismeasurement",
      //     },
      //     {
      //       value: "Structure/object misplacement",
      //       label: "Structure/object misplacement",
      //     },
      //     {
      //       value: "Improper material",
      //       label: "Improper material",
      //     },
      //     {
      //       value: "Cracks",
      //       label: "Cracks",
      //     },
      //     {
      //       value: "Leakasges",
      //       label: "Leakages",
      //     },
      //   ]
      // }
      // console.log(type)
    },
    getListTask() {
      this.listTask = this.selectedProject.tasks
    },
    taskChanged() {
      this.items = []
      if (this.selectedListTask !== null) {
        this.allItems.forEach(item => {
          if (item.task.id === this.selectedListTask) {
            this.items.push(item)
          }
        })
      } else {
        this.items = this.allItems
      }
    },
    addNewRecordClick() {
      this.$bvModal.show("modal-add-new-record")
    },
    addNewType() {
      this.$bvModal.show("modal-add-new-type")
    },
    checkAll() {
      if (this.table.checkedAll) {
        this.items.forEach(staff => {
          this.table.checkedItems.push(staff.employee_id)
        })
      } else this.table.checkedItems = []
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.deleteTrash:hover {
  color: purple;
}
</style>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
