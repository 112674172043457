<template>
  <div>
    <div v-switch="currView">
      <div v-case="'isManhourMenu'">
        <transition name="fade" mode="out-in">
          <!-- v-if is use for transition purposes only -->
          <menu-manhour
            v-if="currView === 'isManhourMenu'"
            key="12"
            :data="manhourData"
            @onClickBack="onClickBack"
        /></transition>
      </div>
      <div v-case="'isMenuRfid'">
        <transition name="fade" mode="out-in" appear
          ><menu-rfid
            v-if="currView === 'isMenuRfid'"
            key="11"
            @onClickBack="onClickBack"
          />
        </transition>
      </div>
      <div v-case="'isMenuMainHall'">
        <transition name="fade" mode="out-in" appear>
          <info-building key="12" @onClickBack="onClickBack"
        /></transition>
      </div>
      <div v-case="'isMenuNptZone'">
        <transition name="fade" mode="out-in" appear>
          <div v-if="currView === 'isMenuNptZone'" key="13">
            <div class="p-1">
              <div class="d-flex flex-row px-2 py-1">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="2x"
                  class="pointer"
                  @click="onItemBack('dronedata')"
                />
              </div>

              <!-- Body -->
              <div class="pt-2 pl-2">
                <p class="font-weight-bold" style="font-size: 130%">NPT Zone</p>
              </div>
              <hr />
              <div class="pl-2">
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 30px;
                      height: 30px;
                      text-align: center;
                      padding-top: 5px;
                      background-color: rgb(234, 84, 85, 0.12);
                    "
                  >
                    <feather-icon
                      icon="MapIcon"
                      size="17"
                      class="text-warning"
                    />
                  </div>
                  <p class="font-weight-bold">Workers Canteen</p>
                </div>
                <div class="pb-1 pr-1 d-flex flex-row">
                  <p class="font-weight-normal">
                    Currently, the description is about IoT and safety helmets
                    with statistics on wearing status and incidents which is
                    incorrect
                  </p>
                </div>
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 40px;
                      height: 40px;
                      text-align: center;
                      padding-top: 5px;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="2x"
                      class="text-success"
                    />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <span class="font-weight-bold"> 2 </span>
                    <p class="font-weight-normal">Current Incident</p>
                  </div>
                </div>
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 40px;
                      height: 40px;
                      text-align: center;
                      padding-top: 5px;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="2x"
                      class="text-success"
                    />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <span class="font-weight-bold"> 39 </span>
                    <p class="font-weight-normal">Total Incident</p>
                  </div>
                </div>
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 40px;
                      height: 40px;
                      text-align: center;
                      padding-top: 5px;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="2x"
                      class="text-success"
                    />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <span class="font-weight-bold"> 2 </span>
                    <p class="font-weight-normal">Wearing Status</p>
                  </div>
                </div>
                <div class="mt-auto">
                  <b-button
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    block
                    variant="outline-dark"
                    @click="currView = 'else'"
                  >
                    Back
                  </b-button>
                </div>
              </div>
            </div>
          </div></transition
        >
      </div>
      <div v-case="'isMenuCctv'">
        <transition name="fade" mode="out-in" appear>
          <div v-if="currView === 'isMenuCctv'" key="14">
            <div class="p-1">
              <div class="d-flex flex-row px-2 py-1">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="2x"
                  class="pointer"
                  @click="onItemBack('cctv')"
                />
              </div>

              <!-- Body -->
              <div class="pt-2 pl-2">
                <p class="font-weight-bold" style="font-size: 130%">CCTV 1</p>
              </div>
              <hr />
              <div class="pl-2">
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 25px;
                      height: 25px;
                      text-align: center;
                      background-color: rgb(234, 84, 85, 0.12);
                    "
                  >
                    <feather-icon
                      icon="MapIcon"
                      size="17"
                      class="text-warning"
                    />
                  </div>
                  <p class="font-weight-bold">Workers Canteen</p>
                </div>
                <div class="pb-1 pr-1 d-flex flex-row">
                  <p class="font-weight-normal">
                    Monitoring for safety compliance 24/7/365 with the aid of computer vision to alert users of any misconduct.
                  </p>
                </div>
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 25px;
                      height: 25px;
                      text-align: center;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon icon="CircleIcon" size="17" />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <p class="font-weight-bold">69.9°</p>
                    <p class="font-weight-normal">Facing Angle</p>
                  </div>
                </div>
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 25px;
                      height: 25px;
                      text-align: center;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon icon="AlignJustifyIcon" size="17" />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <p class="font-weight-bold">QQEB - K7FH - NK43</p>
                    <p class="font-weight-normal">Serial Number</p>
                  </div>
                </div>
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 25px;
                      height: 25px;
                      text-align: center;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon icon="MapPinIcon" size="17" />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <p class="font-weight-bold">Zone A - South</p>
                    <p class="font-weight-normal">Location</p>
                  </div>
                </div>
                <hr />
                <div class="d-flex justify-align-center mb-2">
                  <video
                    style="width: 100%"
                    autoplay
                    class="pr-1 pb-1 pl-2 pt-2"
                  >
                    <source
                      :src="`${publicPath}files/vid/footage.mp4`"
                      autostart="true"
                      type="video/mp4"
                    />
                  </video>
                  <!-- <div
                        style="width: 100%;"
                        class=""
                      >
                        <video-player
                          ref="videoPlayer"
                          class=""
                          :options="playerOptions"
                          @ready="onPlayerReadied"
                          @timeupdate="onTimeupdate"
                        /> -->
                  <!-- {{ liveStream("http://54.169.11.71:5080/PRISM/streams/stream.m3u8") }} -->
                  <!-- {{ liveStream("https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8") }} -->
                  <!-- {{ liveStream("http://cctvcnch5c.v.wscdns.com/live/cctv15_2/index.m3u8") }} -->
                  <!-- </div> -->
                </div>
                <div class="mb-2" style="bottom: 0; width: 100%">
                  <div class="m-0">
                    <b-button
                      v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                      block
                      variant="outline-dark"
                      @click="currView = 'else'"
                    >
                      Back
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div></transition
        >
      </div>
      <div v-case="'isMenuOrtho'">
        <transition name="fade" mode="out-in" appear>
          <div v-if="currView === 'isMenuOrtho'" key="14">
            <div class="p-1">
              <div class="d-flex flex-row px-2 py-1">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="2x"
                  class="pointer"
                  @click="onItemBack('cctv')"
                />
              </div>

              <!-- Body -->
              <div class="pt-2 pl-2">
                <p class="font-weight-bold" style="font-size: 130%">
                  All Zones
                </p>
              </div>
              <hr />
              <div class="pl-2">
                <!-- <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 25px;
                      height: 25px;
                      text-align: center;
                      background-color: rgb(234, 84, 85, 0.12);
                    "
                  >
                    <feather-icon
                      icon="MapIcon"
                      size="17"
                      class="text-warning"
                    />
                  </div>
                  <p class="font-weight-bold">Workers Canteen</p>
                </div> -->
                <div class="pb-1 pr-1 d-flex flex-row">
                  <p class="font-weight-normal">
                    An orthophoto is an aerial photograph that has been
                    geometrically corrected or 'ortho-rectified' such that the
                    scale of the photograph is uniform and utilised in the same
                    manner as a map.
                  </p>
                </div>
                <!-- <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 25px;
                      height: 25px;
                      text-align: center;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon icon="CircleIcon" size="17" />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <p class="font-weight-bold">69.9°</p>
                    <p class="font-weight-normal">Facing Angle</p>
                  </div>
                </div>
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 25px;
                      height: 25px;
                      text-align: center;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon icon="AlignJustifyIcon" size="17" />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <p class="font-weight-bold">QQEB - K7FH - NK43</p>
                    <p class="font-weight-normal">Serial Number</p>
                  </div>
                </div>
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 25px;
                      height: 25px;
                      text-align: center;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon icon="MapPinIcon" size="17" />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <p class="font-weight-bold">Zone A - South</p>
                    <p class="font-weight-normal">Location</p>
                  </div>
                </div> -->
                <hr />
                <div class="d-flex justify-align-center mb-2">
                  <img
                    src="@/assets/images/ortho-pic.png"
                    class="mr-2"
                    width="100%"
                    height="50%"
                  />
                  <!-- <video
                    style="width: 100%"
                    autoplay
                    class="pr-1 pb-1 pl-2 pt-2"
                  >
                    <source
                      :src="`${publicPath}files/vid/footage.mp4`"
                      autostart="true"
                      type="video/mp4"
                    />
                  </video> -->
                  <!-- <div
                        style="width: 100%;"
                        class=""
                      >
                        <video-player
                          ref="videoPlayer"
                          class=""
                          :options="playerOptions"
                          @ready="onPlayerReadied"
                          @timeupdate="onTimeupdate"
                        /> -->
                  <!-- {{ liveStream("http://54.169.11.71:5080/PRISM/streams/stream.m3u8") }} -->
                  <!-- {{ liveStream("https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8") }} -->
                  <!-- {{ liveStream("http://cctvcnch5c.v.wscdns.com/live/cctv15_2/index.m3u8") }} -->
                  <!-- </div> -->
                </div>
                <div class="mb-2" style="bottom: 0; width: 100%">
                  <div class="m-0">
                    <b-button
                      v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                      block
                      variant="outline-dark"
                      @click="currView = 'else'"
                    >
                      Back
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div></transition
        >
      </div>
      <div v-case="'isMenuIot'">
        <transition name="fade" mode="out-in" appear>
          <div v-if="currView === 'isMenuIot'" key="15">
            <div class="p-1">
              <div class="d-flex flex-row px-2 py-1">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="2x"
                  class="pointer"
                  @click="onItemBack('smarthelmet')"
                />
              </div>

              <!-- Body -->
              <div class="pt-2 pl-2">
                <p class="font-weight-bold" style="font-size: 130%">
                  IOT Devices
                </p>
              </div>
              <hr />
              <div class="pl-2">
                <div class="pb-1 d-flex flex-row">
                  <span class="marker greendot mr-1" />
                  <p class="font-weight-bold">Smart Helmet</p>
                </div>
                <div class="pb-1 pr-1 d-flex flex-row">
                  <p class="font-weight-normal">
                    A data driven smart industrial or safety helmet. An IOT
                    solution that helps worksites become safer by providing
                    live, wireless monitoring, and array of sensors such as
                    accident impact and geolocation to perform various analysis
                    of data
                  </p>
                </div>
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 40px;
                      height: 40px;
                      text-align: center;
                      padding-top: 5px;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="2x"
                      class="text-success"
                    />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <span class="font-weight-bold"> 2 </span>
                    <p class="font-weight-normal">Current Incident</p>
                  </div>
                </div>
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 40px;
                      height: 40px;
                      text-align: center;
                      padding-top: 5px;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="2x"
                      class="text-success"
                    />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <span class="font-weight-bold"> 1 </span>
                    <p class="font-weight-normal">Total Incident</p>
                  </div>
                </div>
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 40px;
                      height: 40px;
                      text-align: center;
                      padding-top: 5px;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="2x"
                      class="text-success"
                    />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <span class="font-weight-bold"> 700 </span>
                    <p class="font-weight-normal">Wearing Status</p>
                  </div>
                </div>
                <div class="pb-1 d-flex flex-row">
                  <div
                    class="mr-1"
                    style="
                      border-radius: 5px;
                      width: 40px;
                      height: 40px;
                      text-align: center;
                      padding-top: 5px;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="2x"
                      class="text-success"
                    />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <span class="font-weight-bold"> 1 </span>
                    <p class="font-weight-normal">Alert</p>
                  </div>
                </div>
                <div class="pt-2">
                  <p class="font-weight-bold">Analytics</p>
                </div>
                <hr />
                <div class="pt-2">
                  <vue-apex-charts
                    type="donut"
                    height="300"
                    :series="incident.series"
                    :options="incident.chartOptions"
                  />
                </div>
                <div class="pt-4">
                  <b-button
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    block
                    variant="outline-dark"
                    @click="currView = 'else'"
                  >
                    Back
                  </b-button>
                </div>
              </div>
            </div>
          </div></transition
        >
      </div>
      <div v-case="'isMenuProfile'">
        <transition name="fade" mode="out-in" appear>
          <div v-if="currView === 'isMenuProfile'" key="16">
            <div class="p-1">
              <div class="d-flex flex-row px-2 py-1">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="2x"
                  @click="currView = 'isSearchMenu'"
                />
              </div>
              <div class="pt-2 pl-2 pr-2 d-flex justify-content-center">
                <img src="../map/asset/other/avatar-s-2.jpg" class="rounded" />
              </div>
              <div class="pt-2 d-flex flex-column align-items-center">
                <p class="font-weight-bold" style="font-size: 130%">
                  Gertrude Barton
                </p>
                <div class="pb-1 d-flex justify-content-center">
                  <a href="#" class="badge badge-light-secondary"
                    ><span class="text-success">Project Engineer</span></a
                  >
                </div>
              </div>
              <div class="pl-2 pt-2">
                <div class="pb-1 d-flex flex-row justify-content-center">
                  <div
                    style="
                      border-radius: 5px;
                      width: 35px;
                      height: 35px;
                      text-align: center;
                      padding-top: 5px;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="1.5x"
                      class="text-success"
                    />
                    <!-- </a> -->
                  </div>
                  <div class="d-flex flex-column pl-2 pr-2">
                    <span class="font-weight-bold"> 120 </span>
                    <p class="font-weight-normal">Hours In</p>
                  </div>
                  <div
                    style="
                      border-radius: 5px;
                      width: 35px;
                      height: 35px;
                      text-align: center;
                      padding-top: 5px;
                      background-color: rgba(86, 121, 209, 0.12);
                    "
                  >
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="1.5x"
                      class="text-success"
                    />
                  </div>
                  <div class="d-flex flex-column pl-2">
                    <span class="font-weight-bold"> 120 </span>
                    <p class="font-weight-normal">Hours Out</p>
                  </div>
                </div>
                <div class="pt-2">
                  <p class="font-weight-bold" style="font-size: 130%">
                    Productivity
                  </p>
                </div>
                <hr />
                <div class="pt-2">
                  <vue-apex-charts
                    type="donut"
                    height="300"
                    :series="production.series"
                    :options="production.chartOptions"
                  />
                </div>
                <div class="pt-3">
                  <p class="font-weight-bold" style="font-size: 130%">
                    Details
                  </p>
                </div>
                <hr />
                <div class="d-flex flex-column">
                  <p class="font-weight-bold">
                    Full Name:<span class="font-weight-normal">
                      Gertrude Barton</span
                    >
                  </p>
                  <p class="font-weight-bold">
                    Serial Numbers:<span class="font-weight-normal">
                      A03-201906000035</span
                    >
                  </p>
                  <p class="font-weight-bold">
                    Status:<span class="font-weight-normal pl-1"
                      ><a href="#" class="badge badge-light-secondary"
                        ><span class="text-success">Active</span></a
                      ></span
                    >
                  </p>
                  <p class="font-weight-bold">
                    Role:<span class="font-weight-normal">
                      Project Engineer</span
                    >
                  </p>
                  <p class="font-weight-bold">
                    Phone No:<span class="font-weight-normal"> 0123456789</span>
                  </p>
                  <p class="font-weight-bold">
                    IMEI NO:<span class="font-weight-normal">
                      325910080000643</span
                    >
                  </p>
                  <p class="font-weight-bold">
                    Last Activity:<span class="font-weight-normal">
                      9 Aug 2021, 03:24PM</span
                    >
                  </p>
                  <p class="font-weight-bold">
                    Location:<span class="font-weight-normal">
                      WMJ5+7P Cyberjaya, Selangor,Malaysia</span
                    >
                  </p>
                </div>
                <div class="pt-4">
                  <b-button
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    block
                    variant="outline-dark"
                    @click="currView = 'isSearchMenu'"
                  >
                    Back
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div v-case="'isSearchMenu'">
        <transition name="fade" mode="out-in" appear>
          <div v-if="currView === 'isSearchMenu'" key="17">
            <div class="p-1">
              <div class="d-flex flex-row px-2 py-1">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="2x"
                  @click="onClickBack()"
                />
                <b-col>
                  <b-input-group class="input-group-merge">
                    <b-form-input placeholder="Search here" />
                    <b-input-group-append is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </div>

              <!-- Body -->
              <div class="pt-2 pl-2 pb-2">
                <span style="color: grey">EMPLOYEE</span>
              </div>
              <div class="pl-2">
                <div
                  class="d-flex flex-row pointer"
                  style="padding: 12px"
                  @click="currView = 'isMenuProfile'"
                >
                  <b-avatar
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-20.jpg')
                    "
                  />
                  <div class="pl-2">
                    <div class="font-weight-bold">571231980C</div>
                    <div class="font-weight-normal">Site Supervisor</div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row pointer"
                  style="padding: 12px"
                  @click="currView = 'isMenuProfile'"
                >
                  <b-avatar
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-20.jpg')
                    "
                  />
                  <div class="d-flex flex-column pl-2">
                    <div class="font-weight-bold">124153120C</div>

                    <div class="font-weight-normal">Project Engineer</div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row pointer"
                  style="padding: 12px"
                  @click="currView = 'isMenuProfile'"
                >
                  <b-avatar
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-20.jpg')
                    "
                  />
                  <div class="d-flex flex-column pl-2">
                    <div class="font-weight-bold">120987162C</div>
                    <div class="font-weight-normal">Site Supervisor</div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row pointer"
                  style="padding: 12px"
                  @click="currView = 'isMenuProfile'"
                >
                  <b-avatar
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-20.jpg')
                    "
                  />
                  <div class="d-flex flex-column pl-2">
                    <div class="font-weight-bold">578253120C</div>
                    <div class="font-weight-normal">Site Technician</div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row pointer"
                  style="padding: 12px"
                  @click="currView = 'isMenuProfile'"
                >
                  <b-avatar
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-20.jpg')
                    "
                  />
                  <div class="d-flex flex-column pl-2">
                    <div class="font-weight-bold">Jason Simpson</div>
                    <div class="font-weight-normal">Operator</div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center pt-2 pl-2 pb-2">
                <span style="color: grey">IOT DEVICES</span>
              </div>
              <!-- <div class="list-group list-group-labels"> -->
              <div class="pl-2">
                <div
                  class="d-flex pointer"
                  style="padding: 12px"
                  @click="currView = 'isMenuIot'"
                >
                  <span class="marker greendot mr-1" /><span>Smart Helmet</span>
                </div>
              </div>
              <div class="pl-2">
                <div class="d-flex pointer" style="padding: 12px">
                  <span class="marker pinkdot mr-1" /><span>Smart Glasses</span>
                </div>
              </div>
              <!-- </div> -->

              <div class="pt-2 pl-2 pb-2">
                <span style="color: grey">SENSORS</span>
              </div>
              <div class="pl-2">
                <div
                  class="d-flex pointer"
                  style="padding: 12px"
                  @click="currView = 'isMenuRfid'"
                >
                  <span class="marker yellowdot mr-1" /><span>RFID</span>
                </div>
              </div>
              <div class="pl-2">
                <div
                  class="d-flex pointer"
                  style="padding: 12px"
                  @click="currView = 'isMenuCctv'"
                >
                  <span class="marker purpledot mr-1" /><span>CCTV</span>
                </div>
              </div>
              <div class="pl-2">
                <div class="d-flex pointer" style="padding: 12px">
                  <span class="marker reddot mr-1" /><span>GPS Tracker</span>
                </div>
              </div>
              <div class="pl-2">
                <div
                  class="d-flex pointer"
                  style="padding: 12px"
                  @click="currView = 'isMenuNptZone'"
                >
                  <span class="marker greydot mr-1" /><span>Drone Data</span>
                </div>
              </div>
            </div>
          </div></transition
        >
      </div>
      <div v-case="'isLeftMenuLocation'">
        <transition name="fade" mode="out-in" appear>
          <div v-if="currView === 'isLeftMenuLocation'" key="18" class="p-1">
            <div class="d-flex">
              <div class="mr-auto p-2">
                <span style="font-weight: 500; color: #5e5873; font-size: 18px"
                  >Location</span
                >
              </div>
              <div class="p-2" style="cursor: pointer">
                <!-- <feather-icon
                  class="mr-2"
                  icon="SearchIcon"
                  size="18"
                /> -->
                <div id="tooltip-newproject" @click="toggleAddMarkerNewProject">
                  <feather-icon icon="PlusIcon" size="18" />
                </div>
                <b-tooltip target="tooltip-newproject" :delay="delay">
                  Create New Project
                </b-tooltip>
              </div>
            </div>

            <hr class="mb-2" />
            <div
              v-for="(specificProject, index) in allProject"
              :key="specificProject.id"
              class="p-1 row mb-1"
              @mouseover="projectHover(index, specificProject.id)"
              @mouseleave="hover1 = false"
            >
            <div class="col-12 row">
              <div class="col-2">
                <img
                  src="@/assets/images/icons/red-dot.png"
                  alt="Avatar"
                  class="mr-2"
                  width="20"
                  height="20"
                />
              </div>
              <div class="col-10 row justify-content-between">
                <div
                  class=""
                  style="cursor: pointer"
                  @click="
                    onClickLocation(
                      [specificProject.latitude, specificProject.longitude],
                      specificProject
                    )
                  "
                >
                  {{ specificProject.name }}
                </div>
                <div v-if="currIdx === index" class="row">
                  <div 
                  v-if="hideArr"
                  style="cursor: pointer">
                    <feather-icon size="16" class="mr-1" @click="hideProj(specificProject.id, index)" :icon="hideArr[index] ? 'EyeOffIcon' : 'EyeIcon'"/>
                    <!-- @click="getIndex(index, specificProject.id)" -->
                    <!-- @click="hideProj(specificProject.id)" :icon="isIdxHide ? 'EyeOffIcon' : 'EyeIcon'" size="16" -->
                  </div>
                  <div
                    class="onHoverTrash"
                    style="cursor: pointer"
                    @click="deleteConfirmModal(specificProject.id)"
                  >
                    <feather-icon icon="TrashIcon" size="16" />
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div class="mb-3" /></div
        ></transition>
      </div>
      <div v-case="'else'">
        <transition name="fade" mode="out-in" appear>
          <div v-if="currView === 'else'" key="67">
            <div class="p-1">
              <div class="d-flex justify-content-between mb-2">
                <div class="d-flex align-items-center">
                  <feather-icon
                    class="mr-1"
                    icon="ArrowLeftIcon"
                    style="cursor: pointer"
                    size="2x"
                    @click="onClickLayerBack"
                  />
                  <span
                    style="font-weight: 500; color: #5e5873; font-size: 18px"
                    >{{ title }}</span
                  >
                </div>
                <div>
                  <feather-icon
                    id="tooltip-gantt"
                    icon="FolderMinusIcon"
                    size="18"
                    style="cursor: pointer"
                    class="mr-1"
                    @click="showGanttChartModal()"
                  />
                  <b-tooltip target="tooltip-gantt" :delay="delay">
                    Gantt Chart
                  </b-tooltip>
                  <!-- <feather-icon
                    id="tooltip-timehour"
                    icon="ClockIcon"
                    style="cursor: pointer"
                    size="18"
                    class="mr-1"
                    @click="goManhourMenu"
                  />
                  <b-tooltip target="tooltip-timehour" :delay="delay">
                    Overall Time Hours
                  </b-tooltip> -->
                  <feather-icon
                    id="tooltip-createflight"
                    v-b-modal.modalCreateFlight
                    icon="PlusIcon"
                    style="cursor: pointer"
                    size="18"
                    class="mr-1"
                  />
                  <b-tooltip target="tooltip-createflight" :delay="delay">
                    Create New Flight
                  </b-tooltip>
                  <b-modal
                    id="modalCreateFlight"
                    hide-footer
                    centered
                    size="lg"
                  >
                    <create-flight :project-id="currentProject.id" />
                  </b-modal>
                  <feather-icon
                    id="tooltip-search"
                    icon="SearchIcon"
                    style="cursor: pointer"
                    size="18"
                    @click="goSearchMenu"
                  />
                  <b-tooltip target="tooltip-search" :delay="delay">
                    Search
                  </b-tooltip>
                </div>
              </div>

              <div class="d-flex flex-row">
                <span style="font-weight: 500; color: #5e5873; font-size: 15px"
                  >Site Project : 
                </span>
              </div>
              <div class="pb-1 pr-1 d-flex flex-row">
                <p style="font-weight: 500; color: #5e5873; font-size: 15px">
                  {{ currentProject.name}} 
                </p>
              </div>

              <div
                class="btn-group btn-group-toggle flex-wrap d-flex"
                data-toggle="buttons"
              >
                <label
                  class="layer btn btn-outline-secondary"
                  :class="isLayer ? 'background-active' : ''"
                >
                  <input
                    id="layers"
                    type="radio"
                    name="options"
                    @change="layers('layer')"
                  />
                  Layers
                </label>
                <!-- <label
                  class="filter btn btn-outline-secondary"
                  :class="isFilters ? 'background-active' : ''"
                >
                  <input
                    id="filters"
                    type="radio"
                    name="options"ss
                    @change="layers('filters')"
                  />
                  Filters
                </label> -->
                <label
                  class="analytics btn btn-outline-secondary"
                  :class="isAnalytics ? 'background-active' : ''"
                >
                  <input
                    id="analytics"
                    type="radio"
                    name="options"
                    @change="layers('analytics')"
                  />
                  Analytics
                </label>
              </div>
              <hr class="mt-3 mb-2" />
              <transition name="fade" mode="out-in">
                <div v-if="isLayer" key="3">
                  <div>
                    <div
                      class="p-1 row"
                      :style="hoverHelmet ? 'background: #F8F8F8;' : ''"
                      @mouseover="hoverHelmet = true"
                      @mouseleave="hoverHelmet = false"
                    >
                      <div class="col-2">
                        <img
                          src="@/assets/images/icons/green-dot.png"
                          alt="Avatar"
                          class="mr-2"
                          width="20"
                          height="20"
                        />
                      </div>
                      <div class="col-10 row justify-content-between">
                        <div
                          :style="hoverHelmet ? 'cursor: pointer' : ''"
                          @click="
                            onItemClicked({
                              type: 'Feature',
                              properties: {
                                message: 'Smart Helmet',
                                type: 'pdf',
                                iconSize: [40, 40],
                              },
                              geometry: {
                                type: 'Point',
                                coordinates: [101.737732, 2.962813],
                              },
                              iconClass: 'greendot',
                            })
                          "
                        >
                          Smart Helmet
                        </div>
                        <div v-if="hoverHelmet" class="">
                          <feather-icon
                            :icon="isSmartHelmetHide ? 'EyeOffIcon' : 'EyeIcon'"
                            size="16"
                            class="mr-1"
                            :style="hoverHelmet ? 'cursor: pointer' : ''"
                            @click="eyeclick('smartHelmet')"
                          />
                          <feather-icon icon="TrashIcon" size="16" />
                        </div>
                      </div>
                    </div>
                     <div v-if="!hideothers">
                    <div
                      v-if="!isAerodyneDemo"
                      class="p-1 row"
                      :style="hoverMainHall ? 'background: #F8F8F8;' : ''"
                      @mouseover="hoverMainHall = true"
                      @mouseleave="hoverMainHall = false"
                    >
                      <div class="col-2">
                        <img
                          src="@/assets/images/icons/pink-dot.png"
                          alt="Avatar"
                          class="mr-2"
                          width="20"
                          height="20"
                        />
                      </div>
                      <div class="col-10 row justify-content-between">
                        <div
                          style="cursor: pointer"
                          @click="
                            onItemClicked({
                              type: 'Feature',
                              properties: {
                                message: 'Main Hall',
                                type: 'photos',
                                iconSize: [40, 40],
                              },
                              geometry: {
                                type: 'Point',
                                coordinates: [101.738339, 2.962656],
                              },
                              iconClass: 'pinkdot',
                            })
                          "
                        >
                          Main Hall
                        </div>
                        <div v-if="hoverMainHall" class="">
                          <feather-icon
                            :icon="isMainHallHide ? 'EyeOffIcon' : 'EyeIcon'"
                            size="16"
                            class="mr-1"
                            :style="hoverMainHall ? 'cursor: pointer' : ''"
                            @click="eyeclick('mainhall')"
                          />
                          <feather-icon icon="TrashIcon" size="16" />
                        </div>
                      </div>
                    </div>
                    </div>
                    <div v-if="!hiderfid">
                    <div
                      class="p-1 row"
                      :style="hoverRFID ? 'background: #F8F8F8;' : ''"
                      @mouseover="hoverRFID = true"
                      @mouseleave="hoverRFID = false"
                    >
                      <div class="col-2">
                        <img
                          src="@/assets/images/icons/yellow-dot.png"
                          alt="Avatar"
                          class="mr-2"
                          width="20"
                          height="20"
                        />
                      </div>
                      <div class="col-10 row justify-content-between">
                        <div
                          style="cursor: pointer"
                          @click="
                            onItemClicked({
                              type: 'Feature',
                              properties: {
                                message: 'RFID',
                                type: 'rfid',
                                iconSize: [40, 40],
                              },
                              geometry: {
                                type: 'Point',
                                coordinates: [101.737766, 2.962337],
                              },
                              iconClass: 'yellowdot',
                            })
                          "
                        >
                          RFID
                        </div>
                        <div v-if="hoverRFID" class="">
                          <feather-icon
                            :icon="isRfidHide ? 'EyeOffIcon' : 'EyeIcon'"
                            size="16"
                            class="mr-1"
                            :style="hoverRFID ? 'cursor: pointer' : ''"
                            @click="
                              onItemClicked({
                                type: 'Feature',
                                properties: {
                                  message: 'RFID',
                                  type: 'RFIF',
                                  iconSize: [40, 40],
                                },
                                geometry: {
                                  type: 'Point',
                                  coordinates: [
                                    [101.737515, 2.962383],
                                    [101.737513, 2.961909],
                                    [101.737946, 2.961915],
                                    [101.73792, 2.962426],
                                    [101.737515, 2.962383],
                                  ],
                                },
                                iconClass: 'reddot',
                              })
                            "
                          />
                          <feather-icon icon="TrashIcon" size="16" />
                        </div>
                      </div>
                    </div>
                    </div>
                     <div v-if="!hiderfid">
                    <div
                      class="p-1 row"
                      :style="hoverOrtho ? 'background: #F8F8F8;' : ''"
                      @mouseover="hoverOrtho = true"
                      @mouseleave="hoverOrtho = false"
                    >
                      <div class="col-2">
                        <img
                          src="@/assets/images/icons/red-dot.png"
                          alt="Avatar"
                          class="mr-2"
                          width="20"
                          height="20"
                        />
                      </div>
                      <div class="col-10 row justify-content-between">
                        <div
                          style="cursor: pointer"
                          @click="
                            onItemClicked({
                              type: 'Feature',
                              properties: {
                                message: 'Orthophoto',
                                type: 'orthophoto',
                                iconSize: [40, 40],
                              },
                              geometry: {
                                type: 'Point',
                                coordinates: [101.740112, 2.962541],
                              },
                              iconClass: 'reddot',
                            })
                          "
                        >
                          All Zones
                        </div>
                        <div v-if="hoverOrtho" class="">
                          <feather-icon
                            :icon="isOrthoHide ? 'EyeOffIcon' : 'EyeIcon'"
                            size="16"
                            class="mr-1"
                            :style="hoverOrtho ? 'cursor: pointer' : ''"
                            @click="eyeclick('ortho')"
                          />
                          <feather-icon icon="TrashIcon" size="16" />
                        </div>
                      </div>
                    </div>
                    </div>
                   <!-- <div v-if="!hideothers">
                     <div
                      class="p-1 row"
                      :style="hoverDroneData ? 'background: #F8F8F8;' : ''"
                      @mouseover="hoverDroneData = true"
                      @mouseleave="hoverDroneData = false"
                    > 
                      <div class="col-2">
                        <img
                          src="@/assets/images/icons/grey-dot.png"
                          alt="Avatar"
                          class="mr-2"
                          width="20"
                          height="20"
                        />
                      </div> -->
                      <!-- <div class="col-10 row justify-content-between">
                        <div
                          style="cursor: pointer"
                          @click="
                            onItemClicked({
                              type: 'Feature',
                              properties: {
                                message: 'Drone Data',
                                type: 'video',
                                iconSize: [40, 40],
                              },
                              geometry: {
                                type: 'Point',
                                coordinates: [101.738141, 2.962886],
                              },
                              iconClass: 'greydot',
                            })
                          "
                        >
                          Drone Data
                        </div>
                        <div v-if="hoverDroneData" class="">
                          <feather-icon
                            :icon="isDroneDataHide ? 'EyeOffIcon' : 'EyeIcon'"
                            size="16"
                            class="mr-1"
                            :style="hoverDroneData ? 'cursor: pointer' : ''"
                            @click="eyeclick('droneData')"
                          />
                          <feather-icon icon="TrashIcon" size="16" />
                        </div>
                      </div>
                    </div> 
                    </div> -->
                     <div v-if="!hideothers">
                    <div
                      class="p-1 row"
                      :style="hoverCCTV1 ? 'background: #F8F8F8;' : ''"
                      @mouseover="hoverCCTV1 = true"
                      @mouseleave="hoverCCTV1 = false"
                    >
                      <div class="col-2">
                        <img
                          src="@/assets/images/icons/purple-dot.png"
                          alt="Avatar"
                          class="mr-2"
                          width="20"
                          height="20"
                        />
                      </div>
                      <div class="col-10 row justify-content-between">
                        <div
                          style="cursor: pointer"
                          @click="
                            onItemClicked({
                              type: 'Feature',
                              properties: {
                                message: 'CCTV 1',
                                type: 'cctv',
                                iconSize: [40, 40],
                              },
                              geometry: {
                                type: 'Point',
                                coordinates: [101.738055, 2.962707],
                              },
                              iconClass: 'purpledot',
                            })
                          "
                        >
                          CCTV 1
                        </div>
                        <div v-if="hoverCCTV1" class="">
                          <feather-icon
                            :icon="isCctv1Hide ? 'EyeOffIcon' : 'EyeIcon'"
                            size="16"
                            class="mr-1"
                            :style="hoverCCTV1 ? 'cursor: pointer' : ''"
                            @click="eyeclick('cctv1')"
                          />
                          <feather-icon icon="TrashIcon" size="16" />
                        </div>
                      </div>
                    </div>
                    </div>

                    <div class="mb-3" />
                  </div>
                </div>
                <div v-if="isFilters" key="4">
                  <filters />
                </div>
                <div v-if="isAnalytics">
                  <analytics key="19" /></div
              ></transition>
            </div>
          </div>
        </transition>
      </div>
      <div>
        <b-modal
          id="modal-delete-confirm"
          v-model="showOptionsModal"
          centered
          hide-footer
          @hidden="showOptionsModal = false"
        >
          <p class="my-1 font-weight-bold">Confirm to delete this project?</p>
          <div class="float-right">
            <b-button
              variant="danger"
              class="btn-wishlist mr-1"
              @click="deleteProject"
            >
              <span>Delete</span>
            </b-button>
            <b-button
              variant="light"
              class="btn-wishlist"
              @click="$bvModal.hide('modal-delete-confirm')"
            >
              <span>Cancel</span>
            </b-button>
          </div>
        </b-modal>
      </div>
      <div v-case="'sdddsd'">
        <info-building @onClickBack="onClickBack" />
      </div>
      <GanttChartModal
        ref="modalComponent"
        :current-project="currentProject"
        @onHistoryClick="onHistoryClick"
        @OnViewGantt="OnViewGantt"
        @uploadFile="$emit('showAllTasks')"
      />
    </div>
  </div>
</template>
<style>
.onHoverProject:hover {
  background: #f8f8f8;
}
.video-js {
  position: relative !important;
  width: 100% !important;
  height: 150px !important;
  background-color: black;
  margin-top: auto;
  margin-bottom: auto;
}

.onHoverTrash:hover {
  color: red;
}
.pointer{
  cursor:pointer;
}
</style>

<script>
import {
  BInputGroup,
  BCol,
  BInputGroupAppend,
  BFormInput,
  BAvatar,
  BButton,
  BTooltip,
  // BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required, email, url } from "@validations";
import VueApexCharts from "vue-apexcharts";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import analytics from "@/views/pages/dashboard/map/sub-location/Analytics.vue";
import filters from "./sub-location/Filters.vue";
import GanttChartModal from "@/views/pages/dashboard/map/component/modal/GanttChartModal.vue";
import InfoBuilding from "./component/infoBuilding.vue";
import CreateFlight from "@/views/pages/dashboard/map/component/addFlight.vue";
import MenuRfid from "@/views/pages/dashboard/map/component/rfidSelected.vue";
import EventBus from "./eventBus";
import MenuManhour from "@/views/pages/dashboard/map/component/overallManhour.vue";
import mapViewMixin from "./Map_View";

const isProduction = process.env.NODE_ENV === "production";

export default {
  components: {
    GanttChartModal,
    analytics,
    filters,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTooltip,
    BCol,
    BAvatar,
    BButton,
    VueApexCharts,
    // BFormCheckbox,
    InfoBuilding,
    CreateFlight,
    MenuRfid,
    MenuManhour,
  },
  directives: {
    Ripple,
  },
  mixins: [mapViewMixin],
  props: {
    hiderfid: {
      type: Boolean,
      default: () => false,
    },
     hideothers: {
      type: Boolean,
      default: () => false,
    },
    boolOrtho: {
      type: Boolean,
      default: () => false,
    },
    bool3d: {
      type: Boolean,
      default: () => false,
    },
    boolSynchro: {
      type: Boolean,
      default: () => false,
    },
    boolPointCloud: {
      type: Boolean,
      default: () => false,
    },
    allProject: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      isAerodyneDemo: false,
      showOptionsModal: false,
      setDeleteProjectId: null,
      project: {
        newProjectModal: false,
        all: [],
      },
      hideArr: [],
      currentProject: {},
      manhourData: {
        series: [155],
        color: "#28C76F",
      },
      playerOptions: {
        overNative: true,
        autoplay: false,
        controls: true,
        techOrder: ["html5"],
        sourceOrder: true,
        flash: {
          hls: { withCredentials: false },
          swf: isProduction
            ? "/vue-videojs-demo/static/media/video-js.swf"
            : "/static/media/video-js.swf",
        },
        html5: { hls: { withCredentials: false } },
        sources: [
          {
            type: "rtmp/mp4",
            src: "rtmp://13.229.242.159/LiveApp/alpr",
          },
          {
            withCredentials: false,
            type: "application/x-mpegURL",
            src: "http://54.169.11.71:5080/PRISM/streams/stream.m3u8",
          },
        ],
        poster: isProduction
          ? "/vue-videojs-demo/static/images/logo.png"
          : "/static/images/logo.png",
      },
      currView: "isLeftMenuLocation",
      hover1: false,
      hover2: false,
      isLeftMenuLocation: true,
      isLayer: true,
      isFilters: false,
      isAnalytics: false,
      title: "Layers",
      isSearchMenu: false,
      isManhourMenu: false,
      required,
      email,
      url,
      currProjId: '',
      onIdx: null,
      currIdx: null,
      isMenuProfile: false,
      isMenuIot: false,
      isMenuCctv: false,
      isMenuOrtho: false,
      isMenuRfid: false,
      hoverHelmet: false,
      hoverG12: false,
      hoverG13: false,
      hoverRFID: false,
      hoverMainHall: false,
      hoverCCTV1: false,
      hoverCCTV2: false,
      hoverCCTV3: false,
      hoverCCTV4: false,
      hoverCCTV5: false,
      hoverGPSTracker: false,
      hoverOrtho: false,
      hoverSmartGlasses: false,
      hoverDroneData: false,
      isMenuNptZone: false,
      isSmartHelmetHide: false,
      isGroup12Hide: false,
      isGroup13Hide: false,
      isRfidHide: false,
      isCctv3Hide: false,
      isIdxHide: false,
      isGpsTrackerHide: false,
      isOrthorHide: false,
      isSmartGlassesHide: false,
      isDroneDataHide: false,
      isCctv1Hide: false,
      isCctv2Hide: false,
      isCctv4Hide: false,
      isCctv5Hide: false,
      isOrthoHide: false,
      isMainHallHide: false,
      isMenuMainHall: false,
      production: {
        series: [48, 52],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: ["#8769FF", "#00D4BD"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "1.5rem",
                    label: "Operational",
                    formatter() {
                      return "4";
                    },
                  },
                },
              },
            },
          },
          labels: ["Productive", "Non Productive"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      incident: {
        series: [48, 52],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: ["#8769FF", "#00D4BD"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "0.8rem",
                    label: "Current Incidents",
                    formatter() {
                      return "4";
                    },
                  },
                },
              },
            },
          },
          labels: ["Low Impact", "Medium"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    };
  },
  watch: {
    allProject: {
      handler(val) {
        console.log('project changes')
        val.forEach(project => {
          console.log(project)
          this.hideArr.push(false)
        })
      },
    },
    hideArr: {
      handler(val) {
        console.log('hide arr changes')
        console.log(val)
        this.hideArr = val
      },
    },
  },
  mounted() {
    // setTimeout(() => {
    // console.log(this.allProject[1])
    // }, 1000)

    this.playerOptions.autoplay = true;
    EventBus.$on("rfid-clicked", (data) => {
      // this.isMenuRfid = true
      this.currView = "isMenuRfid";
    });

    EventBus.$on("cctv-clicked", (data) => {
      // this.isMenuRfid = true
      this.currView = "isMenuCctv";
    });

    EventBus.$on("ortho-clicked", (data) => {
      // this.isMenuRfid = true
      this.currView = "isMenuOrtho";
    });

    // EventBus.$on("droneData-clicked", (data) => {
    //   // this.isMenuRfid = true
    //   this.currView = "isMenuNptZone";
    // });

    EventBus.$on("smartHelmet-clicked", (data) => {
      // this.isMenuRfid = true
      this.currView = "isMenuIot";
    });

    EventBus.$on("mainhall-clicked", (data) => {
      // this.isMenuRfid = true
      this.currView = "isMenuMainHall";
    });

    EventBus.$on("project-marker-clicked", (data) => {
      const coordinates = [data.latitude, data.longitude];
      this.onClickLocation(coordinates, data);
    });

    const path = window.location.host
    if (path.indexOf("aerodyne") >= 0) this.isAerodyneDemo = true
  },
  methods: {
    hideProj(projId, index) {
      const newVal = !this.hideArr[index]
      this.hideArr.splice(index, 1)
      this.hideArr.splice(index, 0, newVal)
      
      this.$emit('hideProj', projId)
    },
    projectHover(idx, projId) {
      this.currProjId = projId
      this.currIdx = idx;
    },
    getIndex(idx) {
      this.onIdx = idx;
    },
    deleteConfirmModal(id) {
      this.showOptionsModal = true;
      this.setDeleteProjectId = id;
    },
    deleteProject() {
      this.$axios
        .delete(`${this.$baseUrl}/project/${this.setDeleteProjectId}`)
        .then((response) => {
          console.log("delete project");
          console.log(response);
          if (response.status === 200 || response.status === 201) {
            this.showOptionsModal = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Info`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Successfully Deleted!`,
              },
            });
            this.$emit("deleteProject");
          }
        });
    },
    toggleAddMarkerNewProject() {
      this.$emit("toggleAddMarkerNewProject");
    },
    onPlayerReadied() {
      if (!this.initialized) {
        this.initialized = true;
        // eslint-disable-next-line no-underscore-dangle
        this.currentTech = this.player.techName_;
      }
    },
    // record current time
    onTimeupdate(e) {
      // eslint-disable-next-line no-underscore-dangle
      console.log("currentTime", e.cache_.currentTime);
    },
    activate() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.cctvDisplay = true), 500);
    },
    liveStream(urlPath) {
      this.playerOptions.sources[1].src = urlPath;
    },
    showGanttChartModal() {
      this.$refs.modalComponent.show();
    },
    backGanttChart() {
      this.$refs.modalComponent.show();
    },
    layers(activeLayer) {
      this.title = "Layers";
      if (activeLayer === "layer") {
        this.isLayer = true;
        this.isFilters = false;
        this.isAnalytics = false;
      } else if (activeLayer === "filters") {
        this.isLayer = false;
        this.isFilters = true;
        this.isAnalytics = false;
      } else {
        this.isLayer = false;
        this.isFilters = false;
        this.isAnalytics = true;
      }
    },
    goSearchMenu() {
      // this.isSearchMenu = !this.isSearchMenu
      this.currView = "isSearchMenu";
    },
    goManhourMenu() {
      console.log("manhour");
      // this.isManhourMenu = !this.isManhourMenu
      this.currView = "isManhourMenu";
    },
    onClickLocation(coordinates, project) {
      this.currentProject = project;
      const locationName = project.name;
      // this.isLeftMenuLocation = false
      console.log("tengok current project");
      console.log(this.currentProject);
      this.currView = "else";
      this.$emit("currentProject", this.currentProject);
      this.$emit("locationClicked", { coordinates, locationName });
    },
    onClickLayerBack() {
      // this.isLeftMenuLocation = true
      for (var i=0; i<this.hideArr.length; i += 1) {
        this.hideArr.splice(i, 1)
        this.hideArr.splice(i, 0, false)
      }

      this.$emit('showTimeline', false)
      this.currView = "isLeftMenuLocation";
      this.$emit("layerBackClicked", "somevalue");
    },
    onToggleFilterSwitch(layer, value) {
      this.$emit("toggleSwitched", { layer, value });
    },
    eyeclick(type) {
      if (type === "smartHelmet") {
        this.isSmartHelmetHide = !this.isSmartHelmetHide;
        const marks = document.getElementsByClassName("greendot");
        marks.forEach((data) => {
          const dot = data;
          if (dot.classList.contains("hide")) {
            dot.classList.remove("hide");
          } else {
            dot.className += " hide";
          }
        });
      } else if (type === "group12") {
        this.isGroup12Hide = !this.isGroup12Hide;
      } else if (type === "group13") {
        this.isGroup13Hide = !this.isGroup13Hide;
      } else if (type === "rfid") {
        this.isRfidHide = !this.isRfidHide;
        const marks = document.getElementsByClassName("yellowdot");
        marks.forEach((data) => {
          const dot = data;
          if (dot.classList.contains("hide")) {
            dot.classList.remove("hide");
          } else {
            dot.className += " hide";
          }
        });
      } else if (type === "cctv3") {
        this.isCctv3Hide = !this.isCctv3Hide;
        const marks = document.getElementsByClassName("purpledot");
        marks.forEach((data) => {
          const dot = data;
          if (dot.classList.contains("hide")) {
            dot.classList.remove("hide");
          } else {
            dot.className += " hide";
          }
        });
      } else if (type === "ortho") {
        this.isOrthoHide = !this.isOrthoHide;
        const marks = document.getElementsByClassName("reddot");
        marks.forEach((data) => {
          const dot = data;
          if (dot.classList.contains("hide")) {
            dot.classList.remove("hide");
          } else {
            dot.className += " hide";
          }
        });
      } else if (type === "smartGlasses") {
        this.isSmartGlassesHide = !this.isSmartGlassesHide;
        const marks = document.getElementsByClassName("pinkdot");
        marks.forEach((data) => {
          const dot = data;
          if (dot.classList.contains("hide")) {
            dot.classList.remove("hide");
          } else {
            dot.className += " hide";
          }
        });
      } else if (type === "droneData") {
        this.isDroneDataHide = !this.isDroneDataHide;
        const marks = document.getElementsByClassName("greydot");
        marks.forEach((data) => {
          const dot = data;
          if (dot.classList.contains("hide")) {
            dot.classList.remove("hide");
          } else {
            dot.className += " hide";
          }
        });
      } else if (type === "cctv1") {
        this.isCctv1Hide = !this.isCctv1Hide;
        const marks = document.getElementsByClassName("purpledot");
        marks.forEach((data) => {
          const dot = data;
          if (dot.classList.contains("hide")) {
            dot.classList.remove("hide");
          } else {
            dot.className += " hide";
          }
        });
      } else if (type === "cctv2") {
        this.isCctv2Hide = !this.isCctv2Hide;
      } else if (type === "cctv4") {
        this.isCctv4Hide = !this.isCctv4Hide;
      } else if (type === "cctv5") {
        this.isCctv5Hide = !this.isCctv5Hide;
      } else if (type === "mainhall") {
        this.isMainHallHide = !this.isMainHallHide;
        this.isSmartGlassesHide = !this.isSmartGlassesHide;
        const marks = document.getElementsByClassName("pinkdot");
        marks.forEach((data) => {
          const dot = data;
          if (dot.classList.contains("hide")) {
            dot.classList.remove("hide");
          } else {
            dot.className += " hide";
          }
        });
      }
    },
    onHistoryClick(value) {
      this.$emit("onHistoryClick", value);
    },
    OnViewGantt(value) {
      this.$emit("OnViewGantt", value);
      this.$emit("currentProject", this.currentProject);
    },
    onNewData() {
      this.$emit("onNewData");
    },
    onClickBack() {
      this.currView = "else";
    },
    onItemClicked(type, index) {
      const message = type.properties.message;
      console.log("heree");
      console.log(type);
      if (message === "CCTV 1") {
        EventBus.$emit("cctv-clicked", true);
        this.currView = "isMenuCctv";
      } else if (message === "Smart Helmet") {
        this.currView = "isMenuIot";
        EventBus.$emit("smartHelmet-clicked", true);
      } else if (message === "Orthophoto") {
        this.currView = "isMenuOrtho";
        EventBus.$emit("ortho-clicked", true);
      } else if (message === "Drone Data") {
        this.currView = "isMenuNptZone";
        EventBus.$emit("droneData-clicked", true);
      } else if (message === "Main Hall") {
        this.currView = "isMenuMainHall";
        EventBus.$emit("mainhall-clicked", true);
      } else if (message === "RFID") {
        EventBus.$emit("rfid-clicked", true);
      }
      this.$emit("onItemClicked", type);
    },
    onItemBack(type) {
      if (type === "cctv") {
        // this.isMenuCctv = false
        this.currView = "else";
      } else if (type === "smarthelmet") {
        // this.isMenuIot = false
        this.currView = "else";
      } else if (type === "dronedata") {
        // this.isMenuNptZone = false
        this.currView = "else";
      } else if (type === "rfid") {
        // this.isMenuRfid = false
        this.currView = "else";
      }
      this.$emit("onItemBack", "somevalue");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.pointer:hover {
  cursor: pointer;
  background: #f8f8f8;
}

.marker {
  /* background-image: url('mapbox-icon.png'); */
  background-size: cover;
  width: 21px;
  height: 20px;
  /* border-radius: 50%; */
  /* cursor: pointer; */
}

.background-active {
  background-color: #00ae9d;
  color: white;
}

.greendot {
  background-image: url("../../../../assets/images/icons/green-dot.png");
}

.pinkdot {
  background-image: url("../../../../assets/images/icons/pink-dot.png");
}

.purpledot {
  background-image: url("../../../../assets/images/icons/purple-dot.png");
}

.reddot {
  background-image: url("../../../../assets/images/icons/red-dot.png");
}

.yellowdot {
  background-image: url("../../../../assets/images/icons/yellow-dot.png");
}

.greydot {
  background-image: url("../../../../assets/images/icons/grey-dot.png");
}

.hide {
  display: none;
}
</style>
