<template>
  <div>
    <div class="mb-1">
      <div
        v-for="(item, index) in options"
        :key="index"
        class="d-flex justify-content-between"
        style="margin-bottom: 10px"
      >
        <b-form-checkbox
          :value="item.options"
          class=""
          stacked
        >  {{ item.text }} </b-form-checkbox>
      </div>

    </div>

    <div class="mb-2" />
    <b-button
      variant="primary"
      class="col-12"
    >
      Apply Filters
    </b-button>
    <div class="mb-3" />
  </div>
</template>
<script>
import {
  BFormCheckbox, BButton,
} from 'bootstrap-vue'

export default {
  components: {

    BFormCheckbox,
    BButton,
  },
  data() {
    return {
      filters: [],
      options: [
        { text: 'Date', value: 'Date' },
        { text: 'Time', value: 'Time' },
        // { text: 'Date & Time', value: 'Date & Time' },
        // { text: 'Latitude', value: 'Latitude' },
        // { text: 'Longitude', value: 'Longitude' },
        // { text: 'Depth', value: 'Depth' },
        // { text: 'Magnitude', value: 'Magnitude' },
        // { text: 'Mag Type', value: 'Mag Type' },
        // { text: 'NB Stations', value: 'NB Stations' },
        // { text: 'Gap', value: 'Gap' },
        // { text: 'Distance', value: 'Distance' },
        // { text: 'RMS', value: 'RMS' },
        // { text: 'Source', value: 'Source' },
        // { text: 'Event ID', value: 'Event ID' },
      ],
    }
  },
}
</script>
