<template>
  <div
    class="bg-white p-0 m-0"
  >
    <div class="p-2 d-flex justify-content-between align-items-center">
      <span style="color: #5E5873; font-weight: 500; font-size: 16px">Productivity Manhours
      </span>
      <!-- <div class="btn btn-primary">
        + Add Record
      </div> -->
    </div>
    <!-- <hr class=""> -->
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">
        <div class="d-flex align-items-center">
          <span class="mr-1">
            Show
          </span>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </div>
        <div class="d-flex align-items-center">
          <span class="mr-1">Search</span>
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <!-- <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append> -->
          </b-input-group>
        </div>
      </div></b-card-body>
    <!-- table -->
    <b-table
      ref="selectableTable"
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      show-empty
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @row-selected="onRowSelected"
      @filtered="onFiltered"
    >
      <template #cell(index)="data">
        <col style="width: 5px;">
        {{ data.index + 1 }}
      </template>
      <template #cell(employee_id)="data">
        <div class="d-flex align-items-center">
          <b-avatar :src="data.value" />
          <div class="d-flex flex-column ml-2">
            <span class="font-weight-bolder">
              {{ data.item.personal.employee_id }}

            </span>
            <span style="color: #B9B9C3">
              {{ data.item.personal.email }}
            </span>
          </div>

        </div>
      </template>
      <template #cell(actions)="">
        <div class="">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25 purpleColor"
              />
            </template>
            <b-dropdown-item>
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="d-flex justify-content-between align-items-center ml-3 mr-3 mb-2">
      <span style="color: #B9B9C3">Showing 1 to {{ perPage }} of {{ items.length }} entries</span>
      <b-pagination
        v-model="currentPage"
        :value="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="(value)=>props.pageChanged({currentPage:value})"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import {
  BCardBody, BFormInput, BInputGroup, BTable, BAvatar, BDropdown, BDropdownItem, BFormSelect, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardBody,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BTable,
    BAvatar,
    BFormSelect,
    BPagination,
    BFormInput,
    // BInputGroupAppend,
    // BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      table: {
        checkedAll: false,
        checkedItems: [],
      },
      modes: ['multi', 'single', 'range'],
      fields: [
        { key: 'employee_id', label: 'Employee Id' },
        { key: 'start_time', label: 'Start Time', sortable: true },
        { key: 'end_time', label: 'End Time', sortable: true },
        { key: 'durationA', label: 'Duration [NPT ZONE A]', sortable: true },
        { key: 'durationB', label: 'Duration [NPT ZONE B]', sortable: true },
        'actions',

      ],
      /* eslint-disable global-require */
      items: [
      ],
      /* eslint-disable global-require */
      selectMode: 'multi',
      selected: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Inactive: 'light-danger',
        Active: 'light-success',
        Pending: 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.$http.get('/analytics/data')
      .then(response => {
        const dashboardData = response.data
        this.items = dashboardData.listManHours.manHours
        this.totalRows = this.items.length
      })
  },
  methods: {
    checkAll(){
      if (this.table.checkedAll){
        this.items.forEach(staff => {
          this.table.checkedItems.push(staff.employee_id)
        })
      } else this.table.checkedItems = []
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
