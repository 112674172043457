<template>
  <div>
    <div class="d-flex justify-content-center mb-2">
      <span style="font-weight: 500; font-size: 20px">Incidents</span>
    </div>
    <vue-apex-charts
      type="donut"
      height="350"
      :series="currentIncidentChart.series"
      :options="currentIncidentChart.chartOptions"
    />
    <div class="mb-3" />
    <vue-apex-charts
      type="donut"
      height="350"
      :series="TotalIncidentChart.series"
      :options="TotalIncidentChart.chartOptions"
    />
    <div class="mb-3" />
  </div>
</template>
<script>
import {
} from 'bootstrap-vue'
import VueApexCharts from "vue-apexcharts"

export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      currentIncident: {},
      currentIncidentChart: {
        series: [36, 28, 14, 72],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: ['#00D4BD', '#8769FF', '#FFE700', '#FF9B9E'],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.3rem',
                    label: 'Current Incident',
                    formatter() {
                      return 'week of 4'
                    },
                  },
                },
              },
            },
          },
          labels: ['No Incident', 'Low Impact', 'Medium Impact', 'High Impact'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.3rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },

      TotalIncident: {},
      TotalIncidentChart: {
        series: [36, 28, 14, 72],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: ['#00D4BD', '#8769FF', '#FFE700', '#FF9B9E'],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: 'Total Incident',
                    formatter() {
                      return 'week of 4'
                    },
                  },
                },
              },
            },
          },
          labels: ['No Incident', 'Low Impact', 'Medium Impact', 'High Impact'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>
