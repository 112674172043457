<template>
  <div>
    <div class="card p-1">
      <div>
        <div class="d-flex">
          <div class="d-flex px-1 py-1">
            <feather-icon
              icon="ArrowLeftIcon"
              size="2x"
              class="mr-2"
              @click="onClickBack"
            />
            <span
              style="font-weight: 500; color: #5e5873; font-size: 18px"
            >Petronas Main Hall
            </span>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <div
            class="btn-group bg-white row"
            role="group"
            aria-label="First group"
            style="width: 100%"
          >
            <div
              type="button"
              class="btn btn-light"
              :class="tab[0].display ? 'background-active' : ''"
              @click="toggle('info')"
            >
              Info
            </div>
            <div
              type="button"
              class="btn btn-light"
              :class="tab[1].display ? 'background-active' : ''"
              @click="toggle('annotate')"
            >
              Annotate
            </div>
            <div
              type="button"
              class="btn btn-light"
              :class="tab[2].display ? 'background-active' : ''"
              @click="toggle('gallery')"
            >
              Gallery
            </div>
            <!-- <div
              type="button"
              class="btn btn-light"
              :class="tab[3].display ? 'background-active' : ''"
              @click="toggle('analytics')"
            >
              Analytics
            </div> -->
          </div>
        </div>
      </div>
      <hr class="mt-2 mb-2">
      <div
        v-show="tab[0].display"
        key="2"
      >
        <div class="pl-2">
          <div class="pb-1 d-flex flex-row">
            <span
              style="font-weight: 500; color: #5e5873; font-size: 18px"
            >Summary
            </span>
          </div>
          <div class="pb-1 pr-1 d-flex flex-row">
            <p class="font-weight-normal">
              Beyond just a space, the Petronas Main Hall
              inspires those that enter it to view humanity, technology and
              culture synergistically in a new light.
            </p>
          </div>
          <div class="pb-1 d-flex flex-row">
            <div
              class="mr-1"
              style="
                border-radius: 5px;
                width: 25px;
                height: 25px;
                padding-left: 5px;
                background-color: rgba(86, 121, 209, 0.12);
              "
            >
              <feather-icon
                icon="CircleIcon"
                size="17"
              />
            </div>
            <div class="d-flex flex-column pl-2">
              <span class="font-weight-bold"> Low </span>
              <span class="font-weight-normal"> Severity </span>
            </div>
          </div>
          <div class="pb-1 d-flex flex-row">
            <div
              class="mr-1"
              style="
                border-radius: 5px;
                width: 25px;
                height: 25px;
                padding-left: 5px;
                background-color: rgba(86, 121, 209, 0.12);
              "
            >
              <feather-icon
                icon="AlignJustifyIcon"
                size="17"
              />
            </div>
            <div class="d-flex flex-column pl-2">
              <span class="font-weight-bold"> Bangi, Selangor </span>
              <span class="font-weight-normal"> Location </span>
            </div>
          </div>
          <div class="pb-1 d-flex flex-row">
            <div
              class="mr-1"
              style="
                border-radius: 5px;
                width: 25px;
                height: 25px;
                padding-left: 5px;
                background-color: rgba(86, 121, 209, 0.12);
              "
            >
              <feather-icon
                icon="MapPinIcon"
                size="17"
              />
            </div>
            <div class="d-flex flex-column pl-2">
              <span class="font-weight-bold"> 101.6521251, 2.901180778 </span>
              <span class="font-weight-normal"> Coordinate </span>
            </div>
          </div>
          <hr>
          <span
            style="font-weight: 500; color: #5e5873; font-size: 18px"
          >Gallery
          </span>
          <div class="mt-1 row justify-content-start align-items-center">
            <div class="col-12">
              <img
                rounded
                src="@/views/pages/dashboard/map/asset/other/pic.png"
                width="100%"
              >
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="tab[1].display"
        class="p-1"
      >
        <div class="row justify-content-between align-items-center">
          <span>Annotate</span>
          <b-dropdown
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            text="Severity"
            variant="outline-dark"
            class="mr-2"
          >
            <b-dropdown-item> High </b-dropdown-item>
            <b-dropdown-item> Low </b-dropdown-item>
          </b-dropdown>
        </div>
        <div>
          <div class="mt-1 d-flex align-items-center justify-content-center">
            <img
              class="row text-center"
              rounded
              src="../asset/other/solar1.png"
              style="width: 100%"
            >
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span
                style="font-weight: 500; color: #6e6b7b"
              >Solar Inspection 1
              </span>
              <span style="color: #b9b9c3; font-size: 12px"> 12.01.2021 </span>
            </div>
            <div>
              <div
                style="
                  border-radius: 17px;
                  background-color: rgba(255, 159, 67, 0.12);
                "
              >
                <span
                  style="font-weight: 600px; font-size: 11px; color: #ff9f43"
                  class="p-1"
                >Medium</span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span>Severity</span>
              <span class="font-weight-bold">Safety</span>
            </div>
            <div class="d-flex flex-column">
              <span>Size</span>
              <span class="font-weight-bold">107.061 m2 </span>
            </div>
            <div class="d-flex flex-column">
              <span>Map Layer</span>
              <span class="font-weight-bold">Orthophoto </span>
            </div>
          </div>
          <hr class="mt-2 mb-2">
          <div class="mt-1 d-flex align-items-center justify-content-center">
            <img
              class="row text-center"
              rounded
              src="../asset/other/solar2.png"
              style="width: 100%"
            >
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span
                style="font-weight: 500; color: #6e6b7b"
              >Solar Inspection 1
              </span>
              <span style="color: #b9b9c3; font-size: 12px"> 12.01.2021 </span>
            </div>
            <div>
              <div
                style="
                  border-radius: 17px;
                  background-color: rgba(255, 159, 67, 0.12);
                "
              >
                <span
                  style="font-weight: 600px; font-size: 11px; color: #ff9f43"
                  class="p-1"
                >Medium</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="tab[2].display">
        <div class="mt-1 d-flex align-items-center justify-content-center">
          <img
            class="row text-center"
            rounded
            src="@/views/pages/dashboard/map/asset/image/1.png"
            style="width: 100%"
          >
        </div>
        <div class="mt-1 row justify-content-between align-items-center">
          <div class="col-3 mb-1">
            <img
              class="text-center"
              rounded
              src="@/views/pages/dashboard/map/asset/image/2.png"
              style="width: 100%"
            >
          </div>
          <div class="col-3 mb-1">
            <img
              class="text-center"
              rounded
              src="@/views/pages/dashboard/map/asset/image/3.png"
              style="width: 100%"
            >
          </div>
          <div class="col-3 mb-1">
            <img
              class="text-center"
              rounded
              src="@/views/pages/dashboard/map/asset/image/4.png"
              style="width: 100%"
            >
          </div>
          <div class="col-3 mb-1">
            <img
              class="text-center"
              rounded
              src="@/views/pages/dashboard/map/asset/image/5.png"
              style="width: 100%"
            >
          </div>
          <div class="col-3 mb-1">
            <img
              class="text-center"
              rounded
              src="@/views/pages/dashboard/map/asset/image/6.png"
              style="width: 100%"
            >
          </div>
          <div class="col-3 mb-1">
            <img
              class="text-center"
              rounded
              src="@/views/pages/dashboard/map/asset/image/7.png"
              style="width: 100%"
            >
          </div>
          <div class="col-3 mb-1">
            <img
              class="text-center"
              rounded
              src="@/views/pages/dashboard/map/asset/image/8.png"
              style="width: 100%"
            >
          </div>
          <div class="col-3 mb-1">
            <img
              class="text-center"
              rounded
              src="@/views/pages/dashboard/map/asset/image/9.png"
              style="width: 100%"
            >
          </div>
        </div>
      </div>
      <div v-show="tab[3].display">
        <vue-apex-charts
          type="donut"
          height="350"
          :series="currentIncidentChart.series"
          :options="currentIncidentChart.chartOptions"
        />
        <div class="mb-3" />
        <vue-apex-charts
          type="donut"
          height="350"
          :series="TotalIncidentChart.series"
          :options="TotalIncidentChart.chartOptions"
        />
      </div>
      <b-button
        class="mt-1"
        style="border-radius: 6px"
        variant="outline-dark"
        @click="onClickBack"
      >
        Back
      </b-button>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BButton } from "bootstrap-vue"
import VueApexCharts from "vue-apexcharts"
import Ripple from "vue-ripple-directive"

export default {
  directives: {
    Ripple,
  },
  components: {
    BDropdown,
    BDropdownItem,
    BButton,
    VueApexCharts,
  },
  data() {
    return {
      tab: [
        {
          name: "info",
          display: true,
        },
        {
          name: "annotate",
          display: false,
        },
        {
          name: "gallery",
          display: false,
        },
        {
          name: "analytics",
          display: false,
        },
      ],
      title: "Layers",
      currentIncident: {},
      currentIncidentChart: {
        series: [36, 28, 14, 72],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: ["#00D4BD", "#8769FF", "#FFE700", "#FF9B9E"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "14px",
                    label: "Current Incident",
                    formatter() {
                      return "2"
                    },
                  },
                },
              },
            },
          },
          labels: ["No Incident", "Low Impact", "Medium Impact", "High Impact"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },

      TotalIncident: {},
      TotalIncidentChart: {
        series: [36, 28, 14, 72],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: ["#00D4BD", "#8769FF", "#FFE700", "#FF9B9E"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "14px",
                    label: "Total Incident",
                    formatter() {
                      return "39"
                    },
                  },
                },
              },
            },
          },
          labels: ["No Incident", "Low Impact", "Medium Impact", "High Impact"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
  mounted() {
    // this.layers()
  },
  methods: {
    toggle(tabName) {
      this.tab.forEach((item, index) => {
        if (tabName === this.tab[index].name) {
          this.tab[index].display = true
        } else {
          this.tab[index].display = false
        }
      })
    },
    layers() {
      this.title = "Layers"
      document.getElementById("isLayers").style.display = "block"
      document.getElementById("isAnalytics").style.display = "none"
      document.getElementById("isFilters").style.display = "none"
    },
    filters() {
      this.title = "Filters"
      document.getElementById("isLayers").style.display = "none"
      document.getElementById("isFilters").style.display = "block"
      document.getElementById("isAnalytics").style.display = "none"
    },
    analyticss() {
      this.title = "Analytics"
      document.getElementById("isLayers").style.display = "none"
      document.getElementById("isFilters").style.display = "none"
      document.getElementById("isAnalytics").style.display = "block"
    },
    onClickBack() {
      this.$emit("onClickBack", true)
    },
  },
}
</script>
<style>
.background-active {
  background-color: #00ae9d;
  color: white;
}
</style>
