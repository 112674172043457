<template>
  <div>
    <div class="card p-1">
      <div>
        <div class="d-flex">
          <div class="d-flex px-1 py-1 align-items-center">
            <feather-icon
              icon="ArrowLeftIcon"
              size="2x"
              class="pointer mr-2"
              @click="onClickBack"
            />
            <span style="font-weight: 500; color: #5E5873; font-size:18px;">RFID
            </span>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <div
            class="btn-group bg-white row"
            role="group"
            aria-label="First group"
            style="width: 100%"
          >
            <div
              type="button"
              class="btn btn-light"
              :class="tab[0].display ? 'background-active' : ''"
              @click="toggle('all')"
            >
              All
            </div>
            <div
              type="button"
              class="btn btn-light"
              :class="tab[1].display ? 'background-active' : ''"
              @click="toggle('time in')"
            >
              Time In
            </div>
            <div
              type="button"
              class="btn btn-light"
              :class="tab[2].display ? 'background-active' : ''"
              @click="toggle('time out')"
            >
              Time Out
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-2 mb-2">
      <div
        v-show="tab[0].display"
        key="2"
      >

        <div>
          <div class="">
            <div class="pb-1 d-flex flex-row">
              <span style="font-weight: 500; color:#5E5873; font-size: 18px">Summary
              </span>
            </div>
            <div class="pb-1 pr-1 d-flex flex-row">
              <p class="font-weight-normal">
                Total hours of attendance worker inside the zone
              </p>
            </div>
            <div>
              <vue-apex-charts
                type="donut"
                height="350"
                :series="currentIncidentChart.series"
                :options="currentIncidentChart.chartOptions"
              />
            </div>
            <div class="pb-1 d-flex flex-row">
              <div
                class="d-flex justify-content-center align-items-center"
                style="border-radius: 5px; width:45px; height:45px; text-align: center; background-color: rgba(86, 121, 209, 0.12)"
              >
                <feather-icon
                  icon="UserIcon"
                  size="1.5x"
                  class="text-primary"
                />
              <!-- </a> -->
              </div>
              <div class="d-flex flex-column pl-2">
                <span
                  class=""
                  style="font-weight: 500; color: #5E5873; font-size: 17px;"
                >
                  300
                </span>
                <span class="font-weight-normal">
                  Current Worker
                </span>
              </div>
            </div>
            <div class="pb-1 d-flex flex-row">
              <div
                class="d-flex justify-content-center align-items-center"
                style="border-radius: 5px; width:45px; height:45px; text-align: center; background-color: rgba(86, 121, 209, 0.12)"
              >
                <feather-icon
                  icon="ClockIcon"
                  size="1.5x"
                  class="text-success"
                />
              <!-- </a> -->
              </div>
              <div class="d-flex flex-column pl-2">
                <span
                  class=""
                  style="font-weight: 500; color: #5E5873; font-size: 17px;"
                >
                  500
                </span>
                <span class="font-weight-normal">
                  Time In
                </span>
              </div>
            </div>
            <div class="pb-1 d-flex flex-row">
              <div
                class="d-flex justify-content-center align-items-center"
                style="border-radius: 5px; width:45px; height:45px; text-align: center; background-color: rgba(86, 121, 209, 0.12)"
              >
                <feather-icon
                  icon="ClockIcon"
                  size="1.5x"
                  class="text-warning"
                />
              <!-- </a> -->
              </div>
              <div class="d-flex flex-column pl-2">
                <span
                  class=""
                  style="font-weight: 500; color: #5E5873; font-size: 17px;"
                >
                  200
                </span>
                <span class="font-weight-normal">
                  Time Out
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="">
          <div class="row no-gutters justify-content-between align-items-center">
            <span style="font-weight: 500; font-size: 15px; color: #5E5873">Attendance List</span>
            <b-dropdown
              ref="dropdownAll"
              variant="link"
              toggle-class="text-decoration-none"
              size="md"
              no-caret
            >
              <template v-slot:button-content>
                <div
                  class="d-flex justify-content-between align-items-center border"
                  style="padding-top: 10px; padding-bottom: 10px; padding-right: 20px; padding-left: 20px; border-radius: 4px;"
                >
                  <span style="margin-right: 15px; color: #B9B9C3"> Filter </span> <feather-icon
                    icon="ChevronDownIcon"
                    size="15"
                    class="text-dark"
                  />
                </div>
              </template>
              <div class="d-flex flex-column p-1">
                <b-form-checkbox
                  v-model="selectedDT"
                  class="mb-1"
                  value="show"
                  hidden
                  inline
                >
                  Date and Time
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="selectedName"
                  class="mb-1"
                  value="show"
                  hidden
                  inline
                >
                  Staff Name
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="selectedID"
                  class="mb-1"
                  value="show"
                  hidden
                  inline
                >
                  Staff ID
                </b-form-checkbox>
              </div>

              <div class="d-flex p-1 justify-content-center align-items-center">
                <div
                  class="btn btn-primary mr-1"
                  @click="myFunction()"
                >
                  Save
                </div> <div
                  class="btn btn-light"
                  @click="clear()"
                >
                  Discard
                </div>
              </div>
            </b-dropdown>
          </div>
          <b-col
            v-if="isStaffID"
            sm="12"
          >
            <b-form-group
              label="Staff ID"
              label-for="staffID"
            >
              <b-form-input
                id="staffID"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isStaffName"
            sm="12"
          >
            <b-form-group
              label="Staff Name"
              label-for="staffName"
            >
              <b-form-input
                id="staffName"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isDate"
            sm="12"
          >
            <b-form-group
              label="Date"
              label-for="date"
            >
              <b-input-group class="mb-1">
                <b-form-input
                  id="date"
                  v-model="value"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="value"
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="en-US"
                    aria-controls="date"
                    @context="onContext"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <div class="">
            <span style="color: #B9B9C3; font-size: 11px;">OCTOBER 2021</span>
            <div
              class="d-flex justify-content-between bg-white"
              style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
            >
              <div
                class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
                style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
              >
                <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
                <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">22</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-1"
                style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
              >

                <div class="d-flex flex-column justify-content-between">

                  <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Myra Foster </span>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <div
                    style="border-radius: 17px; background: rgba(255, 159, 67, 0.12); margin-bottom: 5px;"
                    class="pr-1 pl-1"
                  >
                    <span style="color: #FF9F43; font-size: 12px; font-weight: 600; ">Time Out </span>
                  </div>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">11:03:25 AM</span></div>
              </div>

            </div>
            <div
              class="d-flex justify-content-between bg-white mt-1"
              style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
            >
              <div
                class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
                style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
              >
                <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
                <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">16</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-1"
                style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
              >

                <div class="d-flex flex-column justify-content-between">

                  <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Myra Foster </span>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <div
                    style="border-radius: 17px; background: rgba(40, 199, 111, 0.12); margin-bottom: 5px;"
                    class="pr-1 pl-1"
                  >
                    <span style="color: #228B22; font-size: 12px; font-weight: 600; ">Time In </span>
                  </div>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">11:20:47 AM</span></div>
              </div>

            </div>
          </div>
          <hr>
          <div class="">
            <span style="color: #B9B9C3; font-size: 11px;">SEPTEMBER 2021</span>
            <div
              class="d-flex justify-content-between bg-white"
              style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
            >
              <div
                class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
                style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
              >
                <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
                <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">22</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-1"
                style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
              >

                <div class="d-flex flex-column justify-content-between">

                  <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Ian Tan Zhi </span>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <div
                    style="border-radius: 17px; background: rgba(255, 159, 67, 0.12); margin-bottom: 5px;"
                    class="pr-1 pl-1"
                  >
                    <span style="color: #FF9F43; font-size: 12px; font-weight: 600; ">Time Out </span>
                  </div>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">11:03:25 AM</span></div>
              </div>

            </div>
            <div
              class="d-flex justify-content-between bg-white mt-1"
              style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
            >
              <div
                class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
                style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
              >
                <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
                <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">16</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-1"
                style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
              >

                <div class="d-flex flex-column justify-content-between">

                  <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Michael Ish</span>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <div
                    style="border-radius: 17px; background: rgba(40, 199, 111, 0.12); margin-bottom: 5px;"
                    class="pr-1 pl-1"
                  >
                    <span style="color: #228B22; font-size: 12px; font-weight: 600; ">Time In </span>
                  </div>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">10:35:20 AM</span></div>
              </div>

            </div>
            <div
              class="d-flex justify-content-between bg-white mt-1"
              style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
            >
              <div
                class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
                style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
              >
                <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
                <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">13</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-1"
                style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
              >

                <div class="d-flex flex-column justify-content-between">

                  <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">John Doe</span>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <div
                    style="border-radius: 17px; background: rgba(40, 199, 111, 0.12); margin-bottom: 5px;"
                    class="pr-1 pl-1"
                  >
                    <span style="color: #228B22; font-size: 12px; font-weight: 600; ">Time In </span>
                  </div>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">10:35:20 AM</span></div>
              </div>

            </div>
            <div
              class="d-flex justify-content-between bg-white mt-1"
              style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
            >
              <div
                class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
                style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
              >
                <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
                <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">10</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-1"
                style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
              >

                <div class="d-flex flex-column justify-content-between">

                  <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Mary Robert</span>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <div
                    style="border-radius: 17px; background: rgba(40, 199, 111, 0.12); margin-bottom: 5px;"
                    class="pr-1 pl-1"
                  >
                    <span style="color: #228B22; font-size: 12px; font-weight: 600; ">Time In </span>
                  </div>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">10:15:11 AM</span></div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-show="tab[1].display">
        <div class="">
          <div class="row no-gutters justify-content-between align-items-center">
            <span style="font-weight: 500; font-size: 15px; color: #5E5873">Attendance List</span>
            <b-dropdown
              ref="dropdownIn"
              variant="link"
              toggle-class="text-decoration-none"
              size="md"
              no-caret
            >
              <template v-slot:button-content>
                <div
                  class="d-flex justify-content-between align-items-center border"
                  style="padding-top: 10px; padding-bottom: 10px; padding-right: 20px; padding-left: 20px; border-radius: 4px;"
                >
                  <span style="margin-right: 15px; color: #B9B9C3"> Filter </span> <feather-icon
                    icon="ChevronDownIcon"
                    size="15"
                    class="text-dark"
                  />
                </div>
              </template>
              <div class="d-flex flex-column p-1">
                <b-form-checkbox
                  v-model="selectedDT"
                  class="mb-1"
                  value="show"
                  hidden
                  inline
                >
                  Date and Time
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="selectedName"
                  class="mb-1"
                  value="show"
                  hidden
                  inline
                >
                  Staff Name
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="selectedID"
                  class="mb-1"
                  value="show"
                  hidden
                  inline
                >
                  Staff ID
                </b-form-checkbox>
              </div>

              <div class="d-flex p-1 justify-content-center align-items-center">
                <div
                  class="btn btn-primary mr-1"
                  @click="myFunction()"
                >
                  Save
                </div> <div
                  class="btn btn-light"
                  @click="clear()"
                >
                  Discard
                </div>
              </div>
            </b-dropdown>
          </div>
          <b-col
            v-if="isStaffID"
            sm="12"
          >
            <b-form-group
              label="Staff ID"
              label-for="staffID"
            >
              <b-form-input
                id="staffID"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isStaffName"
            sm="12"
          >
            <b-form-group
              label="Staff Name"
              label-for="staffName"
            >
              <b-form-input
                id="staffName"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isDate"
            sm="12"
          >
            <b-form-group
              label="Date"
              label-for="date"
            >
              <b-input-group class="mb-1">
                <b-form-input
                  id="date"
                  v-model="value"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="value"
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="en-US"
                    aria-controls="date"
                    @context="onContext"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <div class="">
            <span style="color: #B9B9C3; font-size: 11px;">OCTOBER 2021</span>
            <div
              class="d-flex justify-content-between bg-white"
              style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
            >
              <div
                class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
                style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
              >
                <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
                <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">16</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-1"
                style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
              >

                <div class="d-flex flex-column justify-content-between">

                  <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Myra Foster </span>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <div
                    style="border-radius: 17px; background: rgba(40, 199, 111, 0.12); margin-bottom: 5px;"
                    class="pr-1 pl-1"
                  >
                    <span style="color: #228B22; font-size: 12px; font-weight: 600; ">Time In </span>
                  </div>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">11:20:47 AM</span></div>
              </div>

            </div>
          </div>
          <hr>
          <div class="">
            <span style="color: #B9B9C3; font-size: 11px;">SEPTEMBER 2021</span>
            <div
              class="d-flex justify-content-between bg-white mt-1"
              style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
            >
              <div
                class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
                style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
              >
                <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
                <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">16</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-1"
                style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
              >

                <div class="d-flex flex-column justify-content-between">

                  <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Michael Ish</span>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <div
                    style="border-radius: 17px; background: rgba(40, 199, 111, 0.12); margin-bottom: 5px;"
                    class="pr-1 pl-1"
                  >
                    <span style="color: #228B22; font-size: 12px; font-weight: 600; ">Time In </span>
                  </div>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">10:35:20 AM</span></div>
              </div>

            </div>
            <div
              class="d-flex justify-content-between bg-white mt-1"
              style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
            >
              <div
                class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
                style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
              >
                <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
                <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">13</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-1"
                style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
              >

                <div class="d-flex flex-column justify-content-between">

                  <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">John Doe</span>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <div
                    style="border-radius: 17px; background: rgba(40, 199, 111, 0.12); margin-bottom: 5px;"
                    class="pr-1 pl-1"
                  >
                    <span style="color: #228B22; font-size: 12px; font-weight: 600; ">Time In </span>
                  </div>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">10:35:20 AM</span></div>
              </div>

            </div>
            <div
              class="d-flex justify-content-between bg-white mt-1"
              style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
            >
              <div
                class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
                style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
              >
                <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
                <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">10</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-1"
                style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
              >

                <div class="d-flex flex-column justify-content-between">

                  <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Mary Robert</span>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <div
                    style="border-radius: 17px; background: rgba(40, 199, 111, 0.12); margin-bottom: 5px;"
                    class="pr-1 pl-1"
                  >
                    <span style="color: #228B22; font-size: 12px; font-weight: 600; ">Time In </span>
                  </div>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">10:15:11 AM</span></div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-show="tab[2].display">
        <div class="">
          <div class="row no-gutters justify-content-between align-items-center">
            <span style="font-weight: 500; font-size: 15px; color: #5E5873">Attendance List</span>
            <b-dropdown
              ref="dropdownOut"
              variant="link"
              toggle-class="text-decoration-none"
              size="md"
              no-caret
            >
              <template v-slot:button-content>
                <div
                  class="d-flex justify-content-between align-items-center border"
                  style="padding-top: 10px; padding-bottom: 10px; padding-right: 20px; padding-left: 20px; border-radius: 4px;"
                >
                  <span style="margin-right: 15px; color: #B9B9C3"> Filter </span> <feather-icon
                    icon="ChevronDownIcon"
                    size="15"
                    class="text-dark"
                  />
                </div>
              </template>
              <div class="d-flex flex-column p-1">
                <b-form-checkbox
                  v-model="selectedDT"
                  class="mb-1"
                  value="show"
                  hidden
                  inline
                >
                  Date and Time
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="selectedName"
                  class="mb-1"
                  value="show"
                  hidden
                  inline
                >
                  Staff Name
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="selectedID"
                  class="mb-1"
                  value="show"
                  hidden
                  inline
                >
                  Staff ID
                </b-form-checkbox>
              </div>

              <div class="d-flex p-1 justify-content-center align-items-center">
                <div
                  class="btn btn-primary mr-1"
                  @click="myFunction()"
                >
                  Save
                </div> <div
                  class="btn btn-light"
                  @click="clear()"
                >
                  Discard
                </div>
              </div>
            </b-dropdown>
          </div>
          <b-col
            v-if="isStaffID"
            sm="12"
          >
            <b-form-group
              label="Staff ID"
              label-for="staffID"
            >
              <b-form-input
                id="staffID"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isStaffName"
            sm="12"
          >
            <b-form-group
              label="Staff Name"
              label-for="staffName"
            >
              <b-form-input
                id="staffName"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isDate"
            sm="12"
          >
            <b-form-group
              label="Date"
              label-for="date"
            >
              <b-input-group class="mb-1">
                <b-form-input
                  id="date"
                  v-model="value"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="value"
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="en-US"
                    aria-controls="date"
                    @context="onContext"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <div class="">
            <span style="color: #B9B9C3; font-size: 11px;">OCTOBER 2021</span>
            <div
              class="d-flex justify-content-between bg-white"
              style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
            >
              <div
                class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
                style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
              >
                <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
                <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">22</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-1"
                style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
              >

                <div class="d-flex flex-column justify-content-between">

                  <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Myra Foster </span>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <div
                    style="border-radius: 17px; background: rgba(255, 159, 67, 0.12); margin-bottom: 5px;"
                    class="pr-1 pl-1"
                  >
                    <span style="color: #FF9F43; font-size: 12px; font-weight: 600; ">Time Out </span>
                  </div>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">11:03:25 AM</span></div>
              </div>

            </div>
          </div>
          <hr>
          <div class="">
            <span style="color: #B9B9C3; font-size: 11px;">SEPTEMBER 2021</span>
            <div
              class="d-flex justify-content-between bg-white"
              style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
            >
              <div
                class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
                style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
              >
                <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
                <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">22</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between p-1"
                style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
              >

                <div class="d-flex flex-column justify-content-between">

                  <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Ian Tan Zhi </span>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-between">
                  <div
                    style="border-radius: 17px; background: rgba(255, 159, 67, 0.12); margin-bottom: 5px;"
                    class="pr-1 pl-1"
                  >
                    <span style="color: #FF9F43; font-size: 12px; font-weight: 600; ">Time Out </span>
                  </div>
                  <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">11:03:25 AM</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-button
        class="mt-1"
        style="border-radius: 6px"
        variant="outline-dark"
        @click="onClickBack"
      >
        Back
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BDropdown, BButton, BFormCheckbox, BCol, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from "vue-apexcharts"

export default {
  directives: {
    Ripple,
  },
  components: {
    BDropdown,
    BButton,
    BFormCheckbox,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormDatepicker,
    VueApexCharts,
  },
  data() {
    return {
      tab: [
        {
          name: 'all',
          display: true,
        },
        {
          name: 'time in',
          display: false,
        },
        {
          name: 'time out',
          display: false,
        },
      ],
      value: '',
      selectedDT: 'hide',
      selectedName: 'hide',
      selectedID: 'hide',
      isDate: false,
      isStaffID: false,
      isStaffName: false,
      title: 'Layers',
      currentIncident: {},
      currentIncidentChart: {
        series: [90, 10],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: ['#00D4BD', '#8769FF'],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                },
              },
            },
          },
          labels: ['PT', 'NPT'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },

      TotalIncident: {},
      TotalIncidentChart: {
        series: [36, 28, 14, 72],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: ['#00D4BD', '#8769FF', '#FFE700', '#FF9B9E'],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '14px',
                    label: 'Total Incident',
                    formatter() {
                      return '39'
                    },
                  },
                },
              },
            },
          },
          labels: ['No Incident', 'Low Impact', 'Medium Impact', 'High Impact'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
  mounted() {
  },
  methods: {
    toggle(tabName){
      this.tab.forEach((item, index) => {
        if (tabName === this.tab[index].name){
          this.tab[index].display = true
        } else {
          this.tab[index].display = false
        }
      })
    },
    onClickBack() {
      this.$emit('onClickBack', true)
    },
    onContext(ctx) {
      // The following will be an empty string until a valid date is entered
      this.selectedDate = ctx.selectedYMD
    },
    myFunction(){
      this.isStaffID = this.selectedID === 'show'
      this.isStaffName = this.selectedName === 'show'
      this.isDate = this.selectedDT === 'show'
      this.$refs.dropdownAll.hide(true)
      this.$refs.dropdownIn.hide(true)
      this.$refs.dropdownOut.hide(true)
    },
    clear(){
      this.selectedDT = 'hide'
      this.selectedID = 'hide'
      this.selectedName = 'hide'
      this.isStaffID = false
      this.isStaffName = false
      this.isDate = false
      this.$refs.dropdownAll.hide(true)
      this.$refs.dropdownIn.hide(true)
      this.$refs.dropdownOut.hide(true)
    },
  },
}
</script>
<style>
.background-active {
  background-color: #00AE9D;
  color: white;
}
</style>
