<template>
  <div>
    <div class="card p-1">
      <div>
        <div class="d-flex">
          <div class="d-flex px-1 py-1">
            <!-- <feather-icon
              icon="ArrowLeftIcon"
              size="2x"
              class="mr-2"
            /> -->
            <span style="font-weight: 500; color: #5E5873; font-size:18px;">3D Model</span>
          </div>
        </div>

      </div>
      <hr class="mt-1">
      <div class="d-flex justify-content-start">
        <!-- <div -->
        <!-- class="btn-group"
        role="group"
        aria-label="First group"
        > -->
        <span
          id="layers"
          style="font-weight: 500; color: #5E5873; font-size:15px;"
        >
          Info
        </span>
        <!-- <button
              id="filters"
              type="button"
              class="btn btn-light"
              @click="filters()"
            >
              Filter
            </button>
            <button
              id="analytics"
              type="button"
              class="btn btn-light"
              @click="analyticss()"
            >
              Analytics
            </button> -->
        <!-- </div> -->
      </div>
      <div>
        <div class="d-flex flex-column">
          <div class="d-flex mb-1 mt-2">
            <span>General Information </span><span style="margin-right: 3px; margin-left:3px;">-</span><span class="text-primary"> Image 1</span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Data Name</span> <span>
              Air Chiller Zone A
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Data ID</span> <span>
              234ASD12
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Section</span> <span>
              H.264, MJPEG
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Focal Length</span> <span>
              1
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Video Compression</span> <span>
              N/A

            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Analogue Out</span> <span>
              CVBS 1.0Vp-p
            </span>
          </div>
        </div>
        <hr class="mt-2 mb-2">
        <div class="d-flex flex-column mb-1">
          <div
            class="d-flex mb-1"
            style="font-weight: 600;"
          >
            <span>General Information </span><span style="margin-right: 3px; margin-left:3px;">-</span><span class="text-primary"> Image 1</span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Data Name</span> <span>
              Air Chiller Zone A
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Data ID</span> <span>
              234ASD12
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Section</span> <span>
              H.264, MJPEG
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Focal Length</span> <span>
              1
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Video Compression</span> <span>
              N/A

            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Analogue Out</span> <span>
              CVBS 1.0Vp-p
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Memory Slot</span> <span>
              Yes (SD/SDHC)
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Alarm I/O</span> <span>
              1/1

            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Day & Night</span> <span>
              Yes
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 5px; font-weight: 400"
          >
            <span>Image Sensor</span> <span>
              1/2.8” 3M PS CMOS
            </span>
          </div>
        </div>
      </div>
      <!-- <div
        id="isFilters"
        key="3"
      >
        filter here
      </div>
      <div
        id="isAnalytics"
        key="4"
      >
        analytic here
      </div> -->
    </div>

  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      layer: '',
      filter: '',
      analytics: '',
      title: 'Layers',
    }
  },
  mounted() {
    // this.layers()
  },
  methods: {
    layers() {
      this.title = "Layers"
      document.getElementById("isLayers").style.display = "block"
      document.getElementById("isAnalytics").style.display = "none"
      document.getElementById("isFilters").style.display = "none"
    },
    filters() {
      this.title = "Filters"
      document.getElementById("isLayers").style.display = "none"
      document.getElementById("isFilters").style.display = "block"
      document.getElementById("isAnalytics").style.display = "none"
    },
    analyticss() {
      this.title = "Analytics"
      document.getElementById("isLayers").style.display = "none"
      document.getElementById("isFilters").style.display = "none"
      document.getElementById("isAnalytics").style.display = "block"
    },
  },
}
</script>
