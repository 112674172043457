<template>
  <div>
    <div
      v-if="dummyData"
      no-body
    >
      <b-col md="12">
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <select
            v-model="dropdownView"
            class="form-control"
            style="border: none; width: 15%"
          >
            <option value="actual">
              Actual Manhours Productivity
            </option>
            <option value="productivity">
              Productivity Manhours
            </option>
          </select>
          <div v-if="dropdownView === 'actual'">
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
                class="mr-1"
              />
              01/09/2021 - 30/09/2020
            </div>
          </div>
        </div>

        <!-- chart -->
        <div v-if="dropdownView === 'actual'">
          <vue-apex-charts
            id="revenue-report-chart"
            type="bar"
            height="430"
            :options="revenueReport.chartOptions"
            :series="dummyData.revenueReport.series"
          />
        </div>
        <div v-if="dropdownView === 'productivity'">
          <productivity-manpowers />
        </div>
      </b-col>
    </div>
  </div>
</template>
<style>
.select {
  -webkit-appearance: none !important;
  appearance: none !important;
  -moz-appearance: none !important;
}
</style>

<script>
import { BCol } from "bootstrap-vue"
// BDropdown, BDropdownDivider, BDropdownItem,
import VueApexCharts from "vue-apexcharts"
import { $themeColors } from "@themeConfig"
import Ripple from "vue-ripple-directive"
import productivityManpowers from "../productivityManpowers.vue"

export default {
  components: {
    VueApexCharts,
    // BCard,
    BCol,
    productivityManpowers,
    // BDropdownDivider,
    // BDropdownItem,
    // BDropdown,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dropdownView: "actual",
      dummyData: {},
      revenue_report: {},
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: "bar",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: [
              "12/9",
              "13/9",
              "14/9",
              "15/9",
              "16/9",
              "17/9",
              "18/9",
              "19/9",
              "20/9",
              "21/9",
              "22/9",
              "23/9",
            ],
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          // colors: [$themeColors.primary, $themeColors.warning],
          colors: ["#7367F0", $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: "17%",
              endingShape: "rounded",
              borderRadius: [10, 10],
              startingShape: "rounded",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
          },
        },
      },
    }
  },
  computed: {},
  created() {
    // data
    this.$http.get("/ecommerce/data").then(response => {
      this.dummyData = response.data.revenue
    })
  },
  mounted() {
    console.log("test manhour")
  },

  methods: {
    productivityManhours() {
      this.$bvModal.show("modal-image")
    },
    display(val) {
      console.log(val)
    },
  },
}
</script>
