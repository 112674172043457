<template>
  <div>
    <!--
    <b-button
      variant="outline-primary"
      @click="show()"
    >
      Actual Manhours Productivity
    </b-button> -->
    <!-- modal vertical center -->
    <b-modal
      id="gantt-chart"
      hide-footer
      centered
      header-bg-variant="white"
      size="lg"
      @show="clearModal"
    >
      <div v-show="isHistory">
        <div class="row justify-content-between align-items-center pl-1 pt-0">
          <div>
            <p style="font-size:150%; font-weight: 20%;"> Gantt Chart
            </p>
            <span>Create a new gantt chart or load data from previous history</span>
          </div>
        </div>
        <hr>
        <div class="d-flex flex-row">
          <div class="col-6">
            <div
              class="d-flex flex-row mt-2"
              @click="changeActive('history')"
            >
              <div class="pl-2 pr-2">
                <div
                  style="border-radius: 6px; width:50px; height:40px; background-color: rgb(11, 158, 131); box-shadow: 0px 3px 6px rgba(0, 174, 157, 0.4);"
                  class="d-flex align-items-center justify-content-center "
                >
                  <feather-icon
                    class=""
                    icon="CommandIcon"
                    size="2x"
                    color="white"
                  />
                </div>
              </div>
              <div class="d-flex flex-column align-items-start">
                <span
                  class="font-weight-bold text-success"
                >
                  History
                </span>
                <div
                  class="pb-1 d-flex justify-content-center"
                >
                  <span>Continue from previous data </span>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-row pt-2"
              @click="changeActive('upload')"
            >
              <div class="pl-2 pr-2 d-flex justify-content-center">
                <div
                  style="border-radius: 6px; width:50px; height:40px; background-color: rgb(186, 191, 199, 0.12)"
                  class="d-flex align-items-center justify-content-center "
                >
                  <feather-icon
                    class=""
                    icon="BoxIcon"
                    size="2x"
                  />
                </div>
              </div>
              <div class="d-flex flex-column align-items-start">
                <span
                  class="font-weight-bold"
                >
                  Upload Files
                </span>
                <div
                  class="pb-1 d-flex justify-content-center"
                >
                  <span>Upload new data</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row pb-2">
              <b-input-group class="input-group-merge">
                <b-form-input placeholder="Search here" />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-append>
              </b-input-group>
            </div>
            <div
              style="height: 250px; overflow: auto;"
              class="row d-flex justify-content-start pb-2"
            >
              <div
                v-for="(history, index) in historyGantt"
                :key="index"
                :value="history.id"
                style="width: 100%;"
              >
                <div
                  class="d-flex justify-content-between pt-2"
                  style="width:100%"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="">
                      <div
                        style="border-radius: 5px; width:50px; height:40px; background-color: rgba(94, 88, 115, 0.12)"
                        class="d-flex justify-content-center align-items-center"
                      >
                        <feather-icon
                          class="mt-0 ml-0"
                          icon="ClockIcon"
                          size="2x"
                        />
                      </div>
                    </div>
                    <!--  hoverCursor

                 @click="onHistoryClick" -->
                    <div
                      class="pr-4 pl-2"
                    >
                      <span
                        class="font-weight-bold"
                      >
                        {{ history.name }}
                      </span>
                    <!-- <div
                      class="pb-1 d-flex justify-content-center"
                    >
                      <span>Petronas Leadership Centre</span>
                    </div> -->
                    </div>
                  </div>

                  <div
                    class="pr-1"
                    style=""
                  >
                    <span>
                      {{ history.createdAt }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-end pt-2">
              <div
                class="btn btn-primary mr-2"
                style="width: 150px"
                @click="changeActive('new data')"
              >
                Add New
              </div>
              <!--  <div
                class="btn btn-primary"
                style="width: 150px"
              >
                Select
              </div> -->
              <div
                class="btn btn-primary"
                style="width: 150px"
                @click="OnViewGantt"
              >
                View Gantt
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="isUploadFiles">
        <div class="row justify-content-between align-items-center pt-0 pl-1">
          <div>
            <p>
              <strong> Gantt Chart</strong>
            </p>
            <span>Create a new gantt chart or load data from previous history</span>
          </div>
          <div
            class="d-flex"
            style="cursor: pointer; "
          >
            <a
              :href="`${publicPath}files/wbs.xlsx`"
              style="color: white"
              class=""
              download
            >
              <div
                class="d-flex align-items-center justify-content-center p-1"
                style="background-color: #448E47; box-shadow: 1px 1px 5px 2px #ccc; border-radius: 5px;"
              >
                <div class="mr-1">
                  <i
                    class="fas fa-download"
                    style="color: white; font-size: 18px"
                  />
                </div>
                WBS Template
              </div>
            </a>
          </div>
        </div>

        <hr>
        <div class="d-flex flex-row">
          <div class="col-6">
            <div
              class="d-flex flex-row mt-2"
              @click="changeActive('history')"
            >
              <div class="pl-2 pr-2">
                <div
                  style="border-radius: 5px; width:50px; height:40px; background-color: rgb(186, 191, 199, 0.12)"
                  class="d-flex align-items-center justify-content-center "
                >
                  <feather-icon
                    class=""
                    icon="CommandIcon"
                    size="2x"
                  />
                </div>
              </div>
              <div class="d-flex flex-column align-items-start">
                <span
                  class="font-weight-bold"
                >
                  History
                </span>
                <div
                  class="pb-1 d-flex justify-content-center"
                >
                  <span>Continue from previous data </span>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-row pt-2"
              @click="changeActive('upload')"
            >
              <div class="pl-2 pr-2 d-flex justify-content-center">
                <div
                  style="border-radius: 6px; width:50px; height:40px; background-color: rgb(11, 158, 131); box-shadow: 0px 3px 6px rgba(0, 174, 157, 0.4);"
                  class="d-flex align-items-center justify-content-center "
                >
                  <feather-icon
                    class=""
                    icon="BoxIcon"
                    size="2x"
                    color="white"
                  />
                </div>
              </div>
              <div class="d-flex flex-column align-items-start">
                <span
                  class="font-weight-bold text-success"
                >
                  Upload Files
                </span>
                <div
                  class="pb-1 d-flex justify-content-center"
                >
                  <span>Upload new data</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-column col-6">
            <!-- <div class="col-6"> -->

            <!-- <div
              class="container-fluid d-flex align-items-center"
              style="border:1px dashed #D8D6DE; height: 200px"
            > -->

            <div class="align-items-center">
              <vue-dropzone
                id="dropzone"
                ref="vue2Dropzone"
                class="dropzoneClass"
                :options="dropzoneOptions"
                @vdropzone-success="uploadFile"
              ><div
                class="dz-default dz-message"
                data-dz-message
              >
                <span>Your Custom Message</span>
              </div></vue-dropzone>

              <!-- <span
                  style="color: #5E5873; font-size: 18px; font-weight: 500"
                  class="mb-1"
                />
                <span               style="color: #5E5873"
                  class="mb-1"
                >or</span>
                <div
                  class="btn btn-primary"
                  style="width: 300px"
                >
                  Browse File
                </div> -->

              <!-- </div> -->

              <!-- </div> -->
              <!-- <div class="col text-center">
                <button
                  :disabled="isUpload"
                  class="btn btn-primary mt-2"
                  style="width: 150px"
                  @click="uploadFile"
                >
                  Upload
                </button>
              </div> -->
            </div>

          </div>
        </div>
      </div>
      <div v-show="isNewData">
        <div>
          <feather-icon
            class="mr-1 mb-2"
            icon="ArrowLeftIcon"
            style="cursor:pointer;"
            size="2x"
            @click="onCancel"
          />
        </div>

        <div class="row justify-content-between align-items-center p-1">
          <div>
            <p><strong> Add New Data</strong></p>
            <span>Add data to visualise layer on top of the map</span>
          </div>
        </div>
        <validation-observer ref="simpleRules">
          <div class="row">

            <div class="col-6 form-group">
              <label
                style="color: #6E6B7B;"
                for="Task"
              ><span style="color: red">* </span>Task</label>
              <validation-provider
                #default="{ errors }"
                name="Task Name"
                rules="required"
              >
                <input
                  v-model="task.name"
                  type="text"
                  class="form-control"
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-6 form-group">
              <label
                style="color: #6E6B7B;"
                for="Task"
              ><span style="color: red">* </span>WBS</label>
              <validation-provider
                #default="{ errors }"
                name="WBS Code"
                rules="required"
              >
                <input
                  v-model="task.wbsCode"
                  type="text"
                  class="form-control"
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-6 form-group">
              <label
                style="color: #6E6B7B;"
                for="Task"
              ><span style="color: red">* </span>Planned Start</label>
              <validation-provider
                #default="{ errors }"
                name="Start Date"
                rules="required"
              >
                <input
                  v-model="task.startDate"
                  type="date"
                  class="form-control"
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-6 form-group">
              <label
                style="color: #6E6B7B;"
                for="Task"
              ><span style="color: red">* </span>Planned End</label>
              <validation-provider
                #default="{ errors }"
                name="End Date"
                rules="required"
              >
                <input
                  v-model="task.endDate"
                  type="date"
                  class="form-control"
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-6 form-group">
              <label
                style="color: #6E6B7B;"
                for="Task"
              ><span style="color: red">* </span>Actual Progress (%)</label>
              <validation-provider
                #default="{ errors }"
                name="Actual Progress"
                rules="required"
              >
                <input
                  v-model="task.actualProgress"
                  type="text"
                  maxlength="4"
                  class="form-control"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-6 form-group">
              <label
                style="color: #6E6B7B;"
                for="Task"
              ><span style="color: red">* </span>Planned Progress (%)</label>
              <validation-provider
                #default="{ errors }"
                name="Planned Progress"
                rules="required"
              >
                <input
                  v-model="task.plannedProgress"
                  type="text"
                  maxlength="4"
                  class="form-control"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div
              class="ml-1 mt-2 btn btn-primary"
              @click="apiSaveNewData()"
            >
              Add Task
            </div>
            <div
              class="ml-1 mt-2 btn btn-primary"
              @click="OnViewGantt"
            >
              View Gantt
            </div>
          </div>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>
<style>
.hoverCursor{
  cursor:pointer;
}

.dropzoneClass{
  background-color: white;
  font-family: "Montserrat", Helvetica, Arial, serif;
  border:1px dashed #D8D6DE;
}

.dropzoneClass .dz-preview .dz-details {
  background-color: white;
  color: black;
  widows: 50%;
  height: 30%;
}

.dropzoneClass .dz-preview .dz-image img:not([src]) {
  height: 1%;
}
</style>
<script>
import {
  BModal, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
// import DropZone from 'dropzone-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from "moment"
import vue2Dropzone from 'vue2-dropzone'
// eslint-disable-next-line import/order
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// Import the CSS or use your own!
// import "vue-toastification/dist/index.css"
// optionally import default styles
import 'dropzone-vue/dist/dropzone-vue.common.css'
import { required } from '@validations'

export default {

  components: {
    vueDropzone: vue2Dropzone,
    // DropZone,
    // BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    FeatherIcon,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
  },
  props: {
    currentProject: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      historyGantt: [],
      required,
      isHistory: true,
      publicPath: process.env.BASE_URL,
      isUpload: true,
      isUploadFiles: false,
      isNewData: false,
      getFile: {},
      error: {
        taskName: false,
        startDate: false,
        endDate: false,
        plannedProgress: false,
        actualProgress: false,
        wbsCode: false,
      },
      task: {
        name: null,
        startDate: null,
        endDate: null,
        plannedProgress: null,
        actualProgress: null,
        wbsCode: null,
        zones: [],
        defects: [],
        files: [],
        manpowers: [],
      },
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        acceptedFiles: ".csv, .xlsx",
        maxFiles: 1,
        // clickable: false,
        dictDefaultMessage: 'Drop your files here<p>or</p><div class="btn btn-primary" style="width: 150px" @click="uploadFile">Browse</div>',
        // thumbnailWidth: 150,
        // maxFilesize: 0.5,
        // autoProcessQueue: false,
      },
    }
  },
  computed: {
  },
  created() {
    this.getHistoryGantt()
    this.addScroll()
    // data
    // this.$http.get('/ecommerce/data')
    //   .then(response => {
    //     this.dummyData = response.data
    //   })
  },

  methods: {
    addScroll() {
      window.addEventListener('scroll', this.handleScroll)
    },
    getHistoryGantt(){
      this.historyGantt = []
      this.$axios.get(`${this.$baseUrl}/taskGroups`).then(response => {
        if (response.status === 200 || response.status === 201) {
          this.historyGantt = response.data
          this.historyGantt.forEach(hist => {
            const date = new Date(hist.createdAt)
            // eslint-disable-next-line no-param-reassign
            hist.createdAt = date.toLocaleDateString()
          })
          console.log(this.historyGantt)
        } else {
        // this.$toast({
        //   component: ToastificationContent,
        //   position: "top-right",
        //   props: {
        //     title: `Info`,
        //     icon: "CoffeeIcon",
        //     variant: "danger",
        //     text: `Project Failed to add!`,
        //   },
        // })
        }
      })
    },
    onCancel(){
      this.isNewData = false
      this.isHistory = true
    },
    show(){
      this.$bvModal.show('gantt-chart')
    },
    afterComplete(file) {
      this.isUpload = false

      this.getFile = file
    },
    uploadFile(value) {
      console.log(`tengok file: ${this.currentProject.id}`)
      console.log(value)
      console.log(value.name)
      const formData = new FormData()
      formData.append('projectId', this.currentProject.id)
      // formData.append('bucket', 'gallery')
      formData.append('file', value)

      this.$axios.post(`${this.$baseUrl}/taskGroups`, {
        name: value.name,
      }).then(response => {
        if (response.status === 200 || response.status === 201) {
          formData.append('taskgroupId', response.data.id)
          this.$axios.post(`${this.$baseUrl}/tasks/uploadExcel`, formData).then(response1 => {
            if (response1.status === 200 || response1.status === 201) {
              console.log(response1)
              this.OnUploadGanttComplete()
              // this.$refs.vue2Dropzone.removeAllFiles()
              this.getHistoryGantt()
              this.$toast.success("File Successully Uploaded!")
              this.$emit('uploadFile')
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Info`,
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: `Project Failed to add!`,
                },
              })
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: `Project Failed to add!`,
            },
          })
        }
      })

      // this.$axios.post(`${this.$baseUrl}/S3upload/create`, formData).then(response => {
      //   if (response.status === 200) {
      //     this.$refs.vue2Dropzone.removeAllFiles()
      //     this.$toast.success("File Successully Uploaded!")
      //   } else {
      //     this.$toast({
      //       component: ToastificationContent,
      //       position: "top-right",
      //       props: {
      //         title: `Info`,
      //         icon: "CoffeeIcon",
      //         variant: "danger",
      //         text: `Project Failed to add!`,
      //       },
      //     })
      //   }
      // })
      console.log(formData)
      // this.$axios.post(`${this.$baseUrl}/tasks/uploadExcel`, formData).then(response => {
      //   if (response.status === 200 || response.status === 201) {
      //     console.log(response)
      //     this.OnUploadGanttComplete()
      //     // this.$refs.vue2Dropzone.removeAllFiles()
      //     this.$toast.success("File Successully Uploaded!")
      //     this.$emit('uploadFile')
      //   } else {
      //     this.$toast({
      //       component: ToastificationContent,
      //       position: "top-right",
      //       props: {
      //         title: `Info`,
      //         icon: "CoffeeIcon",
      //         variant: "danger",
      //         text: `Project Failed to add!`,
      //       },
      //     })
      //   }
      // })
    },
    getHistory() {
      this.$axios.get(`${this.$baseUrl}/taskslog`).then(response => {
        if (response.status === 200 || response.status === 201) {
          console.log(response)
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   position: "top-right",
          //   props: {
          //     title: `Info`,
          //     icon: "CoffeeIcon",
          //     variant: "danger",
          //     text: `Project Failed to add!`,
          //   },
          // })
        }
      })
    },
    changeActive(type) {
      if (type === 'history') {
        this.isHistory = true
        this.isUploadFiles = false
        this.isNewData = false
      } else if (type === 'new data'){
        this.isHistory = false
        this.isUploadFiles = false
        this.isNewData = true
      } else if (type === 'upload'){
        this.isHistory = false
        this.isNewData = false
        this.isUploadFiles = true
      }
    },
    onHistoryClick() {
      this.$emit('onHistoryClick', 'valueHere')
      this.$bvModal.hide('gantt-chart')
    },
    OnViewGantt(){
      this.$emit('OnViewGantt', '0')
      this.$bvModal.hide('gantt-chart')
    },
    OnUploadGanttComplete(){
      this.$bvModal.hide('gantt-chart')
      this.$emit('OnViewGantt', '0')
    },
    onNewData(){
      this.$bvModal.hide('gantt-chart')
      this.modalAddNewData = true
      this.$emit('onNewClick')
    },
    clearModal(){
      this.isHistory = true
      this.isUploadFiles = false
      this.isNewData = false
    },
    apiSaveNewData(){
      this.$refs.simpleRules.validate().then(success => {
        if (success){
          console.log(Number(this.task.actualProgress))
          if (Number(this.task.actualProgress) <= 100) {
            const newData = {
              name: this.task.name,
              // startDate: moment(this.task.startDate).format('DD-MM-YYYY'),
              startDate: moment(this.task.startDate).toDate().valueOf(),
              endDate: moment(this.task.endDate).toDate().valueOf(),
              plannedProgress: Number(this.task.plannedProgress),
              actualProgress: Number(this.task.actualProgress),
              wbsCode: this.task.wbsCode,
              // zones: this.task.zones,
              // defects: this.task.defects,
              // files: this.task.files,
              // manpowers: this.task.manpowers,
              project: this.currentProject.id,
              taskGroup: "",
            }
            this.$axios.post(`${this.$baseUrl}/tasks/createOrUpdate`, newData).then(response => {
              console.log(response)
              if (response.status === 201 || response.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Info`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `Task Successfully Added!`,
                  },
                })
                this.OnViewGantt()
                // this.task = {
                //   name: null,
                //   startDate: null,
                //   endDate: null,
                //   plannedProgress: null,
                //   actualProgress: null,
                //   wbsCode: null,
                //   zones: [],
                //   defects: [],
                //   files: [],
                //   manpowers: [],
                // }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Info`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: `Task Failed to add!`,
                  },
                })
              }
            })
          } else {
            this.$swal({
              icon: 'error',
              title: 'Error',
              text: 'Planned Progress (%) should not exceed 100',
              showConfirmButton: true,
              allowOutsideClick: true,
              // timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        }
      })
    },
  },
}
</script>
