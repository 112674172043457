import moment from 'moment'

export default {
  data(){
    return {
      rfidSite: [
        {
          id: 'rfid-1',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {
                    // site: 'Engineering Task 1',
                    site: 'RFID A',
                    id: 'rfid-1',
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [101.737515, 2.962383],
                        [101.737513, 2.961909],
                        [101.737946, 2.961915],
                        [101.737920, 2.962426],
                        [101.737515, 2.962383],
                      ],
                    ],
                  },
                },
              ],
            },
          },
          draw: {
            layer: {
              id: 'rfid-1',
              type: 'fill',
              source: 'rfid-1', // reference the data source
              layout: {
                visibility: 'visible',
              },
              paint: {
                'fill-color': '#68CEE5', // blue color fill
                'fill-opacity': 0.2,
              },
            },
            outline: {
              id: 'outline-rfid-1',
              type: 'line',
              source: 'rfid-1',
              layout: {
                visibility: 'visible',
              },
              paint: {
                'line-color': '#43C1DD',
                'line-width': 2,
              },
            },
          },
          hover: {
            coordinate: [101.739332, 2.963484],
          },
        },
      ],
      isSpecific: false,
      currentTitle: '',
      currentData: {
        gantt: [
          {
            id: 1,
            label: "Engineering Task 1",
            site: ['1', '4', '5'],
            wbs: 'ET1',
            start: moment("2021-09-27", 'YYYY-MM-DD').toDate().valueOf(),
            // duration: 15 * 24 * 60 * 60 * 1000,
            end: moment("2021-10-19", 'YYYY-MM-DD').toDate().valueOf(),
            percent: 85,
            type: "project",
            style: {
              base: {
                fill: '#00AE9D',
                stroke: '##C6C6C6',
              },
              'chart-row-progress-bar-pattern': {
                // fill: 'url(#diagonalHatch)',
                fill: 'red',
                transform: 'translateY(0.1) scaleY(0.8)',
              },
            },
            'tree-row-bar': {
              fill: '#1EBC61',
              stroke: '#0EAC51',
            },
            'tree-row-bar-polygon': {
              stroke: '#0EAC51',
            },
            // collapsed: true,
          },
        ],
        table: [],
      },
      delay: { show: 1000, hide: 100 },
      perPage: 5,
      pageOptions: [1, 2, 3, 4, 5, 6, 7],
      totalRows: 35,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      itemTable: [
        {
          taskName: "Engineering Task 1",
          wbs: "ET1",
          plannedStart: "01.01.2020 00:00:00",
          plannedEnd: "01.16.2020 00:00:00",
          actualStart: "01.17.2020 00:00:00",
          actualEnd: "01.17.2021 00:00:00",
          budget: "7614",
          actualSpend: "1157",
        }],
      fields: [
        { key: 'taskName', label: 'Task Name', sortable: true },
        { key: 'wbs', label: 'WBS', sortable: true },
        { key: 'plannedStart', label: 'Planned Start', sortable: true },
        { key: 'plannedEnd', label: 'Planned End', sortable: true },
        { key: 'actualStart', label: 'Actual Start', sortable: true },
        { key: 'actualEnd', label: 'Actual End', sortable: true },
        { key: 'budget', label: 'Budget', sortable: true },
        { key: 'actualSpend', label: 'Actual Spent', sortable: true },
      ],
      myChartStart: "2020-10-01 00:00",
      myChartEnd: "2020-12-01 00:00",
      myBars: [
        {
          myBarStart: "2020-10-13 01:30",
          myBarEnd: "2020-10-27 06:00",
        },
      ],
      myBars2: [
        {
          myBarStart: "2020-10-25 01:30",
          myBarEnd: "2020-11-11 06:00",
        },
      ],
      myBars3: [
        {
          myBarStart: "2020-11-10 01:30",
          myBarEnd: "2020-11-18 06:00",
        },
      ],
      myBars4: [
        {
          myBarStart: "2020-10-21 01:30",
          myBarEnd: "2020-11-26 06:00",
        },
      ],
      myBars5: [
        {
          myBarStart: "2020-11-24 01:30",
          myBarEnd: "2020-12-01 06:00",
        },
      ],
      showMoreFiles: false,
      playMedia: 'video',
      currentMedia: '(CCTV Testing 1)',
      listImg: [
        {
          title: 'Image Title91',
          path: 'files/img/img9.png',
        },
        {
          title: 'Image Title 8',
          path: 'files/img/img8.png',
        },
        {
          title: 'Image Title 7',
          path: 'files/img/img7.png',
        },
        {
          title: 'Image Title 6',
          path: 'files/img/img6.png',
        },
        {
          title: 'Image Title 5',
          path: 'files/img/img5.png',
        },
        {
          title: 'Image Title 4',
          path: 'files/img/img4.png',
        },
        {
          title: 'Image Title 3',
          path: 'files/img/img3.png',
        },
        {
          title: 'Image Title 2',
          path: 'files/img/img2.png',
        },
        {
          title: 'Image Title 1',
          path: 'files/img/img1.png',
        },
        {
          title: 'Image Title 10',
          path: 'files/img/img10.png',
        },
        {
          title: 'Image Title 11',
          path: 'files/img/img11.png',
        },
        {
          title: 'Image Title 12',
          path: 'files/img/img12.png',
        },
      ],
      publicPath: process.env.BASE_URL,
      srcImg: "",
      options: { quality: { default: '1080p' } },
      isInfo: true,
      isLog: false,
      isJson: false,
      sub: {
        info: {
          information: [
            {
              name: 'Image Sensor',
              data: '1/2.8” 3M PS CMOS',
            },
            {
              name: 'Streaming',
              data: 'Multiple Streaming',
            },
            {
              name: 'Zoom Ratio',
              data: 'N/A',
            },
            {
              name: 'Media Type',
              data: 'Photo',
            },
            {
              name: 'Video Compression',
              data: 'H.264, MJPEG',
            },
            {
              name: 'Analogue Out',
              data: 'CVBS 1.0Vp-p',
            },
            {
              name: 'Max Resolution',
              data: '2048 x 1536',
            },
            {
              name: 'Max Frame Rate',
              data: '20fps at 3M',
            },
            {
              name: 'Audio Compression',
              data: 'G.711 u-law',
            },
            {
              name: 'Memory Slot',
              data: 'Yes (SD/SDHC)',
            },
            {
              name: 'Alarm I/O',
              data: '1/1',
            },
            {
              name: 'Day & Night',
              data: 'Yes',
            },

          ],
        },
      },
      items: [
        {
          temporary_building: 'Genset', priority_type: 'String',
        },
        {
          temporary_building: 'Schedule Waste', priority_type: 'String',
        },
        {
          temporary_building: 'Structure Steel Yard', priority_type: 'String',
        },
        {
          temporary_building: 'Fabrication Yard', priority_type: 'String',
        },
        {
          temporary_building: 'Foldable Gate', priority_type: 'String',
        },
        {
          temporary_building: 'TNB Sub Station', priority_type: 'String',
        },
        {
          temporary_building: 'Septic Tank', priority_type: 'String',
        },
      ],
      src: [],
      sources: ["http://vjs.zencdn.net/v/oceans.mp4"],
      accessToken: 'pk.eyJ1IjoicmV4emFjazkiLCJhIjoiY2sxYTF6d2QyMDRlZjNkdDlyM3Z5N24zMSJ9.U5TuDBZwdUsnwntE1h2w6w',
      value: '15.6.2020',
      // data: ['1.6.2020', '2.6.2020', '3.6.2020', '4.6.2020', '5.6.2020', '6.6.2020', '7.6.2020', '8.6.2020', '9.6.2020', '10.6.2020', '11.6.2020', '12.6.2020', '13.6.2020', '14.6.2020', '15.6.2020', '16.6.2020', '17.6.2020', '18.6.2020', '19.6.2020', '20.6.2020', '21.6.2020', '22.6.2020', '23.6.2020', '24.6.2020', '25.6.2020', '26.6.2020', '27.6.2020', '28.6.2020', '29.6.2020', '30.6.2020'],
      data: ['1.6.2020', '3.6.2020', '15.6.2020'],
      // marks: {
      //   '1.6.2020': '1.6.2020',
      //   '5.6.2020': '5.6.2020',
      //   '10.6.2020': '10.6.2020',
      //   '15.6.2020': '15.6.2020',
      //   '20.6.2020': '20.6.2020',
      //   '25.6.2020': '25.6.2020',
      //   '30.6.2020': '30.6.2020',
      // },
      marks: {
        '1.6.2020': '1.6.2020',
        '3.6.2020': '3.6.2020',
        '15.6.2020': '15.6.2020',
      },
      dir: 'ltr',
      map: '',
      markers: [],
      isSatellite: false,
      isShowPanel: true,
      is3DPanel: false,
      isComparePanel: false,
      isOrtho: false,
      is3DModel: false,
      isSynchro: false,
      isPointCloud: false,
      isGantt: false,
      mapGantt: '',
      mesh: '',
      geojson: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              message: 'Petronas Leadership Centre',
              type: 'photos',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.73745667145212, 2.9626747164285114],
            },
            iconClass: 'reddot',
          },
          {
            type: 'Feature',
            properties: {
              message: 'Petronas Management Training',
              type: 'video',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.745188, 2.961157],
            },
            iconClass: 'reddot',
          },
        ],
      },
      marker01062020: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              message: 'Smart Helmet',
              type: 'pdf',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.737732, 2.962813],
            },
            iconClass: 'greendot',
          },
        ],
      },
      marker03062020: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              message: 'Smart Helmet',
              type: 'pdf',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.737732, 2.962813],
            },
            iconClass: 'greendot',
          },
          {
            type: 'Feature',
            properties: {
              message: 'CCTV 1',
              type: 'cctv',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.738055, 2.962707],
            },
            iconClass: 'purpledot',
          },
          {
            type: 'Feature',
            properties: {
              message: 'Main Hall',
              type: 'photos',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.738339, 2.962656],
            },
            iconClass: 'pinkdot',
          },
          {
            type: 'Feature',
            properties: {
              message: 'Drone Data',
              type: 'video',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.738141, 2.962886],
            },
            iconClass: 'greydot',
          },
        ],
      },
      marker15062020: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              message: 'Smart Helmet',
              type: 'pdf',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.737732, 2.962813],
            },
            iconClass: 'greendot',
          },
          {
            type: 'Feature',
            properties: {
              message: 'CCTV 1',
              type: 'cctv',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.738055, 2.962707],
            },
            iconClass: 'purpledot',
          },
          {
            type: 'Feature',
            properties: {
              message: 'Main Hall',
              type: 'photos',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.738339, 2.962656],
            },
            iconClass: 'pinkdot',
          },
          {
            type: 'Feature',
            properties: {
              message: 'Drone Data',
              type: 'video',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.738141, 2.962886],
            },
            iconClass: 'greydot',
          },
          {
            type: 'Feature',
            properties: {
              message: 'RFID',
              type: 'rfid',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.737766, 2.962337],
            },
            iconClass: 'yellowdot',
          },
          {
            type: 'Feature',
            properties: {
              message: 'RFID',
              type: 'rfid',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.737789, 2.962036],
            },
            iconClass: 'yellowdot',
          },
          {
            type: 'Feature',
            properties: {
              message: 'Orthophoto',
              type: 'orthophoto',
              iconSize: [40, 40],
            },
            geometry: {
              type: 'Point',
              coordinates: [101.740112, 2.962541],
            },
            iconClass: 'reddot',
          },
        ],
      },
    }
  },
}
