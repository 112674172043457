<template>

  <b-card
    no-body
    class="card-payment"
  >
    <div class="mt-2 ml-1">
      <b-card-title>Add New Flight</b-card-title>
    </div>
    <div class="ml-1">
      <b-card-text>
        Add flight data to the selected location
      </b-card-text>
    </div>
    <hr>
    <b-card-body>
      <!-- form -->
      <b-form>
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Flight Title"
              label-for="flight-title"
            >
              <b-form-input
                id="flight-title"
                v-model="flightTitle"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Flight Type"
              label-for="flight-type"
            >
              <b-form-select
                v-model="selected"
                :options="options"
                @change="myFunction(selected, options)"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isTypeA"
            sm="6"
          >
            <b-form-group
              label="Asset URL"
              label-for="asset-url"
            >
              <b-form-input
                id="asset-url"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isTypeA"
            sm="6"
          >
            <b-form-group
              label="Date"
              label-for="date"
            >
              <b-input-group class="mb-1">
                <b-form-input
                  id="date"
                  v-model="value"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="value"
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="en-US"
                    aria-controls="date"
                    @context="onContext"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="isTypeA"
            sm="6"
          >
            <b-form-group
              label="Latitude"
              label-for="lat"
            >
              <b-form-input
                id="lat"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isTypeA"
            sm="6"
          >
            <b-form-group
              label="Longitude"
              label-for="lng"
            >
              <b-form-input
                id="lng"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <form
              enctype="multipart/form-data"
              novalidate
            >
              <div class="dropbox">
                <input
                  type="file"
                  class="input-file"
                  @change="onFileSelect"
                  multiple
                >
                <!-- <p>
                  Drag your file(s) here to begin<br> or click to browse
                </p> -->
              </div>
            </form>
          </b-col>
          <!-- <b-col sm="12">
            <vue-dropzone
              id="dropzone"
              ref="vue2Dropzone"
              class="dropzoneClass"
              :options="dropzoneOptions"
              @vdropzone-file-added="onFileSelect"
            ><div
              class="dz-default dz-message"
              data-dz-message
            /></vue-dropzone>
          </b-col> -->
          <!-- <div
            class="container-fluid d-flex align-items-center mt-1"
            style="border:1px dashed #D8D6DE; height: 200px"
          >
            <div class="d-flex flex-column align-items-center mx-auto my-auto">
              <span
                style="color: #5E5873; font-size: 18px; font-weight: 500"
                class="mb-1"
              >Drag and drop your file(s) here</span>
              <span
                style="color: #5E5873"
                class="mb-1"
              >or</span>
              <div
                class="btn btn-primary"
                style="width: 300px"
              >
                Browse File
              </div>
            </div>
          </div> -->
          <div
            v-if="upload.progress > 0"
            class="mt-2 mb-1 col-12"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center">
                <b-card-text>
                  Sending images to Processing Server...
                </b-card-text>
              </div>
              <div class="d-flex align-items-center">
                {{ upload.progress }}%
              </div>
            </div>
            <b-progress
              :value="upload.progress"
              max="100"
              height="6px"
              variant="info"
            />
          </div>
          <div
            v-if="uploadToDataServer.progress > 0"
            class="mb-1 col-12"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center">
                <b-card-text>
                  Sending images to Data Server...
                </b-card-text>
              </div>
              <div class="d-flex align-items-center">
                {{ uploadToDataServer.progress }}%
              </div>
            </div>
            <b-progress
              :value="uploadToDataServer.progress"
              max="100"
              height="6px"
              variant="info"
            />
          </div>
          <div
            v-if="metaDataProgressPercent > 0"
            class="mb-1 col-12"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center">
                <b-card-text>
                  Extracting meta data...
                </b-card-text>
              </div>
              <div class="d-flex align-items-center">
                {{ metaDataProgress }}
              </div>
            </div>
            <b-progress
              :value="metaDataProgressPercent"
              max="100"
              height="6px"
              variant="info"
            />
          </div>
          <div
            style="width: 170px"
            class="mr-1"
          >
            <b-button
              block
              variant="primary"
              class="mt-1"
              @click="onUpload"
            >
              Add Data
            </b-button>
          </div>
          <div style="width: 130px;">
            <b-button
              block

              class="mt-1"
              @click="closeModal"
            >
              Cancel
            </b-button>
          </div>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BCardBody, BRow, BCol, BFormGroup,
  BFormInput, BButton, BForm, BFormSelect, BFormDatepicker, BInputGroup,
  BInputGroupAppend, BProgress,
} from 'bootstrap-vue'
import axios from 'axios'
// import MapLocation from './component/mapLocation.vue'
// import vSelect from 'vue-select'

import { v4 as uuidv4 } from 'uuid'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'dropzone-vue/dist/dropzone-vue.common.css'

const { io } = window

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    // vSelect,
    BFormSelect,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BProgress,
    // vueDropzone: vue2Dropzone,
    // // MapLocation,
  },
  // props: {
  //   modalCreateFlight: {
  //     type: Boolean,
  //     required: true,
  //   },
  // },
  props: ['projectId'],
  data() {
    return {
      upload: {
        progress: 0,
      },
      uploadToDataServer: {
        progress: 0,
      },
      metaDataProgress: "0/0",
      metaDataProgressPercent: 0,
      // projectId: "", // TODO: Make this dynamic using last active project ID
      sendToWebOdmServerDone: false,
      sendToDataServerDone: false,
      file: null,
      isTypeA: false,
      value: '',
      selected: null,
      selectedFile: [],
      temp: [],
      // options: [
      //   { value: 'a', text: 'Fixed Point Image' },
      //   { value: 'b', text: 'Ortho-Mosaic' },
      //   { value: 'c', text: 'Panorama' },
      //   { value: 'd', text: 'Point Cloud' },
      // ],
      options: [],
      flightTypeList: [],
      flightTitle: '',
      isUploading: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        acceptedFiles: ".jpg, .png, .jpeg",
        // maxFiles: 1,
        // clickable: false,
        dictDefaultMessage: 'Drop your files here<p>or</p><div class="btn btn-primary" style="width: 150px" @click="uploadFile">Browse</div>',
        // thumbnailWidth: 150,
        // maxFilesize: 0.5,
        // autoProcessQueue: false,
      },
    }
  },
  mounted(){
    this.getFlightType()
  },
  methods: {
    onContext(ctx) {
      // The following will be an empty string until a valid date is entered
      this.selectedDate = ctx.selectedYMD
    },
    myFunction(data, options){
      options.forEach(option => {
        if (option.value === data) {
          if (option.text === 'Fixed Point Images' || option.text === 'Ortho-Mosaic' || option.text === '3D') {
            this.isTypeA = false
          } else {
            this.isTypeA = true
          }
        }
      })

      // if (data === 'a' || data === 'b') {
      //   this.isTypeA = false
      // } else {
      //   this.isTypeA = true
      // }
    },
    chooseFile() {
      document.getElementById('testInputFile').click()
    },
    onFileSelect(file){
      // eslint-disable-next-line prefer-destructuring
      // const files = file
      // const sliced = Array.prototype.slice.call(files)

      // files.forEach(slice => {
      // this.selectedFile.push(file)
      // })

      console.log(file.target.files)
      this.selectedFile = file.target.files
      console.log(this.selectedFile)
    },
    onUpload(){
      // this.getWebODMToken()
      this.startUploadPhotosToWebODM()
    },
    getFlightType() {
      axios.get(`${this.$baseUrl}/flighttype`).then(response => {
        if (response.status === 200) {
          const { data } = response
          this.flightTypeList = data
          this.flightTypeList.forEach(flightType => {
            this.options.push({
              value: flightType.id,
              text: flightType.label,
            })
          })
        }
      })
    },
    getWebODMToken() {
      const formData = new URLSearchParams()
      formData.append('username', 'aerodyne')
      formData.append('password', '@aerodyne')
      axios.post(`${this.$tokenUrl}`, formData).then(response => {
        if (response.status === 200) {
          const { token } = response.data
          localStorage.setItem('webODMToken', token)
          this.isUploading = false
          this.createWebODMTask()
        }
      })
    },
    createWebODMTask() {
      // console.log(this.selectedFile[0])
      this.isUploading = true
      const token = localStorage.getItem('webODMToken')
      const headers = {
        Authorization: `JWT ${token}`,
      }

      // eslint-disable-next-line no-unused-expressions
      const formData = new FormData()
      this.selectedFile.forEach(file => {
        formData.append('images', file)
      })
      formData.append('auto_processing_node', true)
      formData.append('name', this.flightTitle)
      axios.post('https://webodm.vertikaliti.com/api/projects/24/tasks/', formData, {
        onUploadProgress: uploadEvent => {
          // console.log(`Upload Progress: ${Math.round(uploadEvent.loaded / uploadEvent.total) * 100}%`)
          const percentCompleted = Math.round((uploadEvent.loaded * 100) / uploadEvent.total)
          this.upload.progress = percentCompleted
        },
        headers,
      })
        .then(response => {
          if (response.status === 201) {
            const { data } = response
            localStorage.setItem('webODMId', data.id)
            this.uploadRawImageForNewFlight()
          }
        })
    },
    uploadRawImageForNewFlight() {
      const webOdmId = localStorage.getItem('webODMId')
      const formData = new FormData()
      formData.append('sessionId', webOdmId)
      this.selectedFile.forEach(file => {
        formData.append('file', file)
      })

      axios.post(`${this.$baseUrl}/rawimage/upload`, formData).then(response => {
        if (response.status === 200) {
          this.createNewFlight()
        }
      })
    },
    createNewFlight() {
      const webOdmId = localStorage.getItem('webODMId')

      const formData = new FormData()
      formData.append('title', this.flightTitle)
      formData.append('type', this.selected)
      formData.append('images', '')
      formData.append('project_id', '')
      formData.append('center_lat', 0)
      formData.append('center_lng', 0)
      formData.append('date', 0)
      formData.append('s3_images', '')
      formData.append('webodm_project_id', 24)
      formData.append('webodm_task_id', webOdmId)

      axios.post(`${this.$baseUrl}/flight/create`, formData).then(response => {
        console.log(response)
      })
    },

    /* START AMJAD's SNIPPET */

    async getWebODMTokenAsync(withHeading = true) {
      const path = "https://webodm.vertikaliti.com/api/token-auth/"
      try {
        const jwt = await axios.post(path, {
          username: "aerodyne",
          password: "@aerodyne",
        })

        if (withHeading) {
          return `JWT ${jwt.data.token}`
        }
        return jwt.data.token
      } catch (err) {
        console.error("ERR: [getToken]", err)
        // eslint-disable-next-line no-throw-literal
        throw 'failed'
      }
    },

    async startUploadPhotosToWebODM() {
      // override if type is Fix Point Images
      // eslint-disable-next-line eqeqeq
      if (this.selected == this.getFlightTypeIdByLabel("Fix Point Images")) {
        this.startUploadPhotos(0, "")
        return
      }

      const tokenRes = await this.getWebODMTokenAsync(false)

      // eslint-disable-next-line no-unused-vars
      const uploadEndpoint = `https://webodm.vertikaliti.com/api/projects/24/tasks/?jwt=${tokenRes.token}`

      this.showToast("primary", "Info", "Starting raw image uploads to Processing Server..")

      const sessionId = uuidv4() // uniq sessionId for uploading session

      // eslint-disable-next-line camelcase
      const webOdmOptions_ortho = [
        {
          name: "ignore-gsd",
          value: true,
        },
        {
          name: "dsm",
          value: true,
        },
        {
          name: "pc-quality",
          value: "high",
        },
        {
          name: "dem-resolution",
          value: 2.0,
        },
        {
          name: "orthophoto-resolution",
          value: 2.0,
        },
        {
          name: "skip-3dmodel",
          value: true,
        },
        {
          name: "resize-to",
          value: 2048,
        },
      ]

      // eslint-disable-next-line camelcase
      const webOdmOptions_3dsimple = [
        {
          name: "mesh-octree-depth",
          value: 12,
        },
        {
          name: "mesh-size",
          value: 350000,
        },
        {
          name: "pc-ept",
          value: true,
        },
        {
          name: "pc-geometric",
          value: true,
        },
        {
          name: "pc-las",
          value: true,
        },
        {
          name: "pc-quality",
          value: "high",
        },
        {
          name: "texturing-keep-unseen-faces",
          value: true,
        },
        {
          name: "pc-tile",
          value: true,
        },
      ]

      // eslint-disable-next-line consistent-return
      const getWebOdmOptions = type => {
        switch (type) {
          case "Ortho-Mosaic":
            return JSON.stringify(webOdmOptions_ortho)

          case "3D":
            return JSON.stringify(webOdmOptions_3dsimple)

          default:
            break
        }
      }

      const flightWebOdmOptions = getWebOdmOptions(this.getFlightLabelById(this.selected))

      // return;

      const formData = new FormData()
      formData.append("sessionId", sessionId) // other params need to be appended before files
      formData.append("auto_processing_node", "true") // other params need to be appended before files
      formData.append("name", this.flightTitle) // other params need to be appended before files
      formData.append("options", flightWebOdmOptions) // other params need to be appended before files

      // eslint-disable-next-line no-plusplus
      for (let k = 0; k < this.selectedFile.length; k++) {
        const fileItem = this.selectedFile[k]
        formData.append('images[]', fileItem, fileItem.name)
      }

      const webOdmToken = await this.getWebODMTokenAsync()
      console.log("JWT: ", webOdmToken)

      const headers = {
        Authorization: webOdmToken,
      }

      axios.post('https://webodm.vertikaliti.com/api/projects/24/tasks/', formData, {
        onUploadProgress: uploadEvent => {
          const percentCompleted = Math.round((uploadEvent.loaded * 100) / uploadEvent.total)
          console.log("startUploadPhotosToWebODM > Progress: ", percentCompleted)
          this.upload.progress = percentCompleted
        },
        headers,
      })
        .then(response => {
          if (response.status === 201) {
            const { data } = response
            console.log('File is completely uploaded!')
            console.log('Data: ', data)
            const apiResponse = data
            if (apiResponse) {
              setTimeout(() => {
                this.startUploadPhotos(apiResponse.project, apiResponse.id)
              }, 2000)
            } else {
              console.log("Error Uploading to S3")
              this.showToast("danger", "Error!", "Upload to Processing Server Failed!")
            }
          } else {
            console.log("WebODM Upload => Response !201")
            this.showToast("danger", "Error!", "Upload to Processing Server Failed!")
          }
        })
    },

    startUploadPhotos(webOdmProjectId, webOdmTaskId) {
      const uploadEndpoint = `${this.$baseUrl}/RawImage/upload`

      this.showToast('primary', 'Info', 'Starting raw image uploads to Data Server..')

      const sessionId = uuidv4() // uniq sessionId for uploading session

      const formData = new FormData()
      formData.append("sessionId", sessionId) // other params need to be appended before files
      // eslint-disable-next-line no-plusplus
      for (let k = 0; k < this.selectedFile.length; k++) {
        const fileItem = this.selectedFile[k]
        formData.append('file', fileItem, fileItem.name)
      }

      this.startListenUploadUpdates(sessionId)

      // return;
      axios.post(uploadEndpoint, formData, {
        timeout: 600000,
        onUploadProgress: uploadEvent => {
          const percentCompleted = Math.round((uploadEvent.loaded * 100) / uploadEvent.total)
          this.uploadToDataServer.progress = percentCompleted
        },
      }).then(response => {
        console.log('fasa 3')
        console.log(response)
        if (response.status === 200) {
          const { data } = response
          console.log('File is completely uploaded!')
          console.log('Event: ', data)
          const apiResponse = data
          if (apiResponse) {
            setTimeout(() => {
              this.stopListenUploadUpdates(sessionId)

              const imageIds = apiResponse.map(item => item.image.image.id)

              const newFlight = {
                title: this.flightTitle,
                images: JSON.stringify(imageIds),
                type: this.selected,
                project_id: this.projectId,
                webodm_project_id: webOdmProjectId,
                webodm_task_id: webOdmTaskId,
              }

              console.log("CREATE NEW FLIGHT: ", newFlight)

              axios.post(`${this.$baseUrl}/flight/create`, newFlight).then(response2 => {
                console.log("CREATE NEW FLIGHT: ", response2)
                this.showToast('primary', 'Success!', `Created new Flight titled ${this.flightTitle}!`)
                this.closeModal()
                // TODO: Flight successfully created, do something after creation here.
              })
            }, 2000)
          } else {
            console.log("Error Uploading to S3")
            this.showToast("danger", "Error!", "Upload to Data Server Failed!")
          }
        } else {
          console.log("Data Server Upload => Response !201")
          this.showToast("danger", "Error!", "Upload to Data Server Failed!")
        }
      }).catch(error => {
        console.log("ERR UPL: ", error)
        this.showToast("danger", "Error!", "Upload to Data Server Failed!")
      })
    },

    startListenUploadUpdates(sessionId) {
      io.socket.post("/flight/getUpdatesForImageUpload/", { sessionId }, res => {
        console.log("SOCKET RES: ", res)
      })
      io.socket.on('message', data => {
        console.log("METADATA UPDATES: ", `${data.progress.length} / ${this.selectedFile.length}`)
        this.metaDataProgress = `${data.progress.length} / ${this.selectedFile.length}`
        this.metaDataProgressPercent = Math.round((data.progress.length / this.selectedFile.length) * 100)
      })
    },

    stopListenUploadUpdates(sessionId) {
      io.socket.post("/flight/leaveUpdatesForImageUpload/", { sessionId }, res => {
        console.log("SOCKET RES: ", res)
      })
    },

    getFlightTypeIdByLabel(label) {
      // eslint-disable-next-line eqeqeq
      const index = this.options.findIndex(type => type.text == label)
      if (index >= 0) {
        return this.options[index].value
      }
      return null
    },

    getFlightLabelById(id) {
      // eslint-disable-next-line eqeqeq
      const index = this.options.findIndex(type => type.value == id)
      if (index >= 0) {
        return this.options[index].text
      }
      return null
    },

    closeModal() {
      this.$bvModal.hide("modalCreateFlight")
    },

    showToast(variant, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          text: message,
          variant,
        },
      })
    },

    /* END AMJAD's SNIPPET */
  },

}
</script>

<style>
::-webkit-file-upload-button {
   display: none;
}
::file-selector-button {
  display: none;
}
</style>

<style lang="scss">
input[type='file'] {
    border: 2px dashed #D8D6DE;
    padding: 200px 345px 10px 50px;
    cursor: pointer;
    position:relative;
    // color: rgba(0, 0, 0, 0)
}
input[type='file']:before {
    content: "Click to choose file(s) or drop it here";
    display: block;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 40px;
    margin: -20px 0 0 -200px;
    font-size: 18px;
    font-weight: 500;
    color: #999;
}
// .dropbox {
//     outline: 2px dashed grey; /* the dash box */
//     outline-offset: -10px;
//     background: white;
//     color: dimgray;
//     padding: 10px 10px;
//     min-height: 200px; /* minimum height */
//     position: relative;
//     cursor: pointer;
//   }

//   .input-file {
//     opacity: 0; /* invisible but it's there! */
//     width: 100%;
//     height: 200px;
//     position: absolute;
//     cursor: pointer;
//   }

//   .dropbox:hover {
//     background: lightgrey; /* when mouse over to the drop zone, change color */
//   }

//   .dropbox p {
//     font-size: 1.2em;
//     text-align: center;
//     padding: 50px 0;
//   }
</style>
