<template>
  <div>
    <b-form @submit.prevent>
      <b-row>

        <!-- form -->
        <b-col cols="12">
          <!-- <b-form-group
            label="Zone Name"
            label-for="v-name"
          >
            <v-text-field
              v-model="zoneName"
              label="Zone Name"
            />
          </b-form-group> -->
          <b-form-group
            label="Task Name"
            label-for="v-name"
          >
            <b-form-input
              id="v-name"
              v-model="taskName"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-form-group
            label="Task Name"
            label-for="v-task"
          >
            <v-select
              v-model="task.selected"
              multiple
              label="task"
              :options="taskOptions"
            />
          </b-form-group>
        </b-col> -->
        <b-col cols="12">
          <b-form-group
            label="Zones"
            label-for="v-zone"
          >
            <v-select
              v-model="zone.selected"
              label="zone"
              multiple
              :options="zone.options"
            />
          </b-form-group>
        </b-col>

        <!-- coordinates -->
        <!-- <b-col cols="12">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Latitude</th>
                <th>Longitude</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(coordinate, index) in coordinates"
                :key="index"
              >
                <td>{{ coordinate[0] }}</td>
                <td>{{ coordinate[1] }}</td>
              </tr>
            </tbody>
          </table>
        </b-col> -->

        <!-- submit and reset -->
        <b-col cols="12 text-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="danger"
            class="mr-1"
            @click="deleteTask"
          >
            Delete
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="updateTask"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BForm, BButton, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    editTaskId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      zone: {
        selected: [],
        options: [],
      },
      taskName: '',
      task: null,
    }
  },
  computed: {
  },
  created() {
  },
  beforeMount() {
  },
  mounted(){
    console.log(this.editTaskId)
    this.apiGetTaskById()
  },

  methods: {
    apiGetTaskById() {
      this.$axios.get(`${this.$baseUrl}/tasks/${this.editTaskId}`).then(response => {
        if (response.status === 200 || response.status === 201) {
          const { data } = response
          this.task = data
          this.taskName = data.name
          this.apiGetAllZones()
        }
      })
    },
    apiGetAllZones() {
      this.$axios.get(`${this.$baseUrl}/zones`).then(response => {
        if (response.status === 200 || response.status === 201) {
          const { data } = response

          data.forEach(zone => {
            console.log(this.task)
            this.zone.options.push({
              zone: zone.name,
              value: zone.id,
            })

            this.task.zones.forEach(taskZone => {
              if (taskZone.id === zone.id) {
                this.zone.selected.push({
                  zone: taskZone.name,
                  value: taskZone.id,
                })
              }
            })
          })
        }
      })
    },
    updateTask() {
      console.log('updateTask')
      const selectedZone = []
      this.zone.selected.forEach(zone => {
        selectedZone.push(zone.value)
      })
      const formData = {
        name: this.taskName,
        zones: selectedZone,
      }

      this.$axios.patch(`${this.$baseUrl}/tasks/${this.editTaskId}`, formData).then(response => {
        if (response.status === 200 || response.status === 201) {
          const { data } = response
          console.log(data)
          const timeout = 4000
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Task Succesfully Updated!`,
            },
          }, { timeout })
          this.$emit('successAddedZone')
        }
      })
    },
    deleteTask() {
      console.log('delete task')

      this.$axios.delete(`${this.$baseUrl}/tasks/${this.editTaskId}`).then(response => {
        if (response.status === 200 || response.status === 201) {
          const { data } = response
          console.log(data)
          const timeout = 4000
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Task Deleted!`,
            },
          }, { timeout })
          this.$emit('successAddedZone')
        }
      })
    },
  },
}
</script>
