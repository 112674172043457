<template>
  <div>
    <div id="comparison-container">
      <div
        id="before"
        class="map"
      />
      <div
        id="after"
        class="map"
      />
    </div>
  </div>
</template>
<style>
.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 90vh;
}
.mapboxgl-canvas{
  width: 100%!important;
}

.compare-swiper-vertical {
  background-color: white;
  border: 2px solid #00AE9D;
  width: 20px !important;
  height: 20px !important;
  margin-left: 20px;
  border: 3px solid #00AE9D;
}

.mapboxgl-compare {
  background-color: white;
  border: 2px solid #00AE9D;
  outline: none;
}
</style>
<script>
import {
} from 'bootstrap-vue'

import mapboxgl from 'mapbox-gl'
import MapboxCompare from 'mapbox-gl-compare'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
    mapboxgl.accessToken = 'pk.eyJ1IjoicmV4emFjazkiLCJhIjoiY2sxYTF6d2QyMDRlZjNkdDlyM3Z5N24zMSJ9.U5TuDBZwdUsnwntE1h2w6w'

    const beforeMap = new mapboxgl.Map({
      container: 'before', // container ID
      style: 'mapbox://styles/mapbox/light-v10', // style URL
      center: [101.740112, 2.962541],
      zoom: 17,
      antialias: true,
    })

    const afterMap = new mapboxgl.Map({
      container: 'after', // container ID
      style: 'mapbox://styles/mapbox/light-v10', // style URL
      center: [101.740112, 2.962541],
      // center: [-74.5, 40],
      zoom: 17,
      // zoom: 2,
      antialias: true,
    })

    const comparisonContainer = '#comparison-container'
    const map = new MapboxCompare(beforeMap, afterMap, comparisonContainer, {
      // mousemove: true,
      // orientation: 'vertical',
    })

    beforeMap.on('load', () => {
      beforeMap.addSource('sample-ortho', {
        type: 'raster',
        tiles: [
        // 'https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg',
          ('https://aerodyne-mco-data.s3.ap-southeast-1.amazonaws.com/PETRONAS_LEADERSHIP_CENTER_%28PLC%29/04_DELIVERY/04_HOSTING/01_MAPTILER/%2001_ORTHO_MAPTILER/PETRONAS%20Leadership%20Center%20%28PLC%29/openlayers.html').replace(/(?=\w+\.\w{3,4}$).+/, '{z}/{x}/{y}.png'),
        ],
        tileSize: 256,
      })
      beforeMap.addLayer({
        id: 'sample-ortho',
        source: 'sample-ortho',
        type: 'raster',
        minzoom: 0,
        maxzoom: 22,
      })
      beforeMap.resize()
    })

    afterMap.on('load', () => {
      afterMap.addSource('sample-watercolor', {
        type: 'raster',
        tiles: [
          // 'https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg',
          ('https://aerodyne-gis.s3.ap-southeast-1.amazonaws.com/02_POC/16_PETRONAS_NEW_LEADERSHIP_CENTRE/20220113_PETRONAS_LEADERSHIP_CENTRE/04_DELIVERY/01_PHOTOGRAMMETRY/04_HOSTING/01_MAPTILER/01_ORTHO_MAPTILER/20220113_PETRONAS_LEADERSHIP_CENTRE/openlayers.html').replace(/(?=\w+\.\w{3,4}$).+/, '{z}/{x}/{y}.png'),
        ],
        tileSize: 256,
      })
      afterMap.addLayer({
        id: 'sample-watercolor',
        source: 'sample-watercolor',
        type: 'raster',
        minzoom: 0,
        maxzoom: 22,
      })
      afterMap.resize()
    })

    const x = map.currentPosition
    console.log('initial position')
    console.log(x)
    this.$emit('sliderPosition', { isInitial: true, position: x })
    map.setSlider(x)

    console.log(x)
    // const x = map.currentPosition
    // map.setSlider(x)
    map.on('slideend', e => {
      console.log(e.currentPosition)
    })

    // eslint-disable-next-line no-undef
    $('.compare-swiper-vertical, .mapboxgl-compare').on('mousemove', e => {
      if (e.which === 1) {
        // console.log(e.originalEvent.layerX)
        const positionX = e.originalEvent.layerX
        this.$emit('sliderPosition', { isInitial: true, position: positionX })
      }
    })
  },
}
</script>
