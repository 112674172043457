<template>
  <div>
    <b-form @submit.prevent>
      <b-row>

        <!-- form -->
        <b-col cols="12">
          <!-- <b-form-group
            label="Zone Name"
            label-for="v-name"
          >
            <v-text-field
              v-model="zoneName"
              label="Zone Name"
            />
          </b-form-group> -->
          <b-form-group
            label="Zone Name"
            label-for="v-name"
          >
            <b-form-input
              id="v-name"
              v-model="zoneName"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-form-group
            label="Task Name"
            label-for="v-task"
          >
            <v-select
              v-model="task.selected"
              multiple
              label="task"
              :options="taskOptions"
            />
          </b-form-group>
        </b-col> -->
        <b-col cols="12">
          <b-form-group
            label="Zone Type"
            label-for="v-zone"
          >
            <v-select
              v-model="zone.selected"
              label="zone"
              :options="zone.options"
            />
          </b-form-group>
        </b-col>

        <!-- coordinates -->
        <b-col cols="12">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Latitude</th>
                <th>Longitude</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(coordinate, index) in coordinates"
                :key="index"
              >
                <td>{{ coordinate[0] }}</td>
                <td>{{ coordinate[1] }}</td>
              </tr>
            </tbody>
          </table>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12 text-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="saveNewZone"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BForm, BButton, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    coordinates: {
      type: Array,
      default: () => [],
    },
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      task: {
        selected: [],
      },
      zone: {
        selected: [],
        options: [],
      },
      zoneName: '',
      taskOptions: [],
    }
  },
  computed: {
  },
  created() {
  },
  beforeMount() {
    this.tasks.forEach(task => {
      this.taskOptions.push({
        value: task.id,
        task: task.name,
      })
    })
  },
  mounted(){
    this.apiGetAllZoneType()
  },

  methods: {
    apiGetAllZoneType(){
      this.$axios.get(`${this.$baseUrl}/zoneTypes`).then(response => {
        if (response.status === 200) {
          this.zone.options = []
          response.data.forEach(zone => {
            this.zone.options.push({
              zone: zone.label,
              value: zone.id,
            })
          })
        }
      })
    },
    saveNewZone(){
      this.apiPostCreateZone()
    },
    apiPostCreateZone(){
      console.log(this.coordinates)
      console.log(this.tasks)
      // const taskValue = []

      const formData = {
        name: this.zoneName,
        points: this.coordinates,
        type: this.zone.selected.value,
      }

      this.$axios.post(`${this.$baseUrl}/zones`, formData).then(response => {
        if (response.status === 200) {
          const timeout = 4000
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Zone Succesfully Added!`,
            },
          }, { timeout })

          // const { data } = response
          // const createdZoneId = data.id

          this.$emit('successAddedZone')

          // this.task.selected.forEach(item => {
          //   taskValue.push(item.value)

          //   this.tasks.forEach(task => {
          //     if (task.id === item.value) {
          //       console.log(task)
          //       const currentZoneId = []

          //       this.$axios.get(`${this.$baseUrl}/tasks/${task.id}`).then(response2 => {
          //         if (response2.status === 200) {
          //           const data2 = response2.data
          //           console.log(response2.data)
          //           currentZoneId.push(createdZoneId)
          //           data2.zones.forEach(currentZone => {
          //             currentZoneId.push(currentZone.id)
          //           })

          //           setTimeout(() => {
          //             const formDataUpdate = {
          //               zones: currentZoneId,
          //             }

          //             console.log(formDataUpdate)

          //             this.$axios.patch(`${this.$baseUrl}/tasks/${task.id}`, formDataUpdate).then(response3 => {
          //               console.log(response3)
          //               if (response3.status === 200) {
          //                 this.$emit('successAddedZone')
          //               }
          //             })
          //           }, 1000)
          //         }
          //       })
          //     }
          //   })
          // })
          // console.log(data)
        }
      })
      // this.$axios.post(`${this.$baseUrl}/zones`, data).then(response => {
      //   console.log(response)
      //   if (response.status === 200) {
      //     const timeout = 4000
      //     this.$toast({
      //       component: ToastificationContent,
      //       position: "top-right",
      //       props: {
      //         title: `Info`,
      //         icon: "CoffeeIcon",
      //         variant: "success",
      //         text: `Zone Succesffully Added!`,
      //       },
      //     }, { timeout })

      //     this.$emit('successAddedZone')
      //   } else {
      //     const timeout = 4000
      //     this.$toast({
      //       component: ToastificationContent,
      //       position: "top-right",
      //       props: {
      //         title: `Info`,
      //         icon: "CoffeeIcon",
      //         variant: "warning",
      //         text: `Error!`,
      //       },
      //     }, { timeout })
      //   }
      // })
    },
  },
}
</script>
