<template>
  <div>
    <b-form-group
      label="Task"
      label-for="Task"
    >
      <select
        v-model="selectedTask"
        class="form-control"
        style="border: 1px solid #d8d6de;"
      >
        <option
          v-for="(task, index) in tasks"
          :key="index"
          :value="task.id"
        >
          {{ task.name }}
        </option>
      </select>
    </b-form-group>
    <b-form-group
      label="Type"
      label-for="Type"
    >
      <b-form-select
        v-model="selectedType"
        class="form-control"
        style="border: 1px solid #d8d6de;"
      >
        <option
          v-for="(type, index) in listType"
          :key="index"
          :value="type.id"
        >
          {{ type.label }}
        </option>
      </b-form-select>
    </b-form-group>
    <b-form-group
      label="Name"
      label-for="Name"
    >
      <b-form-input
        id="Name"
        v-model="name"
        trim
        placeholder="Name"
      />
    </b-form-group>
    <b-form-group
      label="Time"
      label-for="Time"
    >
      <flat-pickr
        v-model="dateNtim"
        placeholder="Date & Time"
        class="form-control"
        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
      />
    </b-form-group>
    <div
      class="btn btn-primary float-right ml-1"
      @click="createNewDefectIncident()"
    >
      Add
    </div>
    <div
      class="btn btn-light float-right"
      @click="closeModal()"
    >
      Cancel
    </div>
  </div>
</template>
<script>
import {
  BFormInput, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
// import DropZone from 'dropzone-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {

  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    flatPickr,
  },
  directives: {
  },
  props: {
    type: {
      type: String,
      default: () => '',
    },
    listType: {
      type: Array,
      default: () => [],
    },
    selectedProject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dateNtim: null,
      project: null,
      showModal: null,
      cover: null,
      userData: JSON.parse(localStorage.getItem('userData-Core')),
      tasks: [],
      selectedTask: '',
      selectedType: '',
      name: '',
      obj: {},
      dateTime: '',
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
    this.tasks = this.selectedProject.tasks
  },
  methods: {
    test(){
      console.log(this.name)
      console.log(this.selectedTask)
      console.log(this.selectedType)
      this.dateTime = moment(this.dateNtim, "YYYY/M/D H:mm").valueOf()
      console.log(this.dateTime)
    },
    createNewDefectIncident() {
      const formData = new FormData()
      this.dateTime = moment(this.dateNtim, "YYYY/M/D H:mm").valueOf()
      formData.append('name', this.name)
      formData.append('task', this.selectedTask)
      formData.append('type', this.selectedType)
      formData.append('timestamp', this.dateTime)

      // this.obj.name = this.name
      // this.obj.selectedTask = this.selectedTask
      // this.obj.selectedType = this.selectedType
      // this.obj.dateTime = this.dateTime
      console.log(this.name)
      console.log(this.selectedTask)
      console.log(this.selectedType)
      this.dateTime = moment(this.dateNtim, "YYYY/M/D H:mm").valueOf()
      console.log(this.dateTime)
      this.$axios.post(`${this.$baseUrl}/${this.type}`, formData).then(response => {
        if (response.status === 200) {
          this.$emit('refreshDefectIncident')
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Successully Added!`,
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: `Failed to add!`,
            },
          })
        }
        this.closeModal()
      })
    },
    closeModal() {
      this.$bvModal.hide('modal-add-new-record')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
