<template>
  <div>
    <b-modal
      v-model="showModal"
      centered
      title="Add New Project"
      :hide-footer="true"
      @show="project = null"
      @hide="removeMarkerNewMap"
    >
      <div
        class="mb-1"
      >
        <label><span style="color: red">*</span>Project</label>
        <div v-if="error.projectName">
          <span style="color: red; font-size: 10px">Project name is required!</span>
        </div>
        <b-form-input
          id="Project"
          v-model="project"
          trim
          placeholder="Project Name"
        />
      </div>
      <div class="row">
        <b-form-group
          class="col-6"
          label="Latitude"
          label-for="Latitude"
        >
          <b-form-input
            id="Latitude"
            v-model="coordinate.lng"
            trim
            placeholder="Latitude"
          />
        </b-form-group>
        <b-form-group
          class="col-6"
          label="Longitude"
          label-for="Longitude"
        >
          <b-form-input
            id="Longitude"
            v-model="coordinate.lat"
            trim
            placeholder="Longitude"
          />
        </b-form-group>
      </div>
      <div
        style="height: 70px"
      >
        <label><span style="color: red">*</span>Cover Image</label>
        <div v-if="error.coverImage">
          <span style="color: red; font-size: 10px">Cover image is required!</span>
        </div>
        <b-form-file
          id="cover"
          v-model="cover"
          accept="image/jpeg, image/png, image/gif"
        />
      </div>

      <div class="d-flex justify-content-end mt-2">
        <button
          type="button"
          class="btn btn-secondary mr-1"
          @click="showModal = false"
        >
          Cancel
        </button><button
          type="button"
          class="btn btn-primary"
          @click="apiPostNewProject"
        >
          OK
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BFormInput, BFormGroup, BFormFile,
} from 'bootstrap-vue'
// import DropZone from 'dropzone-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    BFormGroup,
    BModal,
    BFormInput,
    BFormFile,
  },
  directives: {
  },
  props: {
    coordinate: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      project: null,
      showModal: null,
      cover: null,
      userData: JSON.parse(localStorage.getItem('userData-Core')),
      error: {
        coverImage: false,
        projectName: false,
      },
    }
  },
  computed: {
  },
  created() {
  },

  methods: {
    show(){
      this.showModal = true
    },
    removeMarkerNewMap(){
      this.$emit('removeMarkerNewMap')
    },
    apiPostNewProject(modal){
      modal.preventDefault()
      console.log(this.cover)
      const formData = new FormData()
      formData.append('name', this.project)
      formData.append('latitude', this.coordinate.lng)
      formData.append('longitude', this.coordinate.lat)
      formData.append('organization_id', this.userData.organization)
      formData.append('user_id', this.userData.id)
      formData.append('file', this.cover)

      console.log(this.userData.organization)
      console.log(this.userData.id)

      let isOkayName = false
      let isOkayFile = false

      if (this.cover !== null){
        isOkayName = true
        this.error.coverImage = false
      } else {
        isOkayName = false
        this.error.coverImage = true
      }

      if (this.project !== null && this.project !== ""){
        isOkayFile = true
        this.error.projectName = false
      } else {
        isOkayFile = false
        this.error.projectName = true
      }

      if (isOkayFile && isOkayName){
        this.showModal = false

        // eslint-disable-next-line no-useless-concat
        this.$axios.post(`${this.$baseUrl}/project/create`, formData).then(response => {
          console.log(response)
          if (response.status === 200) {
            this.$emit('refreshAllProject')
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Info`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Project Successully Added!`,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Info`,
                icon: "CoffeeIcon",
                variant: "danger",
                text: `Project Failed to add!`,
              },
            })
          }
        })
      }
    },
  },
}
</script>
