<template>
  <div class="card p-1">
    <div class="pt-2 pl-2">
      <p
        class="font-weight-bold"
        style="font-size:130%"
      >
        Tower #123
      </p>
    </div>
    <hr>
    <div class="pl-2">
      <div class="pb-1 d-flex flex-row">
        <div
          class="mr-1"

          style="border-radius: 5px; width:25px; height:25px; padding-left:5px; background-color: rgb(234, 84, 85, 0.12)"
        >
          <feather-icon
            icon="MapIcon"
            size="17"
            class="text-warning"
          />
        </div>
        <p class="font-weight-bold">
          Main Tower
        </p>
      </div>
      <div class="pb-1 pr-1 d-flex flex-row">
        <p class="font-weight-normal">
          A data driven smart industrial or safety helmet. An IOT solution that helps worksites become safer by providing live, wireless monitoring, and array of sensors such as accident impact and geolocation to perform various analysis of data
        </p>
      </div>
      <div class="pb-1 d-flex flex-row">
        <div
          class="mr-1"

          style="border-radius: 5px; width:25px; height:25px; padding-left:5px; background-color: rgba(86, 121, 209, 0.12)"
        >
          <feather-icon
            icon="CircleIcon"
            size="17"
          />
        </div>
        <div class="d-flex flex-column pl-2">
          <span
            class="font-weight-bold"
          >
            69.9°
          </span>
          <span class="font-weight-normal">
            Facing Angle
          </span>
        </div>
      </div>
      <div class="pb-1 d-flex flex-row">
        <div
          class="mr-1"

          style="border-radius: 5px; width:25px; height:25px; padding-left:5px; background-color:  rgba(86, 121, 209, 0.12)"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="17"
          />
        </div>
        <div class="d-flex flex-column pl-2">
          <span
            class="font-weight-bold"
          >
            QQEB - K7FH - NK43
          </span>
          <span class="font-weight-normal">
            Serial Number
          </span>
        </div>
      </div>
      <div class="pb-1 d-flex flex-row">
        <div
          class="mr-1"

          style="border-radius: 5px; width:25px; height:25px; padding-left:5px; background-color: rgba(86, 121, 209, 0.12)"
        >
          <feather-icon
            icon="MapPinIcon"
            size="17"
          />
        </div>
        <div class="d-flex flex-column pl-2">
          <span
            class="font-weight-bold"
          >
            Zone A - South
          </span>
          <span class="font-weight-normal">
            Location
          </span>
        </div>
      </div>
      <hr>
      <div class="row justify-align-center">
        <div class="col-12">
          <img
            rounded
            src="@/views/pages/dashboard/map/asset/other/pic.png"
            fluid
            width="100%"
          >
        </div>
      </div>
    </div>
    <!-- <b-button
      class="mt-1"
      style="border-radius: 6px"
      variant="outline-dark"
    >
      Back
    </b-button> -->
  </div>
</template>

<script>
import {
// BButton,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { required, email, url } from "@validations"

export default {
  components: {
    // BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
