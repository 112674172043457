<template>
  <div>
    <div class="card p-1">
      <div>
        <div class="d-flex">
          <div class="d-flex px-1 py-1 align-items-center">
            <feather-icon
              icon="ArrowLeftIcon"
              size="2x"
              class="mr-2 pointer"
              @click="onClickBack"
            />
            <span style="font-weight: 500; color: #5E5873; font-size:18px;">Overall Time Hours
            </span>
          </div>
        </div>

        <vue-apex-charts
          type="radialBar"
          height="200"
          class="my-2 mx-auto"
          :options="manhourRadialBar"
          :series="data.series"
        />

      </div>
      <div>
        <div class="">
          <b-container>
            <b-row align-h="center">
              <div class="pb-1 col-6 d-flex flex-row">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="border-radius: 5px; width:45px; height:45px; text-align: center; background-color: rgba(86, 121, 209, 0.12)"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="1.5x"
                    class="text-primary"
                  />
                  <!-- </a> -->
                </div>
                <div class="d-flex flex-column pl-2">
                  <span
                    class=""
                    style="font-weight: 500; color: #5E5873; font-size: 17px;"
                  >
                    90
                  </span>
                  <span class="font-weight-normal">
                    Hours In
                  </span>
                </div>
              </div>
              <div class="pb-1 col-6 d-flex flex-row">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="border-radius: 5px; width:45px; height:45px; text-align: center; background-color: rgba(86, 121, 209, 0.12)"
                >
                  <feather-icon
                    icon="BriefcaseIcon"
                    size="1.5x"
                    class="text-primary"
                  />
                  <!-- </a> -->
                </div>
                <div class="d-flex flex-column pl-2">
                  <span
                    class=""
                    style="font-weight: 500; color: #5E5873; font-size: 17px;"
                  >
                    55
                  </span>
                  <span class="font-weight-normal">
                    Hours Out
                  </span>
                </div>
              </div>
            </b-row>
            <b-row align-h="center">
              <div class="pb-1 col-6 d-flex flex-row">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="border-radius: 5px; width:45px; height:45px; text-align: center; background-color: rgba(86, 121, 209, 0.12)"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="1.5x"
                    class="text-primary"
                  />
                  <!-- </a> -->
                </div>
                <div class="d-flex flex-column pl-2">
                  <span
                    class=""
                    style="font-weight: 500; color: #5E5873; font-size: 17px;"
                  >
                    70
                  </span>
                  <span class="font-weight-normal">
                    Current
                  </span>
                </div>
              </div>
              <div class="pb-1 col-6 d-flex flex-row">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="border-radius: 5px; width:45px; height:45px; text-align: center; background-color: rgba(86, 121, 209, 0.12)"
                >
                  <feather-icon
                    icon="BriefcaseIcon"
                    size="1.5x"
                    class="text-primary"
                  />
                  <!-- </a> -->
                </div>
                <div class="d-flex flex-column pl-2">
                  <span
                    class=""
                    style="font-weight: 500; color: #5E5873; font-size: 17px;"
                  >
                    123
                  </span>
                  <span class="font-weight-normal">
                    Total Worker
                  </span>
                </div>
              </div>
            </b-row>
          </b-container>
        </div>
      </div>
      <hr>
      <div class="">
        <div class="row no-gutters justify-content-between align-items-center">
          <span style="font-weight: 500; font-size: 15px; color: #5E5873">Attendance List</span>
          <b-dropdown
            ref="dropdown"
            variant="link"
            toggle-class="text-decoration-none"
            size="md"
            no-caret
          >
            <template v-slot:button-content>
              <div
                class="d-flex justify-content-between align-items-center border"
                style="padding-top: 10px; padding-bottom: 10px; padding-right: 20px; padding-left: 20px; border-radius: 4px;"
              >
                <span style="margin-right: 15px; color: #B9B9C3"> Filter </span> <feather-icon
                  icon="ChevronDownIcon"
                  size="15"
                  class="text-dark"
                />
              </div>
            </template>
            <div class="d-flex flex-column p-1">
              <b-form-checkbox
                v-model="selectedDT"
                class="mb-1"
                value="show"
                hidden
                inline
              >
                Date and Time
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedName"
                class="mb-1"
                value="show"
                hidden
                inline
              >
                Staff Name
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedId"
                class="mb-1"
                value="show"
                hidden
                inline
              >
                Staff ID
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedZone"
                class="mb-1"
                value="show"
                hidden
                inline
              >
                Zone
              </b-form-checkbox>
            </div>

            <div class="d-flex p-1 justify-content-center align-items-center">
              <div
                class="btn btn-primary mr-1"
                @click="myFunction()"
              >
                Save
              </div> <div
                class="btn btn-light"
                @click="clear()"
              >
                Discard
              </div>
            </div>
          </b-dropdown>
        </div>
        <b-col
          v-if="isStaffID"
          sm="12"
        >
          <b-form-group
            label="Staff ID"
            label-for="staffID"
          >
            <b-form-input
              id="staffID"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="isStaffName"
          sm="12"
        >
          <b-form-group
            label="Staff Name"
            label-for="staffName"
          >
            <b-form-input
              id="staffName"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="isZone"
          sm="12"
        >
          <b-form-group
            label="Zone"
            label-for="zone"
          >
            <b-form-select
              v-model="selectedZone"
              :options="options"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="isDate"
          sm="12"
        >
          <b-form-group
            label="Date"
            label-for="date"
          >
            <b-input-group class="mb-1">
              <b-form-input
                id="date"
                v-model="value"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="value"
                  show-decade-nav
                  button-only
                  button-variant="outline-primary"
                  right
                  size="sm"
                  locale="en-US"
                  aria-controls="date"
                  @context="onContext"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <div class="">
          <span style="color: #B9B9C3; font-size: 11px;">OCTOBER 2021</span>
          <div
            class="d-flex justify-content-between bg-white"
            style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
          >
            <div
              class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
              style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
            >
              <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
              <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">22</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-between p-1"
              style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
            >

              <div class="d-flex flex-column justify-content-between">

                <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Myra Foster </span>
                <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
              </div>

              <div class="d-flex flex-column align-items-center justify-content-between">
                <div
                  style="border-radius: 17px; background: rgba(255, 159, 67, 0.12); margin-bottom: 5px;"
                  class="pr-1 pl-1"
                >
                  <span style="color: #FF9F43; font-size: 12px; font-weight: 600; ">Time Out </span>
                </div>
                <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">11:03:25 AM</span></div>
            </div>

          </div>
          <div
            class="d-flex justify-content-between bg-white mt-1"
            style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
          >
            <div
              class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
              style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
            >
              <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
              <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">16</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-between p-1"
              style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
            >

              <div class="d-flex flex-column justify-content-between">

                <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Myra Foster </span>
                <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
              </div>

              <div class="d-flex flex-column align-items-center justify-content-between">
                <div
                  style="border-radius: 17px; background: rgba(40, 199, 111, 0.12); margin-bottom: 5px;"
                  class="pr-1 pl-1"
                >
                  <span style="color: #228B22; font-size: 12px; font-weight: 600; ">Time In </span>
                </div>
                <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">11:20:47 AM</span></div>
            </div>

          </div>
        </div>
        <hr>
        <div
          class=""
        >
          <span style="color: #B9B9C3; font-size: 11px;">SEPTEMBER 2021</span>
          <div
            class="d-flex justify-content-between bg-white"
            style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
          >
            <div
              class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
              style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
            >
              <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
              <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">22</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-between p-1"
              style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
            >

              <div class="d-flex flex-column justify-content-between">

                <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Ian Tan Zhi </span>
                <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
              </div>

              <div class="d-flex flex-column align-items-center justify-content-between">
                <div
                  style="border-radius: 17px; background: rgba(255, 159, 67, 0.12); margin-bottom: 5px;"
                  class="pr-1 pl-1"
                >
                  <span style="color: #FF9F43; font-size: 12px; font-weight: 600; ">Time Out </span>
                </div>
                <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">11:03:25 AM</span></div>
            </div>

          </div>
          <div
            class="d-flex justify-content-between bg-white mt-1"
            style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
          >
            <div
              class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
              style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
            >
              <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
              <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">16</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-between p-1"
              style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
            >

              <div class="d-flex flex-column justify-content-between">

                <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Michael Ish</span>
                <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
              </div>

              <div class="d-flex flex-column align-items-center justify-content-between">
                <div
                  style="border-radius: 17px; background: rgba(40, 199, 111, 0.12); margin-bottom: 5px;"
                  class="pr-1 pl-1"
                >
                  <span style="color: #228B22; font-size: 12px; font-weight: 600; ">Time In </span>
                </div>
                <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">10:35:20 AM</span></div>
            </div>

          </div>
          <div
            class="d-flex justify-content-between bg-white mt-1"
            style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
          >
            <div
              class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
              style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
            >
              <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
              <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">13</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-between p-1"
              style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
            >

              <div class="d-flex flex-column justify-content-between">

                <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">John Doe</span>
                <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
              </div>

              <div class="d-flex flex-column align-items-center justify-content-between">
                <div
                  style="border-radius: 17px; background: rgba(40, 199, 111, 0.12); margin-bottom: 5px;"
                  class="pr-1 pl-1"
                >
                  <span style="color: #228B22; font-size: 12px; font-weight: 600; ">Time In </span>
                </div>
                <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">10:35:20 AM</span></div>
            </div>

          </div>
          <div
            class="d-flex justify-content-between bg-white mt-1"
            style="border: 1px solid rgba(94, 88, 115, 0.1);
                  border-radius:6px; margin-top: 5px;"
          >
            <div
              class="col-auto d-flex flex-column align-items-center justify-content-center p-1"
              style="margin-right: 2px; background-color: rgba(94, 88, 115, 0.1); border-radius:6px;"
            >
              <span style="color: #6E6B7B; font-size: 11px; font-weight: 500">OCT </span>
              <span style="color: #6E6B7B; font-size: 21px; font-weight: 500">10</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-between p-1"
              style="background-color: rgba(94, 88, 115, 0.1); width: 100%;"
            >

              <div class="d-flex flex-column justify-content-between">

                <span style="color: #6E6B7B; font-size: 14px; font-weight: 600; margin-bottom: 5px;">Mary Robert</span>
                <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">PETRO-12ADG53</span>
              </div>

              <div class="d-flex flex-column align-items-center justify-content-between">
                <div
                  style="border-radius: 17px; background: rgba(40, 199, 111, 0.12); margin-bottom: 5px;"
                  class="pr-1 pl-1"
                >
                  <span style="color: #228B22; font-size: 12px; font-weight: 600; ">Time In </span>
                </div>
                <span style="color: #B9B9C3; font-size: 12px; font-weight: 400">10:15:11 AM</span></div>
            </div>

          </div>
        </div>
      </div>
      <b-button
        class="mt-1"
        style="border-radius: 6px"
        variant="outline-dark"
        @click="onClickBack"
      >
        Back
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BDropdown, BButton, BFormCheckbox, BCol, BRow, BFormGroup, BFormInput, BFormSelect, BFormDatepicker, BInputGroup, BInputGroupAppend, BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'

const $strokeColor = '#BABFC7'
const $textHeadingColor = '#5e5873'
export default {
  directives: {
    Ripple,
  },
  components: {
    BDropdown,
    BButton,
    BFormCheckbox,
    VueApexCharts,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BContainer,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: '',
      selectedDT: 'hide',
      selectedName: 'hide',
      selectedId: 'hide',
      selectedZone: 'hide',
      isDate: false,
      isStaffID: false,
      isStaffName: false,
      isZone: false,
      options: [
        { value: 'a', text: 'A' },
        { value: 'b', text: 'B' },
        { value: 'c', text: 'C' },
        { value: 'd', text: 'D' },
      ],
      manhourRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [this.data.color],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [this.data.color],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
  methods: {
    onClick() {
      this.$refs.dropdown.hide(true)
    },
    onClickBack() {
      this.$emit('onClickBack', true)
    },
    onContext(ctx) {
      // The following will be an empty string until a valid date is entered
      this.selectedDate = ctx.selectedYMD
    },
    myFunction(){
      this.isStaffID = this.selectedId === 'show' // simple comparison here. if selectedId is equal to === 'show', it will return true, else it return false
      this.isStaffName = this.selectedName === 'show'
      this.isZone = this.selectedZone === 'show'
      this.isDate = this.selectedDT === 'show'
      this.$refs.dropdown.hide(true)
    },
    clear(){
      this.selectedDT = 'hide'
      this.selectedId = 'hide'
      this.selectedName = 'hide'
      this.selectedZone = 'hide'
      this.isStaffID = false
      this.isStaffName = false
      this.isZone = false
      this.isDate = false
      this.$refs.dropdown.hide(true)
    },
  },
}
</script>
<style>
.background-active {
  background-color: #00AE9D;
  color: white;
}
</style>
