<template>
  <div>
    <b-form-group
      label="Type"
      label-for="Type"
    >
      <b-form-input
        id="Type"
        v-model="typeName"
        trim
        placeholder="Type"
      />
    </b-form-group>
    <!-- <b-form-group
      label="Impact Score"
      label-for="impactScore"
    > -->
    <!-- <div class="d-flex">
      <div style="font-size: 0.857rem; margin-right: 4px;">
        Impact Score
      </div>
      <feather-icon
        id="tooltip-impact"
        class="my-auto"
        icon="InfoIcon"
      />
    </div>
    <b-tooltip
      target="tooltip-impact"
    >
      Impact Score
    </b-tooltip>
    <b-form-input
      id="impactScore"
      v-model="impactScore"
      trim
      type="number"
      placeholder="0"
    /> -->
    <!-- </b-form-group> -->
    <div
      class="btn btn-primary float-right ml-1 mt-2"
      @click="createNewDefectIncidentType"
    >
      Add
    </div>
    <div
      class="btn btn-light float-right mt-2"
      @click="closeModal()"
    >
      Cancel
    </div>
  </div>
</template>
<script>
import {
  BFormInput, BFormGroup,
  // BTooltip,
} from 'bootstrap-vue'
// import DropZone from 'dropzone-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    BFormGroup,
    BFormInput,
    // BTooltip,
  },
  directives: {
  },
  props: {
    type: {
      type: String,
      default: () => {},
    },
    selectedProject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      project: null,
      showModal: null,
      cover: null,
      userData: JSON.parse(localStorage.getItem('userData-Core')),
      typeName: '',
      impactScore: 0,
      param: '',
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
    this.param = this.type === 'defects' ? 'defectTypes' : 'incidenttype'
  },
  methods: {
    createNewDefectIncidentType() {
      const formData = new FormData()
      formData.append('label', this.typeName)
      formData.append('impact_score', '1')

      this.$axios.post(`${this.$baseUrl}/${this.param}`, formData).then(response => {
        if (response.status === 200) {
          this.$emit('refreshDefectIncident')
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Successfully Added!`,
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: `Failed to add!`,
            },
          })
        }
        this.closeModal()
      })
    },
    closeModal() {
      this.$bvModal.hide('modal-add-new-type')
    },
  },
}
</script>
