<template>
  <div
    class=""
  >
    <div
      class="p-2"
    >
      <div
        class="rounded bg-white"
      >
        <div
          class="p-1"
          :class="isSCurve ? '' : 'd-flex justify-content-between align-items-center'"
        >
          <div
            v-if="isSCurve"
            class="d-flex justify-content-between"
          >
            <div>
              <feather-icon
                class="mt-0 ml-0 ml-1"
                icon="ChevronLeftIcon"
                size="2.5x"
                color="grey"
                style="cursor:pointer;"
                @click="closeSCurve()"
              />
              <span class="p-1 font-weight-bold"> {{ taskName }}
              </span>
            </div>
            <div
              style="border-radius: 5px; width:50px; height:40px; border: 1px solid #d8d6de;"
              class="d-flex justify-content-center align-items-center ml-1"
              @click="toggleHeader"
            >
              <feather-icon
                class="mt-0 ml-0"
                :icon="isHeaderOnly ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                size="2.5x"
                color="grey"
              />
            </div>
          </div>
          <div v-else>
            <feather-icon
              class="mt-0 ml-0 ml-1"
              icon="ChevronLeftIcon"
              size="2.5x"
              color="grey"
              style="cursor:pointer;"
              @click="backButton"
            />
            <span class="p-1 font-weight-bold"> {{ title }}
            </span>
          </div>
          <div
            v-if="isSCurve === false"
            class="d-flex mr-2 ml-2"
          >
            <select
              v-model="tableDropdown"
              class="form-control"
              style="border: 1px solid #d8d6de;"
            >
              <option value="gantt">
                Gantt Chart
              </option>
              <option value="datatable">
                Data Table
              </option>
            </select>

            <div class="input-group ml-2 p-12">
              <input
                v-model="searchField"
                class="form-control"
                style="height: 100%;"
                type="search"
                placeholder="Search"
              >
              <span class="input-group-append">
                <button
                  class="btn"
                  style="border: 1px solid #d8d6de;"
                  type="button"
                >
                  <i class="fa fa-search" />
                </button>
              </span>
            </div>
            <!-- <div
              class="ml-2 btn-group btn-group-toggle d-flex "
              data-toggle="buttons"
            >
              <label
                class="btn btn-outline-secondary"
                style="border: 1px solid #d8d6de;"
              >
                <input
                  type="radio"
                  name="options"
                > Month
              </label>
              <label
                class="btn btn-outline-secondary"
                style="border: 1px solid #d8d6de;"
              >
                <input
                  type="radio"
                  checked
                  name="options"
                > View
              </label>
              <label
                class="btn btn-outline-secondary"
                style="border: 1px solid #d8d6de;"
              >
                <input
                  type="radio"
                  name="options"
                > Day
              </label>
            </div> -->
            <div
              style="border-radius: 5px; width:50px; height:40px; border: 1px solid #d8d6de;"
              class="d-flex justify-content-center align-items-center ml-1"
              @click="toggleHeader"
            >
              <feather-icon
                class="mt-0 ml-0"
                :icon="isHeaderOnly ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                size="2.5x"
                color="grey"
              />
            </div>
          </div>
        </div>
        <div
          v-if="isSCurve"
          v-show="!isHeaderOnly"
        >
          <scurve-chart
            :chart-data="charData"
          />
          <div class="d-flex justify-content-center align-items-center mb-2 mt-1">
            <div class="d-flex mr-2">

              <span
                style="height: 15px;
                      width: 15px;
                      margin-right: 5px;
                      background-color: #826AF9;
                      border-radius: 50%;
                      display: inline-block;"
              />  <span>Actual</span>
            </div>
            <div class="d-flex">

              <span
                style="height: 15px;
                margin-right: 5px;
                      width: 15px;
                      background-color: #B9B9C3;
                      border-radius: 50%;
                      display: inline-block;"
              /> <span>Plan</span>
            </div>

          </div>
        </div>
        <div v-else>
          <div
            v-if="tableDropdown==='gantt'"
          >
            <!-- showActualGantt -->
            <div v-if="noData === false">
              <gantt-elastic
                v-show="!isHeaderOnly"
                :options="options"
                :tasks="searchDataGantt.length === 0 ? ganttData : searchDataGantt"
                @tasks-changed="tasksUpdate"
                @options-changed="optionsUpdate"
                @dynamic-style-changed="styleUpdate"
              >
                <gantt-header slot="header" />
              </gantt-elastic>
            </div>
          </div>
          <div
            v-if="tableDropdown==='datatable' && noData!== true"
          >
            <transition
              name="slide-fade"
              mode="out-in"
            >
              <div
                v-show="!isHeaderOnly"
                key="200"
              >
                <b-table
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :total-rows="tableData.length"
                  :current-page="currentPage"
                  :items="searchDataGantt.length === 0 ? tableData : searchDataTable"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >

                  <template
                    #cell(name)="data"
                  >

                    <div
                      @click="taskClick(data.item.id, data.item.name)"
                    >
                      <span> {{ data.item.name }}</span>
                    </div>
                  </template>
                  <template #cell(startDate)="data">
                    {{ convertStartDate(data) }}
                  </template>
                  <template #cell(endDate)="data">
                    {{ convertEndDate(data) }}

                  </template>
                  <template #cell(edit)="data">
                    <div
                      style="cursor: pointer; color: purple;"
                      @click="actionClick(data.item.id, data.item.name)"
                    >
                      Edit
                    </div>

                  </template>
                </b-table>

                <div class="d-flex justify-content-between align-items-center ml-3 mr-3 mb-2">
                  <span style="color: #B9B9C3">Showing 1 to {{ perPage }} of {{ searchDataGantt.length === 0 ? tableData.length : searchDataTable.length }} entries</span>
                  <b-pagination
                    v-model="currentPage"
                    :value="currentPage"
                    :total-rows="searchDataGantt.length === 0 ? tableData.length : searchDataTable.length"
                    :per-page="perPage"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </transition>
          </div>
          <div
            v-if="noData"
            class="p-2"
          >
            You have no data information, please create new gantt chart project or upload files

          </div>
        </div>
      </div>
    </div>
    <div class="q-mt-md" />
  </div>
</template>
<style>

.p-1 .font-weight-bold{
font-size: 17px;
}

.gantt-elastic__header{
  display: none !important;
}
</style>
<script>
import GanttElastic from "gantt-elastic"
import GanttHeader from "gantt-elastic-header"
import dayjs from "dayjs"
import moment from 'moment'
import {
  BPagination, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import scurveChart from '@/views/pages/dashboard/analytic/component/scurveChart.vue'

// just helper to get current dates
function getDate(hours) {
  // const currentDate = new Date()
  const currentDate = new Date()
  console.log(currentDate)
  const currentDateTest = moment("2021-09-28", 'DD-MM-YYYY').toDate()
  console.log(currentDateTest)
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const currentDay = currentDate.getDate()
  const timeStamp = new Date(
    currentYear,
    currentMonth,
    currentDay,
    0,
    0,
    0,
  ).getTime()
  const dateTest = new Date(timeStamp + hours * 60 * 60 * 1000).getTime()
  // const dateTest = new Date(timeStamp * 60 * 60 * 1000).getTime()
  console.log(dateTest)
  return dateTest
}

export default {
  components: {
    GanttElastic,
    GanttHeader,
    BPagination,
    BTable,
    scurveChart,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    ganttData: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    noData: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    const self = this
    return {
      searchField: null,
      searchDataGantt: [],
      searchDataTable: [],
      totalRows: null,
      isSCurve: false,
      taskName: '',
      isHeaderOnly: false,
      tableDropdown: 'gantt',
      charData: {
        title: 'Resources',
        series: [{
          name: 'Actual',
          data: [0, 120000, 300000, 470000, 500000, 650000, 740000, 800000, 870000, 900000],
        },
        {
          name: 'Plan',
          data: [0, 300000, 430000, 550000, 630000, 700000, 870000, 930000, 960000, 990000],
        },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: 'straight',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ['#d0ccff', '#ebe9f1'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#7367F0', '#D0CCFF'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            // opposite: isRtl,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999999 ? `${(val / 1000000).toFixed(0)} mil` : `${val / 1000}k`
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: true },
          },
        },
      },
      currentGantt: '',
      options: {
        taskMapping: {
          progress: "percent",
        },
        maxRows: 100,
        maxHeight: 253,
        title: {
          label: "Prism Timeline",
          html: false,
        },
        row: {
          height: 24,
        },
        calendar: {
          hour: {
            display: true,
          },
        },
        chart: {
          progress: {
            bar: false,
          },
          expander: {
            display: true,
          },
        },
        taskList: {
          expander: {
            straight: false,
          },
          columns: [
            {
              id: 1,
              label: "Task Name",
              value: "label",
              width: 200,
              expander: true,
              html: true,
              events: {
                // eslint-disable-next-line no-unused-vars
                click({ data, column }) {
                  console.log(data)
                  self.taskClick(data.id, data.name)
                },
              },
            },
            {
              id: 2,
              label: "Action",
              value: "testing",
              width: 100,
              expander: true,
              html: true,
              events: {
                // eslint-disable-next-line no-unused-vars
                click({ data, column }) {
                  self.actionClick(data.id, data.label)
                },
              },
            },
            {
              id: 3,
              label: "WBS",
              value: "wbs",
              width: 130,
              html: true,
            },
            {
              id: 4,
              label: "Actual Start",
              value: task => dayjs(task.start).format("DD-MM-YYYY"),
              width: 78,
            },
            {
              id: 5,
              label: "Actual End",
              value: task => dayjs(task.end).format("DD-MM-YYYY"),
              width: 78,
            },
            {
              id: 6,
              label: "Planned Start",
              value: "plannedStart",
              width: 78,
            },
            {
              id: 7,
              label: "Planned End",
              value: "plannedEnd",
              width: 78,
            },
            // {
            //   id: 4,
            //   label: "Type",
            //   value: "type",
            //   width: 68,
            // },
            // {
            //   id: 5,
            //   label: "%",
            //   value: "progress",
            //   width: 35,
            //   style: {
            //     "task-list-header-label": {
            //       "text-align": "center",
            //       width: "100%",
            //     },
            //     "task-list-item-value-container": {
            //       "text-align": "center",
            //       width: "100%",
            //     },
            //   },
            // },
          ],
        },
        locale: {
          name: "en",
          Now: "Now",
          "X-Scale": "Zoom-X",
          "Y-Scale": "Zoom-Y",
          "Task list width": "Task list",
          "Before/After": "Expand",
          "Display task list": "Task list",
        },
      },
      dynamicStyle: {},
      lastId: 16,
      display: {
        gantt: false,
        table: false,
      },
      perPage: 5,
      pageOptions: [1, 2, 3, 4, 5, 6, 7],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      //
      fields: [
        { key: 'name', label: 'Task Name', sortable: true },
        { key: 'wbsCode', label: 'WBS', sortable: true },
        { key: 'actualProgress', label: 'Actual Progress (%)', sortable: true },
        { key: 'plannedProgress', label: 'Planned Progress (%)', sortable: true },
        { key: 'startDate', label: 'Actual Start', sortable: true },
        { key: 'endDate', label: 'Actual End', sortable: true },
        { key: 'edit', label: '', sortable: true },
        // { key: 'plannedStart', label: 'Planned Start', sortable: true },
        // { key: 'plannedEnd', label: 'Planned End', sortable: true },
        // { key: 'percentPlan', label: 'Percentage Plan (%)', sortable: true },
        // { key: 'percentActual', label: 'Percentage Plan (%)', sortable: true },
        // { key: 'budget', label: 'Budget (RM)', sortable: true },
        // { key: 'actualSpend', label: 'Actual Spent (RM)', sortable: true },
      ],
      myChartStart: "2020-10-01 00:00",
      myChartEnd: "2020-12-01 00:00",
      myBars: [
        {
          myBarStart: "2020-10-13 01:30",
          myBarEnd: "2020-10-27 06:00",
        },
      ],
      myBars2: [
        {
          myBarStart: "2020-10-25 01:30",
          myBarEnd: "2020-11-11 06:00",
        },
      ],
      myBars3: [
        {
          myBarStart: "2020-11-10 01:30",
          myBarEnd: "2020-11-18 06:00",
        },
      ],
      myBars4: [
        {
          myBarStart: "2020-10-21 01:30",
          myBarEnd: "2020-11-26 06:00",
        },
      ],
      myBars5: [
        {
          myBarStart: "2020-11-24 01:30",
          myBarEnd: "2020-12-01 06:00",
        },
      ],
      showMoreFiles: false,
      playMedia: 'video',
      currentMedia: '(CCTV Testing 1)',
      listImg: [
        {
          title: 'Image Title91',
          path: 'files/img/img9.png',
        },
        {
          title: 'Image Title 8',
          path: 'files/img/img8.png',
        },
        {
          title: 'Image Title 7',
          path: 'files/img/img7.png',
        },
        {
          title: 'Image Title 6',
          path: 'files/img/img6.png',
        },
        {
          title: 'Image Title 5',
          path: 'files/img/img5.png',
        },
        {
          title: 'Image Title 4',
          path: 'files/img/img4.png',
        },
        {
          title: 'Image Title 3',
          path: 'files/img/img3.png',
        },
        {
          title: 'Image Title 2',
          path: 'files/img/img2.png',
        },
        {
          title: 'Image Title 1',
          path: 'files/img/img1.png',
        },
        {
          title: 'Image Title 10',
          path: 'files/img/img10.png',
        },
        {
          title: 'Image Title 11',
          path: 'files/img/img11.png',
        },
        {
          title: 'Image Title 12',
          path: 'files/img/img12.png',
        },
      ],
      publicPath: process.env.BASE_URL,
      srcImg: "",
      isInfo: true,
      isLog: false,
      isJson: false,
      sub: {
        info: {
          information: [
            {
              name: 'Image Sensor',
              data: '1/2.8” 3M PS CMOS',
            },
            {
              name: 'Streaming',
              data: 'Multiple Streaming',
            },
            {
              name: 'Zoom Ratio',
              data: 'N/A',
            },
            {
              name: 'Media Type',
              data: 'Photo',
            },
            {
              name: 'Video Compression',
              data: 'H.264, MJPEG',
            },
            {
              name: 'Analogue Out',
              data: 'CVBS 1.0Vp-p',
            },
            {
              name: 'Max Resolution',
              data: '2048 x 1536',
            },
            {
              name: 'Max Frame Rate',
              data: '20fps at 3M',
            },
            {
              name: 'Audio Compression',
              data: 'G.711 u-law',
            },
            {
              name: 'Memory Slot',
              data: 'Yes (SD/SDHC)',
            },
            {
              name: 'Alarm I/O',
              data: '1/1',
            },
            {
              name: 'Day & Night',
              data: 'Yes',
            },
          ],
        },
      },
      items: [
        {
          temporary_building: 'Genset', priority_type: 'String',
        },
        {
          temporary_building: 'Schedule Waste', priority_type: 'String',
        },
        {
          temporary_building: 'Structure Steel Yard', priority_type: 'String',
        },
        {
          temporary_building: 'Fabrication Yard', priority_type: 'String',
        },
        {
          temporary_building: 'Foldable Gate', priority_type: 'String',
        },
        {
          temporary_building: 'TNB Sub Station', priority_type: 'String',
        },
        {
          temporary_building: 'Septic Tank', priority_type: 'String',
        },
      ],
    }
  },
  watch: {
    searchField: {
      handler(val){
        this.searchDataGantt = []
        this.searchDataTable = []
        const tempDataGantt = [] // use to hold data before assigning to searchDataGantt. to make sure that gantt chart will still responsive during search
        const tempDataTable = []

        this.ganttData.forEach(item => {
          if (item.name.toLowerCase().includes(val.toLowerCase())){
            tempDataGantt.push(item)
          }
        })

        this.tableData.forEach(item => {
          if (item.name.toLowerCase().includes(val.toLowerCase())){
            tempDataTable.push(item)
          }
        })
        this.searchDataGantt = tempDataGantt
        this.searchDataTable = tempDataTable
      },
    },
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.tableData.length
    // console.log(this.tableData)
  },
  methods: {
    convertStartDate(data){
      return dayjs(data.item.startDate).format("DD-MM-YYYY")
    },

    convertEndDate(data){
      return dayjs(data.item.endDate).format("DD-MM-YYYY")
    },
    closeSCurve(){
      this.isSCurve = false
      this.$emit('closeSCurve')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
    },
    addTask() {
      this.tasks.push({
        // eslint-disable-next-line no-plusplus
        id: this.lastId++,
        label:
          '<a href="https://images.pexels.com/photos/423364/pexels-photo-423364.jpeg?auto=compress&cs=tinysrgb&h=650&w=940" target="_blank" style="color:#0077c0;">New Task Heree</a>',
        user:
          '<a href="https://images.pexels.com/photos/423364/pexels-photo-423364.jpeg?auto=compress&cs=tinysrgb&h=650&w=940" target="_blank" style="color:#0077c0;">Who?</a>',
        start: getDate(24 * 3),
        duration: 1 * 24 * 60 * 60 * 1000,
        percent: 50,
        type: "project",
      })
    },
    tasksUpdate(data) {
      this.tasks = data
    },
    optionsUpdate(data) {
      this.options = data
    },
    styleUpdate(style) {
      this.dynamicStyle = style
    },
    toggleHeader() {
      this.isHeaderOnly = !this.isHeaderOnly
    },
    backButton() {
      this.$emit('backButton', true)
    },
    taskClick(id, name){
      this.$emit('taskClick', id)
      console.log(name)
      this.taskName = name
      this.isSCurve = true
    },
    actionClick(id, name) {
      this.$emit('actionClick', id)
      console.log('action clicked')
      console.log(id)
      console.log(name)
    },
  },
}
</script>
