<template>
  <div style="width: 100%; height: 100%;">
    <!-- <video-player
      ref="videoPlayer"
      class="vjs-custom-skin"
      :options="playerOptions"
    /> -->
    <div class="synchro-threed">
      <iframe
        style="height: 100%; width: 100%;"
        src="https://dev-api.petronas.myprism.biz/threed-progress"
      />
    </div>

    <!-- <div class="d-flex flex-column justify-content-center">
      <div class="d-flex justify-content-center align-items-center mt-2">
        <span>HLS</span>
        <div class="col-sm-4">
          <input
            v-model="url"
            class="form-control"
            type="text"
            placeholder="HLS url here"
          >
        </div>
      </div>
      <div class="mt-1">
        <div class="d-flex justify-content-center">
          <button
            class="btn btn-primary"
            type="button"
            @click="enterStream"
          >
            Apply
          </button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<style>
.video-js .vjs-tech {
  height: auto !important;
}

.synchro-threed {
  width: 100%;
  height: 100%;
}

/* [dir] .video-js {
  background: none !important;
} */

/* video {
  background-color: rgba(255,255,255,0.8) !important;
  padding: 10px !important;
  border-radius: 8px !important;
  width: 100% !important;
  height: auto !important;
} */
</style>

<script>
// import EventBus from '@/views/module/eventBus'

export default {
  props: {
    playerOptions: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      url: '',
      classCollection: null,
    }
  },
  mounted() {
    console.log('first mount')
    console.log(this.playerOptions)
    console.log(this.index)
    // EventBus.$on('onViewChange', itemLength => {
    //   console.log('receiving bus')
    //   console.log(itemLength)
    //   this.classCollection = document.getElementsByClassName('vjs-tech')
    //   console.log(this.classCollection)
    //   if (this.classCollection.length !== 0) {
    //     if (itemLength === 1) {
    //       this.classCollection[0].style.height = 'auto'
    //     } else {
    //       this.classCollection[0].style.height = '100%'
    //     }
    //   }
    // })
  },
  methods: {
    enterStream() {
      this.playerOptions.sources[0].src = this.url
      this.playerOptions.autoplay = true
    },
  },
}
</script>
